import { defineMessages } from 'react-intl';

export default defineMessages({
    pleaseSelectDevices: {
        id: 'quoteandbind.views.wmicadditionaldetails.Please select each safety device that applies to your residence.',
        defaultMessage: 'Please select each safety device that applies to your residence.'
    },
    yourDiscounts: {
        id: 'quoteandbind.views.coverables.rating.Your Discounts',
        defaultMessage: 'Your Discounts'
    },
    fireExtiguishers: {
        id: 'quoteandbind.ho.views.rating.Do you have one or more fire extinguishers in the home',
        defaultMessage: 'Do you have one or more fire extinguishers in the home?'
    },
    burglarAlarmCA: {
        id: 'quoteandbind.ho.views.rating.Do you have a professionally installed Burglar Alarm system that is monitored by a Central Monitoring Station within your residence',
        defaultMessage: 'Do you have a professionally installed Burglar Alarm system that is monitored by a Central Monitoring Station within your residence?'
    },
    burglarAlarmOR: {
        id: 'quoteandbind.ho.views.rating.Do you have a professionally installed Burglar Alarm system within your residence',
        defaultMessage: 'Do you have a professionally installed Burglar Alarm system within your residence?'
    },
    alarmType: {
        id: 'quoteandbind.views.coverables.Alarm Type',
        defaultMessage: 'Alarm Type'
    },
    fireAlarmCA: {
        id: 'quoteandbind.ho.views.rating.Do you have a professionally installed Fire Alarm system that is monitored by a Central Monitoring Station within your residence',
        defaultMessage: 'Do you have a professionally installed Fire Alarm system that is monitored by a Central Monitoring Station within your residence?'
    },
    fireAlarmOR: {
        id: 'quoteandbind.ho.views.rating.Do you have a professionally installed Fire Alarm in your residence',
        defaultMessage: 'Do you have a professionally installed Fire Alarm in your residence?'
    },
    smokeAlarmOnAllFloors: {
        id: 'quoteandbind.ho.views.rating.Do you have operable Smoke Detectors located in your residence',
        defaultMessage: 'Do you have operable Smoke Detectors located in your residence?'
    },
    digitalDialer: {
        id: 'quoteandbind.ho.views.rating.Smoke Detector.Activated by Digital Dialer or Voice Synthesized (not tape) telephone dialer?',
        defaultMessage: 'Activated by Digital Dialer or Voice Synthesized (not tape) telephone dialer?'
    },
    deadbolts: {
        id: 'quoteandbind.ho.views.rating.Are the exit/ entry doors to your residence secured by deadbolt locks',
        defaultMessage: 'Are the exit/ entry doors to your residence secured by deadbolt locks?'
    },
    isInGatedCommunity: {
        id: 'quoteandbind.ho.views.rating.Is the residence/ building located in a gated community or area with a locked and secured entrance to the grounds',
        defaultMessage: 'Is the residence/ building located in a gated community or area with a locked and secured entrance to the grounds?'
    },
    securityGuard: {
        id: 'quoteandbind.ho.views.rating.Is there a security guard on duty 24 hours a day?',
        defaultMessage: ' there a security guard on duty 24 hours a day?'
    },
    multiProduct: {
        id: 'quoteandbind.ho.views.rating.Do you have an existing automobile policy with Wawanesa?',
        defaultMessage: 'Do you have an existing automobile policy with Wawanesa?'
    },
    multiProductPolicyNumber: {
        id: 'quoteandbind.ho.views.rating.Policy Number',
        defaultMessage: 'Policy Number'
    },
    sprinklerSystemType: {
        id: 'quoteandbind.views.contact-details.Sprinkler System in Your Home',
        defaultMessage: 'What sprinkler coverage do you have in your residence?'
    },
    haveFireExtinguisher: {
        id: 'quoteandbind.views.wmicadditionaldetails.I have a fire extinguisher in my home.',
        defaultMessage: 'I have a fire extinguisher in my home.'
    },
    haveBurglarAlarm: {
        id: 'quoteandbind.views.wmicadditionaldetails.I have a burglar and/or fire alarm monitored by a central monitoring station, police department or fire department.',
        defaultMessage: 'I have a burglar and/or fire alarm monitored by a central monitoring station, police department or fire department.'
    },
    haveSmokeAlarm: {
        id: 'quoteandbind.views.wmicadditionaldetails.I have operable smoke detectors in my home.',
        defaultMessage: 'I have operable smoke detectors in my home.'
    },
    haveDeadBolts: {
        id: 'quoteandbind.views.wmicadditionaldetails.The exit / entry doors to my home are secured by deadbolt locks.',
        defaultMessage: 'The exit / entry doors to my home are secured by deadbolt locks.'
    },
    haveGatedCommunity: {
        id: 'quoteandbind.views.wmicadditionaldetails.My home is in a gated community or secured building.',
        defaultMessage: 'My home is in a gated community or secured building.'
    },
    haveSecurityGuard: {
        id: 'quoteandbind.views.wmicadditionaldetails.There is a guard on duty 24 hours a day.',
        defaultMessage: 'There is a guard on duty 24 hours a day.'
    },
    calculatingYourQuote: {
        id: 'quoteandbind.views.wmicadditionaldetails.Calculating Your Quote...',
        defaultMessage: 'Calculating quote'
    }
});
