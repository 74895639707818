import { defineMessages } from 'react-intl';

export default defineMessages({
    yourInfoTitle: {
        id: 'quoteandbind.ho.views.ho-confirmation.YourInfo',
        defaultMessage: 'Information'
    },
    yourHouseTitle: {
        id: 'quoteandbind.ho.views.ho-confirmation.YourHouse',
        defaultMessage: 'House'
    },
    yourCoveragesTitle: {
        id: 'quoteandbind.ho.views.ho-confirmation.YourCoverages',
        defaultMessage: 'Your Coverages'
    },
    yourCoveragesLimits: {
        id: 'quoteandbind.ho.views.ho-confirmation.Limits',
        defaultMessage: 'Coverages'
    },
    firstName: {
        id: 'quoteandbind.ho.views.ho-confirmation.FirstName',
        defaultMessage: 'First Name'
    },
    lastName: {
        id: 'quoteandbind.ho.views.ho-confirmation.LastName',
        defaultMessage: 'Last Name'
    },
    emailAddress: {
        id: 'quoteandbind.ho.views.ho-confirmation.Email',
        defaultMessage: 'Email'
    },
    coveragePremiumDetails: {
        id: 'quoteandbind.ho.views.ho-confirmation.Coverage Premium Details',
        defaultMessage: 'Coverage Premium Details'
    },
    baseCoverages: {
        id: 'quoteandbind.ho.views.policy-info.Base Coverages',
        defaultMessage: 'Base Coverages'
    },
    additionalCoverages: {
        id: 'quoteandbind.ho.views.policy-info.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    startDate: {
        id: 'quoteandbind.ho.views.ho-confirmation.Start Date',
        defaultMessage: 'Start Date'
    },
    endDate: {
        id: 'quoteandbind.ho.views.ho-confirmation.End Date',
        defaultMessage: 'End Date'
    }
});
