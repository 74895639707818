import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import { PaymentDetailsPage, QualificationPage, WMICQualificationPageQnB } from 'gw-capability-quoteandbind-common-react';

import HOYourHomePage from './pages/YourHome/YourHomePage';
import HOYourInfoPage from './pages/YourInfo/YourInfoPage';
import HOConstructionPage from './pages/Construction/ConstructionPage';
import HODiscountPage from './pages/Discount/DiscountPage';
import HOQuotePage from './pages/Quote/QuotePage';
import HOAdditionalInformation from './pages/AdditionalInformation/AdditionalInformationPage';
import HOPolicyInformationPage from './pages/PolicyInformation/PolicyInformationPage';
import HOSuccessPage from './pages/Success/SuccessPage';
// eslint-disable-next-line camelcase
import WMICAdditionalDetails from './pages/WMICAdditionalDetails/WMICAdditionalDetails';
import WMICQuotePage from './pages/WMICQuote/WMICQuotePage';
import WMICPolicySummary from './pages/WMICPolicySummary/WMICPolicySummary';
import WMICKnockoutPage from './components/WMICKnockout/WMICKnockoutPage';
import WMICAdditionalCoverageOR from './components/WMICAdditionalCoverageOR/WMICAdditionalCoverageOR';
import WMICSpecialCoverageCA from './components/WMICSpecialCoverageCA/WMICSpecialCoverageCA';
import WMICOneIncPaymentModalQnB from './components/WMICOneIncPaymentModalQnB/WMICOneIncPaymentModalQnB';
import WMICContactPreference from './pages/WMICContactPreferences/WMICContactPreferences';
import WMICSuccessPage from './pages/WMICSuccess/WMICSuccessPage';
import WMICRecurringPaymentAuthorization from './pages/WMICRecurringPaymentAuthorization/WMICRecurringPaymentAuthorization';
import WMICRecurringPaymentDetails from './pages/WMICRecurringPaymentDetails/WMICRecurringPaymentDetails';
import WMICPolicyInProcessPage from './pages/WMICPolicyInProcess/WMICPolicyInProcessPage'

setComponentMapOverrides(
    {
        HOYourInfoPage: { component: 'HOYourInfoPage' },
        HOQualificationPage: { component: 'HOQualificationPage' },
        WMICHOQualificationPageQnB: { component: 'WMICHOQualificationPageQnB' },
        WMICAdditionalDetails: { component: 'WMICAdditionalDetails' },
        WMICQuotePage: { component: 'WMICQuotePage' },
        WMICPolicySummary: { component: 'WMICPolicySummary' },
        HOYourHomePage: { component: 'HOYourHomePage' },
        HOConstructionPage: { component: 'HOConstructionPage' },
        HODiscountPage: { component: 'HODiscountPage' },
        HOQuotePage: { component: 'HOQuotePage' },
        HOAdditionalInformation: { component: 'HOAdditionalInformation' },
        HOPolicyInformationPage: { component: 'HOPolicyInformationPage' },
        HOPaymentDetailsPage: { component: 'HOPaymentDetailsPage' },
        HOSuccessPage: { component: 'HOSuccessPage' },
        WMICSuccessPage: { component: 'WMICSuccessPage' },
        WMICKnockoutPage: { component: 'WMICKnockoutPage' },
        WMICContactPreference: { component: 'WMICContactPreference' },
        WMICRecurringPaymentAuthorization: { component: 'WMICRecurringPaymentAuthorization' },
        WMICRecurringPaymentDetails: { component: 'WMICRecurringPaymentDetails' },
        WMICPolicyInProcessPage: { component: 'WMICPolicyInProcessPage' },
        WMICOneIncPaymentModalQnB: { component: 'WMICOneIncPaymentModalQnB' }
    },
    {
        HOYourInfoPage,
        HOQualificationPage: QualificationPage,
        WMICHOQualificationPageQnB: WMICQualificationPageQnB,
        WMICAdditionalDetails,
        WMICQuotePage,
        WMICPolicySummary,
        HOYourHomePage,
        HOConstructionPage,
        HODiscountPage,
        HOQuotePage,
        HOAdditionalInformation,
        HOPolicyInformationPage,
        HOPaymentDetailsPage: PaymentDetailsPage,
        HOSuccessPage,
        WMICSuccessPage,
        WMICKnockoutPage,
        WMICAdditionalCoverageOR,
        WMICSpecialCoverageCA,
        WMICOneIncPaymentModalQnB,
        WMICContactPreference,
        WMICRecurringPaymentAuthorization,
        WMICRecurringPaymentDetails,
        WMICPolicyInProcessPage
    }
);

// eslint-disable-next-line import/prefer-default-export
// eslint-disable-next-line import/named
// eslint-disable-next-line import/prefer-default-export
export { default as HOWizard } from './HOWizard';
export { default as WMICKnockoutPage } from './components/WMICKnockout/WMICKnockoutPage';
export { default as WMICSuccessPage } from './pages/WMICSuccess/WMICSuccessPage';
export { default as WMICPaymentSteps } from './components/WMICPaymentSteps/WMICPaymentSteps';
export { default as WMICEmailForm } from './components/WMICEmailForm/WMICEmailForm';
export { default as WMICPolicySummaryUtil } from './pages/WMICPolicySummary/WMICPolicySummaryUtil';
export { default as WMICPolicyInProcessPage } from './pages/WMICPolicyInProcess/WMICPolicyInProcessPage';
export { default as WMICOneIncPaymentModalQnB } from './components/WMICOneIncPaymentModalQnB/WMICOneIncPaymentModalQnB';
