import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import GTPQuestion from './components/GTPQuestion/GTPQuestion';
import GTPYourBusinessPage from './pages/YourBusiness/YourBusinessPage';
import GTPYourAddressPage from './pages/YourAddress/YourAddressPage';
import GTPQuestionsPage from './pages/Questions/QuestionsPage';
import GTPRecommendationsPage from './pages/Recommendations/RecommendationsPage';

setComponentMapOverrides(
    {
        GTPQuestion: { component: 'GTPQuestion' },
        GTPYourBusinessPage: { component: 'GTPYourBusinessPage' },
        GTPYourAddressPage: { component: 'GTPYourAddressPage' },
        GTPQuestionsPage: { component: 'GTPQuestionsPage' },
        GTPRecommendationsPage: { component: 'GTPRecommendationsPage' },
    },
    {
        GTPQuestion,
        GTPYourBusinessPage,
        GTPYourAddressPage,
        GTPQuestionsPage,
        GTPRecommendationsPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as GTPWizard } from './GTPWizard';
