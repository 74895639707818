import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { Loader } from '@jutro/components';

import wizardConfig from './config/gtp-wizard-config.json5';
import './GTPWizard.messages';

function GTPWizard(props) {
    const { steps, title } = wizardConfig;
    const [isLoading, setIsLoading] = useState(true);
    const [initialSubmission, setInitialSubmission] = useState(null);
    const viewModelService = useContext(ViewModelServiceContext);
    const history = useHistory();

    useEffect(() => {
        const gtpPrepData = {
            userAddress: {}
        };
        if (!viewModelService) {
            history.push('/');
            return;
        }
        const submission = viewModelService.create(
            gtpPrepData,
            'pc',
            'wmic.edge.us.capabilities.guidance.dto.GuidanceDTO'
        );
        setInitialSubmission(submission);
        setIsLoading(false);
    }, [history, viewModelService]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return <Wizard initialSteps={steps} wizardTitle={title} initialData={initialSubmission} />;
}

GTPWizard.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default GTPWizard;
