/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { WMICESignatureService } from 'wmic-capability-quoteandbind';
import { WMICProgressModal } from 'gw-capability-quoteandbind-common-react';
import { ERROR_CODE, ESIGNATURE_STATUS } from 'wmic-portals-utils-js';
import messages from './WMICESignatureCallbackPage.messages';

const MAX_RETRIES = 4;

function WMICESignatureCallbackPage() {
    const { authHeader } = useAuthentication();
    const { search } = useLocation();
    const history = useHistory();
    const translator = useTranslator();

    const queryStrings = new URLSearchParams(search);
    const orderID = queryStrings.get('i');
    const needToWait = queryStrings.get('needToWait');
    const [quoteID, setQuoteID] = useState();

    const gotoContactUs = (displayMode) => {
        const nextState = {
            pathname: `/contact-us/${displayMode}`,
            state: {
                quoteID: quoteID
            }
        };
        history.push(`/contact-us/${displayMode}`, nextState.state);
    };

    const gotoRetrieveQuote = () => {
        history.push(`/quick-retrieve-quote?orderID=${orderID}`);
    };

    const checkESignatureStatus = (count) => {
        if (count < MAX_RETRIES) {
            const promise = WMICESignatureService.getESignatureStatus(undefined, undefined, orderID, false, authHeader);
            promise.then((response) => {
                if (response) {
                    setQuoteID(response.quoteID);
                }

                if (response && (response.esignatureDocumentStatusName === ESIGNATURE_STATUS.SIGNED || response.esignatureDocumentStatusName === ESIGNATURE_STATUS.OPTIMISTICALLY_SIGNED
                    || response.esignatureDocumentStatusName === ESIGNATURE_STATUS.DECLINED)) {
                    gotoRetrieveQuote();
                } else if (response && response.esignatureDocumentStatusName === ESIGNATURE_STATUS.PENDING_SIGNATURE) {
                    setTimeout(() => {
                        count++;
                        checkESignatureStatus(count);
                    }, 5000);
                } else {
                    // Go to generic confirmation screen telling the user to call in
                    gotoContactUs(ERROR_CODE.TECHNICAL_ERROR);
                }
            }, () => {
                gotoContactUs(ERROR_CODE.TECHNICAL_ERROR_RETRIEVE_QUOTE);
            });
        } else {
            gotoRetrieveQuote();
        }
    };

    useEffect(() => {
        if (orderID !== undefined && orderID !== null) {
            if (needToWait && needToWait === 'true') {
                checkESignatureStatus(0);
            } else {
                gotoRetrieveQuote();
            }
        } else {
            history.push('/');
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WMICProgressModal
            modalTitle={translator(messages.returningToWawanesa)}
            isOpen
        />
    );
}

export default WMICESignatureCallbackPage;