import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import { ServiceManager } from '@jutro/legacy/services';
import { Loader } from '@jutro/components';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import wizardConfig from './config/slq-wizard-config.json5';
import './SLQWizard.messages';

function SLQWizard(props) {
    const { steps } = wizardConfig;
    const localeService = ServiceManager.getService('locale-service');
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const viewModelService = useContext(ViewModelServiceContext);
    const history = useHistory();

    useEffect(() => {
        const slqPrepData = {
            policyAddress: {
                country: localeService.getDefaultCountryCode()
            },
            productCode: 'PersonalAuto',
            lobCoverables: {
                personalAuto: {}
            },
            age: undefined,
            termsChecked: {
                value: false
            }
        };
        if (!viewModelService) {
            history.push('/');
            return;
        }
        const submission = viewModelService.create(
            slqPrepData,
            'pc',
            'wmic.edge.us.capabilities.quote.streamlined.dto.StreamlinedQuoteDTO'
        );

        const quoteVM = viewModelService.create(
            {},
            'pc',
            'wmic.edge.us.capabilities.quote.submission.dto.QuoteDataDTO'
        );

        const wizardData = {
            submissionVM: submission,
            quoteVM: quoteVM,
            isManualEntryMode: false,
            showAddressOnMap: false,
            isUserUsingCurrentLocation: false
        };

        setInitialSubmission(wizardData);
        setIsLoading(false);
    }, [history, localeService, viewModelService]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return <Wizard initialSteps={steps} initialData={initialSubmission} />;
}

SLQWizard.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default SLQWizard;
