/* eslint-disable max-len */
import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import {
    MockUpUtil,
    CONSTANTS,
    WMICVariousUtil,
    LocalDateUtil
} from 'wmic-portals-utils-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    WMICCustomInput,
    WMICCustomDropdownSelect,
    WMICCustomRadioButton,
    WMICCustomTooltip,
    WMICAddressLookupComponent,
    WMICProgressModal,
    WMICRetrieveInfoContext
} from 'gw-capability-quoteandbind-common-react';
import dayjs from 'dayjs';
import styles from './PolicyInformationPage.module.scss';
import metadata from './PolicyInformationPage.metadata.json5';
import messages from './PolicyInformationPage.messages';

function removeInitialData(submissionVM) {
    // eslint-disable-next-line no-param-reassign
    submissionVM.value = MockUpUtil.cleanUpMockedProperties(
        _.get(submissionVM, 'value'),
        'quote.ho',
        'baseData.accountHolder.firstName',
        'baseData.accountHolder.lastName'
    );
    return submissionVM;
}

function initialiseVM(submissionVM) {
    const vm = removeInitialData(submissionVM);
    vm.bindData.value = vm.bindData.value || {};
    vm.bindData.billingAddress.value = vm.bindData.billingAddress.value
        || vm.baseData.policyAddress.value;
    vm.bindData.contactEmail.value = vm.bindData.contactEmail.value
        || vm.baseData.accountHolder.emailAddress1.value;
    vm.bindData.hasDapForNotification_WMIC.value = vm.bindData.hasDapForNotification_WMIC.value || false;
    vm.bindData.hasLandlordForCertificateHolder_WMIC.value = vm.bindData.hasLandlordForCertificateHolder_WMIC.value || false;
    vm.bindData.contactPhone = vm.bindData.contactPhone.value || '';
    vm.bindData.primaryNamedInsuredOccupationType_WMIC = _.find(vm.bindData.primaryNamedInsuredOccupationType_WMIC.aspects.availableValues, { code: 'notknown' });
    return vm;
}

function PolicyInformationPage(props) {
    const { wizardData: submissionVM, updateWizardData, goNext } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const retrieveContext = useContext(WMICRetrieveInfoContext);
    const [isSameBillingAddress, updateIsSameBillingAddress] = useState(true);
    const [isVmInitialized, updateIsVmInitialized] = useState(false);
    const localeService = ServiceManager.getService('locale-service');
    const { LoadSaveService, MultiProductService } = useDependencies(['LoadSaveService', 'MultiProductService']);
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate } = useValidation('PolicyInformationPage');
    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const hasDap = useCallback(() => {
        return _.get(submissionVM, 'bindData.hasDapForNotification_WMIC.value');
    }, [submissionVM]);

    const hasLandlord = useCallback(() => {
        return _.get(submissionVM, 'bindData.hasLandlordForCertificateHolder_WMIC.value');
    }, [submissionVM]);

    const hasLandlordCompany = useCallback(() => {
        return _.get(submissionVM, 'bindData.isLandlordCompany_WMIC.value');
    }, [submissionVM]);

    useEffect(() => {
        if (!isVmInitialized) {
            let nextSubmissionVM = viewModelService.clone(submissionVM);
            nextSubmissionVM = initialiseVM(nextSubmissionVM, localeService);

            if (!_.isEqual(submissionVM.value, nextSubmissionVM.value)) {
                updateWizardData(nextSubmissionVM);
            }
            updateIsVmInitialized(true);
        } else {
            if (hasDap() && !submissionVM.bindData.dapAddress_WMIC.value) {
                submissionVM.bindData.dapAddress_WMIC.value = {
                    state: submissionVM.baseData.policyAddress.value.state,
                    country: localeService.getDefaultCountryCode()
                };
                updateWizardData(submissionVM);
            }
            if (hasLandlord() && !submissionVM.bindData.landlordAddress_WMIC.value) {
                submissionVM.bindData.landlordAddress_WMIC.value = {
                    state: submissionVM.baseData.policyAddress.value.state,
                    country: localeService.getDefaultCountryCode()
                };
                updateWizardData(submissionVM);
            }
        }
        // eslint-disable-next-line max-len
    }, [isVmInitialized, submissionVM, updateWizardData, viewModelService, localeService, hasLandlord, hasDap]);

    const continueToNext = useCallback(async() => {
        if (!isComponentValid) {
            setShowErrors(true);
            window.scrollTo(0, 0);
            return false;
        }

        const policyAddress = _.get(submissionVM, 'baseData.policyAddress.value');
        if (isSameBillingAddress) {
            _.set(submissionVM, 'bindData.billingAddress.value', policyAddress);
        }

        setIsLoading(true);

        if(submissionVM.value.bindData.hasOwnProperty('paymentDetails')) {
            _.unset(submissionVM, 'value.bindData.paymentDetails');
        }

        submissionVM.value = await LoadSaveService.updateQuotedSubmission(submissionVM.value, authHeader);
        updateWizardData(submissionVM);
        goNext();
        return submissionVM;
    }, [ LoadSaveService, authHeader, isSameBillingAddress, submissionVM, goNext, updateWizardData, isComponentValid ]);

    const handleNext = useCallback(async () => {
        if (submissionVM.value.baseData.multiProduct_WMIC) {
            let isValidPolicyNumber = false;
            if (submissionVM.value.baseData.multiProductPolicyNumber_WMIC != null && String(submissionVM.value.baseData.multiProductPolicyNumber_WMIC)) {
                isValidPolicyNumber = await MultiProductService.validateMultiProductPolicyNumber(submissionVM.value.quoteID, submissionVM.value.baseData.multiProductPolicyNumber_WMIC);
            }
            _.set(submissionVM, 'baseData.multiProductPolicyNumberValid_WMIC.value', isValidPolicyNumber);
            updateWizardData(submissionVM);
            if (isValidPolicyNumber) {
                return continueToNext();
            } else {
                return false;
            } 
        // eslint-disable-next-line no-else-return
        } else {
            return continueToNext();
        }
    }, [MultiProductService, submissionVM, updateWizardData, continueToNext, isComponentValid]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, `${path}.value`, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const designatePersonNotificationChange = (value, path) => {
        writeValue(value, path);
        if (value === true) {
            _.set(submissionVM, 'bindData.dapPerson_WMIC.value', {});
            _.set(submissionVM, 'bindData.dapAddress_WMIC.value', { country: 'US' });
            _.set(submissionVM, 'bindData.dapGivenPermissionToDiscussBillingWithDesignee_WMIC.value', false);
            _.set(submissionVM, 'bindData.dapPersonEmailAddress_WMIC.value', '');
        } else {
            _.set(submissionVM, 'bindData.dapPerson_WMIC.value', undefined);
            _.set(submissionVM, 'bindData.dapAddress_WMIC.value', undefined);
            _.set(submissionVM, 'bindData.dapGivenPermissionToDiscussBillingWithDesignee_WMIC.value', undefined);
            _.set(submissionVM, 'bindData.dapPersonEmailAddress_WMIC.value', undefined);
        }
    };

    const switchLandlordContactType = (isCompany) => {
        // Contact types: Person, Company
        if (isCompany === true) {
            _.set(submissionVM, 'bindData.landlordCompanyForCertificateHolder_WMIC.value', {});
            _.set(submissionVM, 'bindData.landlordForCertificateHolder_WMIC.value', undefined);
            _.set(submissionVM, 'bindData.landlordCompanyForCertificateHolder_WMIC.value.producerCode', 'code');
            _.set(submissionVM, 'bindData.landlordAddress_WMIC.value', { country: 'US' });
        } else {
            _.set(submissionVM, 'bindData.isLandlordCompany_WMIC.value', false);
            _.set(submissionVM, 'bindData.landlordForCertificateHolder_WMIC.value', {});
            _.set(submissionVM, 'bindData.landlordCompanyForCertificateHolder_WMIC.value', undefined);
        }
    };

    const landlordForCertificateHolderChange = (value, path) => {
        writeValue(value, path);
        switchLandlordContactType(false);
        if (value === true) {
            _.set(submissionVM, 'bindData.landlordAddress_WMIC.value', { country: 'US' });
        } else {
            _.set(submissionVM, 'bindData.landlordAddress_WMIC.value', undefined);
        }
        updateWizardData(submissionVM);
    };

    const multiProductChange = (value, path) => {
        writeValue(value, path);
        _.set(submissionVM, 'baseData.multiProductPolicyNumber_WMIC.value', undefined);
        updateWizardData(submissionVM);
    };

    const multiProductPolicyNumberChange = (value, path) => {
        writeValue(value, path);
        // defaulting to true for now as the validation will be checked when clicking Next button
        _.set(submissionVM, 'baseData.multiProductPolicyNumberValid_WMIC.value', true);
        updateWizardData(submissionVM);
    };

    const isLandlordCompanyChange = (value, path) => {
        writeValue(value, path);
        switchLandlordContactType(value);
    };
    const getEffDate = () => {
        let date = LocalDateUtil.toMidnightDate((submissionVM.baseData.value.periodStartDate));
        date = dayjs(date).format('dddd, MMMM D, YYYY');
        return date;
    };

    const getAddress = () => {
        const address = submissionVM.baseData.value.policyAddress;
        return <span>{address.addressLine1}<br />{address.city}, {address.state} {address.postalCode}</span>;
    };

    const getEmail = () => {
        const email = submissionVM.baseData.accountHolder.emailAddress1.value;
        return <span>{translator(messages.email)} <b>{email}</b></span>;
    };

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top'
        },
        startDateValue: {
            content: getEffDate()
        },
        primaryInsuredValue: {
            content: submissionVM.baseData.value.accountHolder.displayName
        },
        policyAddressValue: {
            content: getAddress()
        },
        sameBillingAddress: {
            value: isSameBillingAddress,
            onValueChange: updateIsSameBillingAddress
        },
        billingAddress: {
            visible: !isSameBillingAddress,
            model: submissionVM,
            stateField: {
                readOnly: false
            }
        },
        email: {
            content: getEmail()
        },
        phoneNumber: {
            countryCode: localeService.getDefaultPhoneCountry()
        },
        multiProduct: {
            onValueChange: (value, path) => {
                multiProductChange(value, path);
            },
            visible: (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.CA)
        },
        multiProductPolicyNumber: {
            visible: (_.get(submissionVM, 'baseData.multiProduct_WMIC.value') === true),
            onValueChange: (value, path) => {
                multiProductPolicyNumberChange(value, path);
            }
        },
        designatePersonForNotificationContainer: {
            visible: (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.CA)
        },
        designatePersonForNotification: {
            onValueChange: (value, path) => {
                designatePersonNotificationChange(value, path);
            }
        },
        dapFirstName: {
            visible: hasDap()
        },
        dapLastName: {
            visible: hasDap()
        },
        dapEmail: {
            visible: hasDap()
        },
        dapAddress: {
            visible: hasDap(),
            model: submissionVM,
            stateField: {
                readOnly: false
            }
        },
        permissionToDiscussBillingWithDesignee: {
            visible: hasDap()
        },
        isLandlordCompany: {
            visible: hasLandlord(),
            onValueChange: (value, path) => {
                isLandlordCompanyChange(value, path);
            }
        },
        landlordCompanyName: {
            visible: hasLandlord() && hasLandlordCompany()
        },
        landlordFirstName: {
            visible: !hasLandlordCompany() && hasLandlord()
        },
        landlordLastName: {
            visible: !hasLandlordCompany() && hasLandlord()
        },
        landlordAddress: {
            visible: hasLandlord(),
            model: submissionVM,
            stateField: {
                readOnly: false
            }
        },
        emailTextHint: {
            className : styles.emailTextHint,
            content: WMICVariousUtil.getTextWithIcon('fa fa-question-circle wmicIconBig', translator(messages.emailUpdateHint))
        },
        landlordForCertificateHolder: {
            onValueChange: (value, path) => {
                landlordForCertificateHolderChange(value, path);
            }
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            updateIsSameBillingAddress
        },
        resolveComponentMap: {
            wmicInput: WMICCustomInput,
            wmicAddressLookupComponent: WMICAddressLookupComponent,
            wmicCustomDropdownSelect: WMICCustomDropdownSelect,
            wmicCustomRadioButton: WMICCustomRadioButton,
            wmicCustomTooltip: WMICCustomTooltip
        },
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    const modalProps = {
        modalTitle: translator(messages.loadingYourQuote),
        isOpen: true
    };

    const skipPageWhen = useCallback(() => {
        return new Promise(((resolve) => { resolve(retrieveContext.allowNavigationToPaymentDetails || retrieveContext.allowNavigationToRecurringPaymentAuthorization || retrieveContext.allowNavigationToRecurringPaymentDetails); }));
    }, [retrieveContext]);

    return (
        <WizardPage
            onNext={handleNext}
            skipWhen={skipPageWhen}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
            {isLoading && <WMICProgressModal {...modalProps} />}
        </WizardPage>
    );
}

PolicyInformationPage.propTypes = wizardProps;
export default PolicyInformationPage;