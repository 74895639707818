/* eslint-disable quote-props */
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { ProductMediaMapUtil } from 'wmic-portals-utils-js';

const SECTOR1 = 1711;
const SECTOR2 = 5710;
const SECTOR3 = 8111;
const STOCK_PHOTO_URI = 'stock-photos';
const occupationImageMap = {
    'sector1': 'sector1',
    'sector2': 'sector2',
    'sector3': 'sector3',
    'default': 'sector4'
};

const questionMap = {
    '01': 'mi-local-shipping',
    '02': 'mi-business',
    '03': 'mi-desktop-windows',
    '04': 'mi-assignment',
    '05': 'mi-monetization_on',
    '06': 'mi-group',
    '07': 'mi-pan-tool',
    '08': 'mi-flight',
    '09': 'mi-archive',
    '10': 'mi-keyboard',
    '11': 'mi-explore',
    '12': 'mi-phone',
    '13': 'mi-archive',
    'default': ''
};

function normaliseSIC(SIC) {
    let normalisedId = 'default';
    const parsedSIC = _.toInteger(SIC);
    if (!Number.isInteger(parsedSIC)) {
        return normalisedId;
    }
    switch (parsedSIC) {
        case (SECTOR1):
            normalisedId = 'sector1';
            break;
        case (SECTOR2):
            normalisedId = 'sector2';
            break;
        case (SECTOR3):
            normalisedId = 'sector3';
            break;
        default:
            break;
    }
    return normalisedId;
}

export default {
    getQuestionIcon(id) {
        return questionMap[id] || questionMap.default;
    },
    getOccupationImage(SIC) {
        const id = normaliseSIC(SIC);
        const image = occupationImageMap[id] || occupationImageMap.default;
        return `${STOCK_PHOTO_URI}/${image}.svg`;
    },
    getProductCodeIcon(productCode) {
        return ProductMediaMapUtil.getProductCodeIcon(productCode);
    },
    getProductCodeImage(productCode) {
        return ProductMediaMapUtil.getProductCodeImage(productCode);
    }
};
