/* eslint-disable max-len */
import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICCustomRadioButton, WMICProgressModal, WMICRetrieveInfoContext } from 'gw-capability-quoteandbind-common-react';
import {
    CONSTANTS,
    DeviceBreakpoint,
    Position,
    WMICRichTextUtil
} from 'wmic-portals-utils-js';
import metadata from './WMICContactPreferences.metadata.json5';
import messages from './WMICContactPreferences.messages';
import styles from './WMICContactPreferences.module.scss';

const EMAIL = 'email';
const MAIL = 'mail';

function WMICContactPreferences(props) {
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const retrieveContext = useContext(WMICRetrieveInfoContext);
    const { phoneNumber, phoneNumberRaw } = appConfig;

    const { wizardData: submissionVM, updateWizardData, goNext } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        onValidate
    } = useValidation('WMICContactPreferences');

    const [radioValue, setRadioValue] = useState(undefined);
    const [showContactPreferenceRequiredMessage, setContactPreferenceRequiredMessage] = useState(false);

    useEffect(() => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, 'bindData.distributionConsentType_WMIC', {
            aspects: {
                valid: false,
                required: true,
                aspects: false
            },
            value: null
        });
        _.set(newSubmissionVM, 'bindData.isRecordEmailDistributionConsent_WMIC', true);
        updateWizardData(newSubmissionVM);
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isOR = (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR);

    const setPreferredMethodOfDistributionAndConsentRequest = useCallback((value) => {
        let newSubmissionVM;
        setRadioValue(value);
        if (value === MAIL) {
            newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, 'bindData.distributionConsentRequest_WMIC', {
                systemOrigin: 'qnb',
                applicationFlow: 'USBuyRenters',
                consentType: 'distributionMethod',
                region: _.get(submissionVM, 'baseData.policyAddress.state.value.code'),
                distributionType: MAIL
            });
            _.set(newSubmissionVM, 'bindData.prefDistributionMethod_WMIC', MAIL);
            updateWizardData(newSubmissionVM);
        } else if (value === EMAIL) {
            newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, 'bindData.distributionConsentRequest_WMIC', {
                systemOrigin: 'qnb',
                applicationFlow: 'USBuyRenters',
                consentType: 'distributionMethod',
                region: _.get(submissionVM, 'baseData.policyAddress.state.value.code'),
                distributionType: EMAIL
            });
            _.set(newSubmissionVM, 'bindData.prefDistributionMethod_WMIC', EMAIL);
            updateWizardData(newSubmissionVM);
        }
    }, [submissionVM, updateWizardData, viewModelService]);

    useEffect(() => {
        if (radioValue) {
            setContactPreferenceRequiredMessage(false);
        }
    }, [radioValue]);

    const onNext = useCallback(async () => {
        if (!radioValue) {
            setContactPreferenceRequiredMessage(true);
        }

        if (!submissionVM.bindData.aspects.subtreeValid) {
            return false;
        }

        setIsLoading(true);
        submissionVM.value = await LoadSaveService.updateQuotedSubmission(
            submissionVM.value,
            authHeader
        );
        updateWizardData(submissionVM);
        goNext();
        return submissionVM;
    }, [LoadSaveService, authHeader, radioValue, submissionVM, updateWizardData, goNext]);

    const skipPageWhen = useCallback(() => {
        return new Promise(((resolve) => { resolve(retrieveContext.allowNavigationToPaymentDetails || retrieveContext.allowNavigationToRecurringPaymentAuthorization || retrieveContext.allowNavigationToRecurringPaymentDetails); }));
    }, [retrieveContext]);

    const getOption = (translatedText) => {
        return <div className={styles.radioOption}>{WMICRichTextUtil.translateRichText(translatedText)}</div>
    }

    const resolvers = {
        resolveComponentMap: {
            wmicCustomRadioButton: WMICCustomRadioButton,
        },
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
        },
        withConsent: {
            content: isOR ? messages.withConsentOregon : messages.withConsent
        },
        emailOrCall: {
            content: WMICRichTextUtil.translateRichText(translator(messages.emailOrCall, {phoneNumberRaw, phoneNumber}))
        },
        optOut: {
            content: translator(messages.optOut)
        },
        updateEmail: {
            content: translator(messages.updateEmail)
        },
        requestCopies: {
            content: translator(messages.requestCopies)
        },
        contackPreferenceRadioButton: {
            availableValues: [
                {
                    code: EMAIL,
                    name: (
                        getOption(translator(messages.radioYes, { email: _.get(submissionVM, 'bindData.contactEmail.value') }))
                    )
                },
                {
                    code: MAIL,
                    name: (
                        getOption(translator(messages.radioNo))
                    )
                },
            ],
            value: radioValue,
            onValueChange: setPreferredMethodOfDistributionAndConsentRequest
        },
        contactPreferenceRequiredMessage: {
            visible: showContactPreferenceRequiredMessage
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    const modalProps = {
        modalTitle: translator(messages.loadingYourQuote),
        isOpen: true
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={skipPageWhen}
            showQuoteDisclaimer={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
            />
            {isLoading && <WMICProgressModal {...modalProps} />}
        </WizardPage>
    );
}

WMICContactPreferences.propTypes = wizardProps;
export default WMICContactPreferences;