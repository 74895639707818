import { defineMessages } from 'react-intl';

export default defineMessages({
    printVersion: {
        id: 'quoteandbind.common.views.confirmation.Print',
        defaultMessage: 'Printable Version'
    },
    confirmationOfPurchase: {
        id: 'quoteandbind.common.views.confirmation.Confirmation of Purchase',
        defaultMessage: 'Confirmation of Purchase'
    },
    name: {
        id: 'quoteandbind.views.confirmation.Name',
        defaultMessage: 'Name'
    },
    residence: {
        id: 'quoteandbind.views.confirmation.Home Address',
        defaultMessage: 'Residence'
    },
    paymentPlan: {
        id: 'quoteandbind.views.confirmation.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    monthlyInstallments: {
        id: 'quoteandbind.views.confirmation.Monthly Installments',
        defaultMessage: 'Monthly Installments'
    },
    withdrawalSchedule: {
        id: 'quoteandbind.views.confirmation.A schedule of your withdrawals will be sent.',
        defaultMessage: 'A schedule of your withdrawals will be sent.'
    },
    paymentReceived: {
        id: 'quoteandbind.views.confirmation.Payment Received',
        defaultMessage: 'Payment Received'
    },
    thankYou: {
        id: 'quoteandbind.common.views.confirmation.Thank you for purchasing. Selected Method',
        defaultMessage: 'Thank you for purchasing your renters insurance policy with Wawanesa Insurance. You will receive your confirmation documents via {preferredDistributionMethod} shortly.'
    },
    contactUs: {
        id: 'quoteandbind.common.views.confirmation.contact-us',
        defaultMessage: 'Please contact us at {phoneNumber} for any questions about your new policy.'
    }
});