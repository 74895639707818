/* eslint-disable prefer-template */
import React from "react";
import { MetadataForm } from '@jutro/legacy/uiconfig';
import { useTranslator } from "@jutro/locale";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { WMICSidebarBoxes } from "gw-portals-wizard-components-ui";
import appConfig from 'app-config';

import metadata from "./WMICPolicyInProcessPage.metadata.json5";
import messages from "./WMICPolicyInProcessPage.messages";
import styles from "./WMICPolicyInProcessPage.module.scss";

function WMICPolicyInProgress(props) {
    const { location } = props;
    const translator = useTranslator();
    const quoteID = new URLSearchParams(location.search).get('quoteID')
    const { phoneNumber } = appConfig;

    const overrideProps = {
        confirmationOfPurchase: {
            content: translator(messages.confirmationOfPurchase)
        },
        confirmationMessage: {
            content: `${translator(messages.thankYou)} ${translator(messages.contactUs, { phoneNumber })
            }`
        },
        quoteNumber: {
            content: `${translator(messages.quoteNumber, {
                quoteNumber: quoteID
            })}`
        },
        callCenterHours: {
            content: translator(messages.callCenterHours)
        },
        weekdayHours: {
            content: translator(messages.weekdayHours)
        },
        weekendHours: {
            content: translator(messages.weekendHours)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            wmicSidebarBoxes: WMICSidebarBoxes
        }
    };

    return (
        <MetadataForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap} />
    );
}

WMICPolicyInProgress.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired
    })
};
WMICPolicyInProgress.defaultProps = {
    location: undefined
};
export default withRouter(WMICPolicyInProgress);
