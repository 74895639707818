import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { MockUpUtil } from 'wmic-portals-utils-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import messages from './QuotePage.messages';
import styles from './QuotePage.module.scss';

import metadata from './QuotePage.metadata.json5';

function generateClauseData(columnData, coveragePath) {
    return columnData.map(({ lob, code }) => {
        const completeCoveragePath = `coverages.${coveragePath}`;

        return {
            code: code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: _.get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const structureCustomQuote = (submissionVM, affectedQuote, clauses) => {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    };
};

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.homeowners.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings.map((lobOffering, lobIndex) => {
        const quoteDataIndex = quoteOfferings.findIndex(
            (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
        );
        const quoteData = quoteOfferings[quoteDataIndex];
        return {
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                data: quoteData
            },
            lob: {
                path: `${lobOfferingPath}.children[${lobIndex}]`,
                data: lobOffering
            }
        };
    }).filter(({ quote }) => !_.isUndefined(quote.data));
    return _.sortBy(columnData, ['code']);
};

const getCoveragesUniqueID = (submissionVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (coverage) => ({ coverageUniqueID: coverage.publicID });

    const offerings = _.get(submissionVM, 'lobData.homeowners.offerings.value');
    const baseCoverages = _.uniqBy(
        offerings.flatMap((offering) => offering.coverages
            .baseCoverages.map(structureClauseTableData)),
        'coverageUniqueID'
    );
    const additionalCoverages = _.uniqBy(
        offerings.flatMap((offering) => offering.coverages
            .additionalCoverages.map(structureClauseTableData)),
        'coverageUniqueID'
    );
    return {
        baseCoverages,
        additionalCoverages
    };
};

const generateTableData = (submissionVM, columnData) => {
    const uniqueID = getCoveragesUniqueID(submissionVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: _.get(messages, coverageType),
        data: _.get(uniqueID, coverageType),
        tableContent: generateClauseData(columnData, coverageType)
    }));
};

const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

function QuotePage(props) {
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate, disregardFieldValidation } = useValidation('QuotePage');
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardSnapshot,
        wizardData: submissionVM,
        updateWizardData,
        underwritingIssues
    } = props;
    const [initialSubmissionVM, setInitialSubmissionVM] = useState(undefined);

    useEffect(() => {
        // inject mock contactPhone to prevent wizardData.subtreeValid is invalid
        submissionVM.value = MockUpUtil.setMockData(
            submissionVM.value,
            'quote.ho',
            'bindData.contactPhone'
        );

        setInitialSubmissionVM(viewModelService.clone(wizardSnapshot));
        // Store the initial SubmissionVM when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const getNewSubmissionVM = useCallback(
        (response, lobPath, quotePath, lobName) => {
            const clonedSubmissionVM = viewModelService.clone(submissionVM);
            const updatedClauses = _.get(response, `coverages.${lobName}`);
            // Update local offering with new one from xcenter
            _.set(clonedSubmissionVM, `${lobPath}.coverages`, updatedClauses);
            // Update local quote with new one from xcenter
            const status = _.get(response, 'quote.status', 'Draft');
            _.set(clonedSubmissionVM, `${quotePath}.status`, status);
            // Update premium with new one from xcenter
            _.set(clonedSubmissionVM, `${quotePath}.premium`, response.quote.premium);
            // Update local errorsAndWarnings with new one from xcenter
            _.set(clonedSubmissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
            return clonedSubmissionVM;
        },
        [viewModelService, submissionVM]
    );

    const onUpdateCustomQuote = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName, true);
            return CustomQuoteService.updateCustomQuoteCoverages(customQuote, authHeader).then(
                (response) => {
                    const newSubmissionVM = getNewSubmissionVM(
                        response,
                        lobPath,
                        quotePath,
                        lobName
                    );
                    const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.baseCoverages`
                    );
                    const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.additionalCoverages`
                    );
                    const allRemovedFields = [
                        ...removedFieldsFromBaseCoverages,
                        ...removedFieldsFromAdditionalCoverages
                    ];
                    disregardFieldValidation(allRemovedFields);
                    updateWizardData(newSubmissionVM);
                }
            );
        },
        [
            submissionVM,
            CustomQuoteService,
            authHeader,
            getNewSubmissionVM,
            disregardFieldValidation,
            updateWizardData
        ]
    );

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            return onUpdateCustomQuote(basePath, lobPath, quotePath);
        },
        [onUpdateCustomQuote]
    );

    const handlePrint = () => {
        window.print();
    };

    const onScheduleChange = useCallback(
        (schedule, path) => {
            const lobOfferingPath = 'lobData.homeowners.offerings.children[0]';
            const quoteOfferingPath = 'quoteData.offeredQuotes.children[0]';
            writeValue(schedule, `${path}.value`);
            const offering = _.get(submissionVM, `${lobOfferingPath}.value`);
            setStaleQuoteBranchCode(offering.branchCode);
            return onUpdateCustomQuote({}, lobOfferingPath, quoteOfferingPath);
        },
        [onUpdateCustomQuote, submissionVM, writeValue]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        [submissionVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeSubmission(value, changedPath);
            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, syncCoverages]
    );

    const resetQuote = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(initialSubmissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.forceUpdateCustomQuoteCoverages(customQuote, authHeader).then(
                (response) => {
                    const newSubmissionVM = getNewSubmissionVM(
                        response,
                        lobPath,
                        quotePath,
                        lobName
                    );
                    updateWizardData(newSubmissionVM);
                }
            );
        },
        [initialSubmissionVM, CustomQuoteService, authHeader, getNewSubmissionVM, updateWizardData]
    );

    const recalculate = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.updateCustomQuote(customQuote, authHeader).then(
                (response) => {
                    setStaleQuoteBranchCode(undefined);
                    const newSubmissionVM = getNewSubmissionVM(
                        response,
                        lobPath,
                        quotePath,
                        lobName
                    );
                    updateWizardData(newSubmissionVM);
                    return response;
                }
            );
        },
        [submissionVM, CustomQuoteService, authHeader, getNewSubmissionVM, updateWizardData]
    );

    const buyNow = useCallback(
        async (lobPath, quotePath) => {
            const lobOffering = _.get(submissionVM, `${lobPath}.value`);
            const quoteOffering = _.get(submissionVM, `${quotePath}.value`);
            const quoteID = _.get(submissionVM, 'quoteID.value');
            const sessionUUID = _.get(submissionVM, 'sessionUUID.value');

            await CustomQuoteService.setSelectedVersionOnSubmission(
                quoteID,
                lobOffering.branchName,
                sessionUUID,
                authHeader
            );

            // set chosen quote
            _.set(submissionVM, 'bindData.chosenQuote.value', quoteOffering.publicID);
            submissionVM.value = MockUpUtil.cleanUpMockedProperties(
                submissionVM.value,
                'quote.ho',
                'bindData.contactPhone'
            );
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData, CustomQuoteService, authHeader]
    );

    const columnData = generateColumnData(submissionVM);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        quoteTable: {
            columnData: columnData,
            tableData: generateTableData(submissionVM, columnData),
            underwritingIssues: underwritingIssues,
            filterUWIssuesInCustomOffering: false,
            quoteID: submissionVM.quoteID.value
        },
        startDate: {
            visible: showQuoteStartDate
        },
        printPage: {
            visible: !showQuoteStartDate
        }
    };

    const onStaleQuoteBranchCode = useCallback(() => {
        return staleQuoteBranchCode;
    }, [staleQuoteBranchCode]);

    return (
        <WizardPage
            showNext={false}
            showCancel={false}
            showPrevious={false}
            disableNext={!isComponentValid}
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveClassNameMap: styles,
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
                        onRecalculate: recalculate,
                        onResetQuote: resetQuote,
                        onChangeSubmissionAndSync: changeSubmissionAndSync,
                        onChangeSubmission: changeSubmission,
                        onSyncCoverages: syncCoverages,
                        onScheduleChange: onScheduleChange,
                        onStaleQuoteBranchCode: onStaleQuoteBranchCode,
                        onPrint: handlePrint
                    }
                };
                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardData}
                        onValidationChange={onValidate}
                        classNameMap={resolvers.resolveClassNameMap}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                );
            }}
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
