/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from '@jutro/locale';
import { WMICVariousUtil } from 'wmic-portals-utils-js';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withTracking } from '@xengage/gw-portals-tracking-react';

import metadata from './WMICKnockoutPage.metadata.json5';
import messages from './WMICKnockoutPage.messages';

function WMICKnockoutPage(props) {
    const { location } = props;
    const translator = useTranslator();
    const quoteNumber = location.state.quoteID;
    const reasons = location.state.underwritingIssues;

    const getRecommendationText = () => {
        return (
            <span>
                {translator(messages.weRecommend1)} <a href="tel:1-888-995-3111">{translator(messages.weRecommendPhoneNumber)}</a> {translator(messages.weRecommend2)}
            </span>
        );
    };

    const getRequestText = () => {
        return (
            <span>
                {translator(messages.requestDetails)} <a href="tel:1-877-WAWANESA (929-2637)">{translator(messages.requestPhoneNumber)}</a> .
            </span>
        );
    };

    const getQuoteNumber = () => {
        return (
            <span>
                {translator(messages.yourQuoteNumber)} <b>{quoteNumber}</b> .
            </span>
        );
    };

    const getByCallingText = () => {
        return (
            <span>
                {translator(messages.orByCalling)} <a href="tel:1-503-643-5448"> {translator(messages.contactPhone)} </a> .
            </span>
        );
    };

    const getFeedbackText = () => {
        return (
            <span>
                {translator(messages.haveSuggestion)} <a href="mail:usafdbk@wawanesa.com">{translator(messages.feedbackMail)}</a> .
            </span>
        );
    };

    const getReturnToWawanesaLink = () => {
        return (
            <span>
                <a href="wawanesa.com">{translator(messages.returnToWawanesa)}</a> .
            </span>
        );
    };

    const overrideProps = {
        wmicKnockoutTitle: {
            content: WMICVariousUtil.getTextWithIcon('wmicKOIconColor fa fa-fw fa-exclamation-circle', translator(messages.unableToOffer))
        },
        wmicKnockoutPageTextSectionHeader: {
            content: getRecommendationText()
        },
        knockoutReasonsIterable: {
            data: reasons,
            content: reasons,
            visible: true
        },
        knockoutRequestDetailsText: {
            content: getRequestText()
        },
        quoteNumber: {
            content: getQuoteNumber()
        },
        knockoutEligibilityAddressTextContinued: {
            content: getByCallingText()
        },
        suggestionText: {
            content: getFeedbackText()
        },
        returnToWawanesaLink: {
            content: getReturnToWawanesaLink()
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
        />
    );
}

WMICKnockoutPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})),
            quoteID: PropTypes.string
        })
    }).isRequired
};

export default withViewModelService(withRouter(withTracking(WMICKnockoutPage)));
