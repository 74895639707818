import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { MockUpUtil } from 'wmic-portals-utils-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import AdditionalInterestComponent from '../../components/AdditionalInterest/AdditionalInterestComponent';
import metadata from './VehiclesPage.metadata.json5';
import messages from './VehiclesPage.messages';

function VehiclesPage(props) {
    const modalApi = useModal();
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [isPageInitialised, updateIsPageInitialised] = useState(false);
    const { LoadSaveService } = useDependencies('LoadSaveService');

    const { authHeader } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        registerInitialComponentValidation
    } = useValidation('VehiclePage');

    const createVehicleVM = useCallback(() => {
        const vehicles = submissionVM.lobData.personalAuto.coverables.vehicles.value;
        if (_.isEmpty(vehicles)) {
            const accountHolder = submissionVM.baseData.accountHolder.value;
            const vehicleObj = {
                licenseState: accountHolder.primaryAddress.state,
                costNew: {
                    amount: ''
                },
                primaryUse: 'commuting'
            };
            // eslint-disable-next-line max-len
            const { _xCenter, _dtoName } = submissionVM.lobData.personalAuto.coverables.vehicles;
            const vehicleVM = viewModelService.create(vehicleObj, _xCenter, _dtoName);
            submissionVM.lobData.personalAuto.coverables.vehicles.pushElement(vehicleVM);
            updateWizardData(submissionVM);
        }
    }, [submissionVM, updateWizardData, viewModelService]);

    useEffect(() => {
        if (!isPageInitialised) {
            const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
            const vehicles = _.get(submissionVM, `${vehiclesPath}.value`);

            const newVehicles = vehicles.map((vehicle, index) => {
                const pathToCheckForMockData = `${vehiclesPath}.${index}`;
                const isLincenseNumberMocked = MockUpUtil.isValueMocked(
                    submissionVM.value,
                    'quote.pa',
                    `${pathToCheckForMockData}.license`
                );
                const isVINMocked = MockUpUtil.isValueMocked(
                    submissionVM.value,
                    'quote.pa',
                    `${pathToCheckForMockData}.vin`
                );
                const newVehicle = {
                    ...vehicle,
                    vin: isVINMocked ? undefined : vehicle.vin,
                    costNew: isLincenseNumberMocked
                        ? {
                            ...vehicle.costNew,
                            amount: undefined
                        }
                        : vehicle.costNew
                };
                if (isLincenseNumberMocked && newVehicle.license) {
                    delete newVehicle.license;
                }
                return newVehicle;
            });

            if (!_.isEqual(newVehicles, vehicles)) {
                _.set(submissionVM, `${vehiclesPath}.value`, newVehicles);
                updateWizardData(submissionVM);
            }

            if (_.isEmpty(vehicles)) {
                createVehicleVM();
            }
            updateIsPageInitialised(true);
        }
    }, [createVehicleVM, isPageInitialised, submissionVM, updateWizardData]);

    const addVehicle = useCallback(() => {
        const accountHolder = submissionVM.baseData.accountHolder.value;
        const vehicleObj = {
            licenseState: accountHolder.primaryAddress.state,
            costNew: {
                amount: ''
            },
            primaryUse: 'commuting'
        };
        // eslint-disable-next-line max-len
        const { _xCenter, _dtoName } = submissionVM.lobData.personalAuto.coverables.vehicles;
        const vehicleVM = viewModelService.create(vehicleObj, _xCenter, _dtoName);
        submissionVM.lobData.personalAuto.coverables.vehicles.pushElement(vehicleVM);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const removeVehicle = useCallback(
        (evt) => {
            const vehicleListPath = 'lobData.personalAuto.coverables.vehicles.value';
            const currentPath = evt.path;
            const vehiclePath = currentPath.replace(/\.children\[(\d+)\]/, '.children[$1].value');
            const vehicle = _.get(submissionVM, vehiclePath);
            const vehicleList = _.get(submissionVM, vehicleListPath);
            const vehicleIndex = vehicleList.findIndex(
                (driverFromList) => _.isEqual(driverFromList, vehicle)
            );

            modalApi.showConfirm({
                title: messages.removeVehicleTitle,
                message: messages.removeVehicleDescription,
                status: 'warning',
                icon: 'mi-error-outline'
            }).then(
                (results) => {
                    if (results !== 'confirm') {
                        return _.noop();
                    }
                    const newVehicleList = _.remove(vehicleList,
                        (vehicleFromList) => _.isEqual(vehicleFromList, vehicle));

                    _.set(submissionVM, newVehicleList, vehicleListPath);
                    disregardFieldValidation(`vehicle${vehicleIndex}`);
                    updateWizardData(submissionVM);
                    return true;
                }, _.noop
            );
        },
        [disregardFieldValidation, submissionVM, updateWizardData]
    );

    const addAdditionalInterest = useCallback(
        (additionalInterestPath) => {
            const vehicleAdditionalInterests = _.get(submissionVM, additionalInterestPath);

            // initialize additional interest VM
            vehicleAdditionalInterests.value = vehicleAdditionalInterests.value || [];

            const additionalInterestTypedPath = 'lobData.personalAuto.coverables.addInterestTypeCategory';
            const initialValue = {
                policyAdditionalInterest: {
                    subtype: 'Person',
                    primaryAddress: {}
                }
            };

            const initialContext = {
                AdditionalInterestType: _.get(submissionVM, additionalInterestTypedPath)
            };
            const { _xCenter, _dtoName } = vehicleAdditionalInterests;

            const currentAdditionalInterest = viewModelService.create(
                initialValue,
                _xCenter,
                _dtoName,
                initialContext
            );

            vehicleAdditionalInterests.pushElement(currentAdditionalInterest);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const updateAdditionalInterests = useCallback(() => {
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const generateOverrides = useCallback(() => {
        const vehiclePath = 'lobData.personalAuto.coverables.vehicles';
        const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value', []);

        const overrides = vehicles.map((vehicle, index) => {
            let driverTitle = translator(messages.paVehicleTitleNumber);

            if (vehicles.length > 1 && index !== 0) {
                driverTitle = `${translator(messages.paVehicleTitleNumber)} ${index + 1}`;
            }

            return {
                [`paDeleteVehicle${index}`]: {
                    visible: vehicles.length > 1
                },
                [`paVehicleTitle${index}`]: {
                    content: driverTitle
                },
                [`paAddAdditionalInterest${index}`]: {
                    onClick: () => addAdditionalInterest(
                        `${vehiclePath}.children[${index}].additionalInterests`
                    )
                },
                [`additionalinterestcomponentId${index}`]: {
                    updateAdditionalInterests,
                    value: _.get(submissionVM, `${vehiclePath}.children[${index}]`)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [addAdditionalInterest, submissionVM, translator, updateAdditionalInterests]);

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
            submissionVM.value,
            authHeader
        );
        return submissionVM;
    }, [LoadSaveService, authHeader, submissionVM]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        addVehicle: {
            disabled: !isComponentValid
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddVehicleClick: addVehicle,
            onAddAdditionalInterest: addAdditionalInterest,
            onRemoveVehicle: removeVehicle,
            onValidate
        },
        resolveComponentMap: {
            additionalinterestcomponent: AdditionalInterestComponent
        }
    };

    const isSubmissionQuoted = useCallback(() => {
        return _.get(submissionVM.value, 'baseData.periodStatus') === 'Quoted';
    }, [submissionVM]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    useEffect(() => {
        registerInitialComponentValidation(isSubmissionQuoted);
    }, [isSubmissionQuoted, registerInitialComponentValidation]);

    if (!isPageInitialised) {
        return null;
    }

    return (
        <WizardPage onNext={onNext} skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

VehiclesPage.propTypes = wizardProps;
export default VehiclesPage;
