/* eslint-disable prefer-template */
import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import { IntlContext, useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import htmlParser from 'html-react-parser';
import { WMICSidebarBoxes } from 'gw-portals-wizard-components-ui';
import { WMICButton } from 'wmic-components-platform-react';

import metadata from './WMICSuccessPage.metadata.json5';
import messages from './WMICSuccessPage.messages';
import styles from './WMICSuccessPage.module.scss';

function printQuote() {
    const printContents = document.getElementById('printContainer').innerHTML;

    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
        const popupWindow = window.open('', '_blank', 'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWindow.window.focus();
        popupWindow.document.write('<!doctype html><html><head><title>Quote & Buy</title><link rel="stylesheet" href="../styles/css/application.css"/><link rel="stylesheet" href="../styles/css/print.css" type="text/css"/><script type="text/javascript">setTimeout(function () { window.print(); }, 500); window.onfocus = function () { setTimeout(function () { window.close(); }, 500); }</script></head><body>' + printContents + '</body></html>');
    } else {
        const popupWindow = window.open('', '_blank', 'width=800,height=600');
        popupWindow.document.open();
        popupWindow.document.write('<html><head><title>Quote & Buy</title><link rel="stylesheet" href="../styles/css/application.css"/><link rel="stylesheet" href="../styles/css/print.css" type="text/css"/></head><body onload="window.print()">' + printContents + '</body></html>');
        popupWindow.document.close();
    }

    return true;
}

function WMICSuccessPage(props) {
    const { location } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const [submission] = useState(_.get(location, 'state.submission'));
    const [isPrinting, setPrintingState] = useState(false);
    const MONTHLY_PLAN_NAME = 'Monthly Automated Recurring';
    const { phoneNumber } = appConfig;

    useEffect(() => {
        if (isPrinting) {
            // The accordion component requires a new rendering cycle to be trigger,
            // this allows us to wait until that has completed
            const timeoutHandle = setTimeout(() => {
                window.print();
                // Timeout added to delay resetting the print state after the print is called,
                // the print state was being reset before the print preview loaded on actual device
                setTimeout(setPrintingState(false), 1000);
            }, 500);
            return () => clearTimeout(timeoutHandle);
        }
        return _.noop;
    }, [isPrinting]);

    const getAddress = useCallback(
        () => {
            const address = _.get(submission, 'baseData.policyAddress');
            const addressLine1 = _.get(address, 'addressLine1');
            const city = _.get(address, 'city');
            const state = _.get(address, 'state');
            const postalCode = _.get(address, 'postalCode');

            return `${addressLine1}<br>${city}, ${state} ${postalCode}`;
        }, [submission]
    );

    const getEffectiveDates = useCallback(
        () => {
            const startDate = _.get(submission, 'baseData.periodStartDate', { day: 1, month: 1, year: 2021 });
            const endDate = _.get(submission, 'baseData.periodEndDate', { day: 1, month: 1, year: 2021 });

            const sDate = new Date(startDate.year, startDate.month, startDate.day);
            const eDate = new Date(endDate.year, endDate.month, endDate.day);

            return `${intl.formatDate(sDate, { year: 'numeric', month: 'long', day: '2-digit' })} - ${intl.formatDate(eDate, { year: 'numeric', month: 'long', day: '2-digit' })}`;
        }, [intl, submission]
    );

    const getPaymentPlan = useCallback(
        () => {
            let toReturn;
            const allPlans = _.get(submission, 'bindData.paymentPlans');
            const selectedPaymentPlan = _.get(submission, 'bindData.selectedPaymentPlan');

            if (!_.isEmpty(allPlans)) {
                const chosenPlan = allPlans.find((plan) => {
                    return plan.billingId === selectedPaymentPlan;
                });
                toReturn = chosenPlan;
            }

            return toReturn;
        }, [submission]
    );

    const getFormattedAmount = (currAmount) => {
        const amount = _.get(currAmount, 'amount');
        const currency = _.get(currAmount, 'currency', 'USD');
        
        const formattedNumber = intl.formatNumber(
            amount,
            {
                style: 'currency',
                currency: currency,
                currencyDisplay: 'symbol'
            }
        );

        return formattedNumber;
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            readOnly: true
        },
        wizard: {
            visible: !_.isNil(submission)
        },
        confirmationMessage: {
            content: `${translator(messages.thankYou, { preferredDistributionMethod: _.get(submission, 'bindData.prefDistributionMethod_WMIC') })} ${translator(messages.contactUs, { phoneNumber })}`
        },
        address: {
            value: htmlParser(getAddress())
        },
        policyPeriod: {
            value: getEffectiveDates()
        },
        paymentPlan: {
            value: _.get(getPaymentPlan(), 'name')
        },
        policyTotal: {
            value: getFormattedAmount(_.get(getPaymentPlan(), 'total'))
        },
        monthlyInstallments: {
            visible: _.get(getPaymentPlan(), 'name') === MONTHLY_PLAN_NAME,
            value: getFormattedAmount(_.get(getPaymentPlan(), 'installment'))
        },
        paymentSchedule: {
            visible: _.get(getPaymentPlan(), 'name') === MONTHLY_PLAN_NAME,
        },
        paymentReceived: {
            visible: !(_.get(getPaymentPlan(), 'name') === MONTHLY_PLAN_NAME),
            value: getFormattedAmount(_.get(getPaymentPlan(), 'downPayment'))
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: printQuote
        },
        resolveComponentMap: {
            wmicSidebarBoxes: WMICSidebarBoxes,
            WMICButton
        }
    };

    return (
        <MetadataForm
            uiProps={metadata.pageContent}
            data={submission}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap} />
    );
}

WMICSuccessPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            submissionData: PropTypes.shape({
            })
        })
    })
};
WMICSuccessPage.defaultProps = {
    location: undefined
};
export default withRouter(WMICSuccessPage);