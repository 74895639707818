import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { loadGoogleMapsAPI } from '@xengage/gw-portals-google-maps-js';
import { ExtractAddressUtil } from 'wmic-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { QuickQuoteWizardPageTemplate } from 'gw-capability-quoteandbind-common-react';
import { AddressLookupService } from 'gw-capability-address';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import styles from './YourLocationPage.module.scss';
import metadata from './YourLocationPage.metadata.json5';
import messages from './YourLocationPage.messages';

function YourLocationPage(props) {
    const modalApi = useModal();
    const { wizardData, updateWizardData } = props;
    const {
        submissionVM,
        isManualEntryMode,
        showAddressOnMap,
        isUserUsingCurrentLocation
    } = wizardData;
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation(
        'YourLocationPage'
    );
    function setAddressToManualEntryMode() {
        _.set(wizardData, 'showAddressOnMap', false);
        _.set(wizardData, 'isUserUsingCurrentLocation', false);
        _.set(wizardData, 'isManualEntryMode', true);
        updateWizardData(wizardData);
    }

    function setAddressToSearchMode() {
        _.set(wizardData, 'isManualEntryMode', false);
        updateWizardData(wizardData);
    }

    const updateAddressFromCoordinates = (coords) => {
        // eslint-disable-next-line
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: coords }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const adr = ExtractAddressUtil.getAddress(results[0].address_components);
                    if (adr.addressLine1 && adr.city && adr.state && adr.postalCode) {
                        _.set(
                            wizardData.submissionVM,
                            'policyAddress.addressLine1.value',
                            adr.addressLine1
                        );
                        _.set(wizardData.submissionVM, 'policyAddress.city.value', adr.city);
                        _.set(
                            wizardData.submissionVM,
                            'policyAddress.postalCode.value',
                            adr.postalCode
                        );
                        _.set(wizardData.submissionVM, 'policyAddress.state.value', adr.state);
                        _.set(wizardData, 'showAddressOnMap', true);
                        updateWizardData(wizardData);
                    }
                } else {
                    modalApi.showAlert({
                        title: messages.error,
                        message: messages.addressNotFound,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                    setAddressToManualEntryMode();
                }
            } else {
                modalApi.showAlert({
                    title: messages.error,
                    message: messages.somethingWentWrong,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                setAddressToManualEntryMode();
            }
        });
    };

    const readAddressValue = () => {
        const address = _.get(submissionVM, 'policyAddress.value');
        if (
            address.addressLine1 === undefined
            || address.city === undefined
            || address.state === undefined
            || address.postalCode === undefined
        ) {
            return {};
        }
        return address;
    };

    const useMyCurrentLocation = async () => {
        await loadGoogleMapsAPI();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    updateAddressFromCoordinates({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                () => {
                    // When demo'ing and testing we do not actually use the users 'current' location
                    // as the user will not always be testing
                    // and demo'ing on an actual mobile device.
                    // For this reason, if the user blocks the
                    // browser from knowing their location we
                    // use the OOTB default locale details.
                    // This functionality should be implemented by the customer
                    updateAddressFromCoordinates({
                        lat: appConfig.defaultLocaleDetails.mapCenter.lat,
                        lng: appConfig.defaultLocaleDetails.mapCenter.lon
                    });
                }
            );
            _.set(wizardData, 'isUserUsingCurrentLocation', true);
            _.set(wizardData, 'showAddressOnMap', true);
        } else {
            modalApi.showAlert({
                title: messages.error,
                message: messages.somethingWentWrong,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
            setAddressToManualEntryMode();
        }
    };

    const renderItem = (address) => {
        return `${address.addressLine1}, ${address.city}, ${address.state}, ${address.postalCode}`;
    };

    const searchItems = async (userInput) => {
        let results = [];
        if (userInput.length >= 3) {
            const response = await AddressLookupService.lookupAddressUsingString(userInput);
            results = _(response.matches || [])
                .sortBy('matchAccuracy')
                .map('address');
        }
        return results;
    };

    const handleUserSelection = (address, path) => {
        _.set(wizardData, 'showAddressOnMap', true);
        _.set(wizardData.submissionVM, path, address);
        updateWizardData(wizardData);
    };

    const clearTypeaheadInput = () => {
        _.set(wizardData, 'showAddressOnMap', false);
        _.set(wizardData, 'isUserUsingCurrentLocation', false);
        _.set(wizardData.submissionVM, 'policyAddress.addressLine1.value', undefined);
        _.set(wizardData.submissionVM, 'policyAddress.city.value', undefined);
        _.set(wizardData.submissionVM, 'policyAddress.postalCode.value', undefined);
        _.set(wizardData.submissionVM, 'policyAddress.state.value', undefined);
        updateWizardData(wizardData);
    };

    const overrideProps = {
        addressLookup: {
            value: readAddressValue()
        },
        googleMapContainer: {
            visible: showAddressOnMap
        },
        manualAddressContainer: {
            visible: isManualEntryMode
        },
        typeaheadContainer: {
            visible: !isManualEntryMode
        },
        informationTextContainerTabletDesktop: {
            visible: !isManualEntryMode && !showAddressOnMap
        },
        informationTextContainerMobile: {
            visible: !isManualEntryMode && !showAddressOnMap
        },
        wrongAddressTryAgainContainerMobile: {
            visible: !isManualEntryMode && showAddressOnMap && isUserUsingCurrentLocation
        },
        addressNotInThisListContainer: {
            visible: !showAddressOnMap
        },
        addressNotInList: {
            visible: !isManualEntryMode
        },
        backToAddressSearch: {
            visible: isManualEntryMode
        },
        googleMapPlacement: {
            GOOGLE_MAPS_API_KEY: appConfig.credentials.GOOGLE_MAPS_API_KEY
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            typeaheadSearchItems: searchItems,
            onHandleUserSelection: handleUserSelection,
            onRenderItem: renderItem,
            onSetAddressToManualEntryMode: setAddressToManualEntryMode,
            onSetAddressToSearchMode: setAddressToSearchMode,
            onClearTypeaheadInput: clearTypeaheadInput,
            onUseMyCurrentLocation: useMyCurrentLocation
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    const getFormValidity = useCallback(() => {
        const isFormValid = wizardData.submissionVM.policyAddress.aspects.valid
            && wizardData.submissionVM.policyAddress.aspects.subtreeValid;
        return isFormValid;
    }, [wizardData]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    const handleModelChange = useCallback(
        (newModel) => {
            updateWizardData({
                ...wizardData,
                submissionVM: newModel
            });
        },
        [updateWizardData, wizardData]
    );

    return (
        <WizardPage template={QuickQuoteWizardPageTemplate} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={handleModelChange}
                overrideProps={overrideProps}
                resolveValue={readValue}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

YourLocationPage.propTypes = wizardProps;
export default YourLocationPage;
