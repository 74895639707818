import { defineMessages } from 'react-intl';

export default defineMessages({
    needHelp: {
        id: 'portal.index.Need Help?',
        defaultMessage: 'Need Help?',
    },
    liveChat: {
        id: 'portal.index.Live Chat',
        defaultMessage: 'Live Chat',
    },
    callNumber: {
        id: 'portal.index.Call',
        defaultMessage: 'Call'
    }
});