import 'react-app-polyfill/ie9';
import { start } from '@jutro/app';
import { initDefaultGA } from '@jutro/events';
import { LocaleService, G11nstore } from 'gw-portals-i18n-react';
import wmicTheme from 'wmic-qb-digital-theme-styles';
import { getConfigValue } from '@jutro/config';

import { WMICRegionUrlUtil } from 'wmic-portals-url-js';
import { CONSTANTS } from 'wmic-portals-utils-js';
import {
    BrowserRouter
} from 'react-router-dom';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import 'regenerator-runtime';

import { loadGoogleApiScript } from 'gw-ext-google-tools';
import App from './app/App';

import { configureLegacyComponentMap } from '@jutro/legacy/uiconfig';
configureLegacyComponentMap();

// Set app locale and language to 'en-US'
const localeInstance = new LocaleService();
localeInstance.saveLocale('en-US');
localeInstance.saveLanguage('en-US');

const { trackingConfig = {} } = appConfig;

const oneincScriptUrl = appConfig.serviceUrls.oneIncScriptUrl;
const oneIncScript = document.createElement('script');
oneIncScript.type = 'text/javascript';
oneIncScript.async = true;
oneIncScript.defer = true;
oneIncScript.src = oneincScriptUrl;
document.head.appendChild(oneIncScript);

const gaTrackingId = getConfigValue("GA_TRACKING_ID");

const getDefaultMessage = () => {
    let region = WMICRegionUrlUtil.getRegionByParam(window.location.search);
    if(!region) {
        region = WMICRegionUrlUtil.getRegion();
    }
    let result = appConfig.applicationDetails.applicationName;
    
    if (region === CONSTANTS.JURISDICTIONS.CA) {
        result = CONSTANTS.TITLE.CA;
    } else if (region === CONSTANTS.JURISDICTIONS.OR) {
        result = CONSTANTS.TITLE.OR;
    } else {
        result = CONSTANTS.TITLE.DEFAULT;
    }
    return result;
}

const recaptchaKey = appConfig.credentials.CAPTCHA_API_KEY;
const onloadScript = document.createElement('script');
onloadScript.append('function onRecaptchaLoadCallback() { window.recaptchaLoaded = true; }');
document.head.append(onloadScript);

const script = document.createElement('script');
script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}&onload=onRecaptchaLoadCallback`;
document.head.append(script);

const gaGtmToken = getConfigValue('GTM_TRACKING_ID');
const gtmScript = document.createElement('script');
gtmScript.append(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gaGtmToken}');`);
document.head.append(gtmScript);

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}

const config = {
    ...appConfig.credentials
}

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    themeConfig: wmicTheme,
    refreshOnLocaleChange: true,
    config: [config],
    Router: BrowserRouter,
    g11nStore: G11nstore(),
    routerBasename: '/quote-and-buy',

    onInit: () => {
        LocaleService.register();
        loadGoogleApiScript();
    },

    appName: {
        id: 'digital.appName',
        defaultMessage: getDefaultMessage()
    },

    appDescription: 'Wawanesa Renters Insurance policy will protect your belongings from unforeseen challenges like fire, water or theft. Get a Online Renters Insurance quote today.',
});
