import _ from 'lodash';
import { CONSTANTS } from 'wmic-portals-utils-js';

const isANIValid = (submissionData) => {
    const relationship = _.get(submissionData, 'baseData.addlNamedInsuredRelationship.value');
    const hasANI = _.get(submissionData, 'hasAdditionalNamedInsured') === true;
    return (!hasANI && relationship === undefined)
            || (hasANI && relationship !== undefined && relationship.code !== CONSTANTS.UNKNOWN);
};

const showANIField = (submissionData) => {
    const relationship = _.get(submissionData, 'baseData.addlNamedInsuredRelationship.value');
    return relationship !== undefined && relationship.code !== CONSTANTS.UNKNOWN;
};

const getANIValueForRelationship = (submissionData, relationship) => {
    if (relationship === CONSTANTS.UNKNOWN) {
        return undefined;
    }
    const anipersonWMIC = _.get(submissionData, 'baseData.aniperson_WMIC');
    
    if(anipersonWMIC.firstName) {
        return anipersonWMIC;
    }
    
    return {
        firstName: '',
        lastName: ''
    }
};

const getMailingAddress = (submissionVM) => {
    const sameMailingAddress = _.get(submissionVM, 'mailAndHomeAddrsEqual');
    const mailingAddressToSet = _.get(submissionVM, 'baseData.policyAddress.value');
    const mailingAddress = _.get(submissionVM, 'baseData.mailingAddress_WMIC.value');
    if (sameMailingAddress) {
        return mailingAddressToSet;
    }

    return mailingAddress;
};

const isDate = (value) => {
    switch (typeof value) {
        case 'number':
            return true;
        case 'string':
            return !isNaN(Date.parse(value));
        case 'object':
            if (value instanceof Date) {
                return !isNaN(value.getTime());
            }
            return false;
        default:
            return false;
    }
}

export default {
    isANIValid,
    showANIField,
    getANIValueForRelationship,
    getMailingAddress,
    isDate,
};
