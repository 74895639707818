/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import React, { Component } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withModalContext } from '@jutro/components';
import { AvailabilityService } from 'gw-capability-policycommon';
import { WMICRegionUrlUtil } from 'wmic-portals-url-js';
import { StartQuoteComponent } from 'wmic-components-qnb-common-react';
import { withAuthenticationContext } from 'wmic-digital-auth-react';
import { WMICModal, WMICProgressModal } from 'gw-capability-quoteandbind-common-react';
import {
    LocalDateUtil, CONSTANTS, WMICRichTextUtil,
    ERROR_CODE, WMICFeatureFlagUtil
} from 'wmic-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';

import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import metadata from './LandingPage.metadata.json5';
import styles from './LandingPage.module.scss';
import messages from './LandingPage.messages';

class LandingPageWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
    };

    state = {
        formData: {
            lobCategory: 'personal'
        },
        landingPageState: 'startQuote',
        isLoading: true,
        modalProps: {},
        isRentersQnBEnabled: true
    };

    componentDidMount() {
        const { location, authHeader } = this.props;
        WMICRegionUrlUtil.setRegion(location.search);
        const featureFlagsTypes = WMICFeatureFlagUtil.getFeatureFlags();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        WMICFeatureFlagUtil.useFlags(authHeader).then((result) => {
            const rentersQnbAvailable = result[featureFlagsTypes.RENTERSQNB]?.isAvailable;
            this.setState({ isRentersQnBEnabled: rentersQnbAvailable });
            if (rentersQnbAvailable) {
                // show landing page as it is available
                this.setState({ isLoading: false });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { history } = this.props;
        const { isRentersQnBEnabled } = this.state;
        const { isRentersQnBEnabled: prevIsRentersQnBEnabled } = prevState;

        if (isRentersQnBEnabled === false
            && isRentersQnBEnabled !== prevIsRentersQnBEnabled) {
            // Renter QnB feature flag is set to false, therefore we have to push the page not available
            history.push(`contact-us/${ERROR_CODE.QNB_UNAVAILABLE}`);
        }
    }

    handleValueChange = (value, path) => {
        const { formData } = this.state;
        _.set(formData, path, value);
        this.setState({ formData });
    };

    readValue = (id, path) => {
        const { formData } = this.state;
        return _.get(formData, path);
    };

    startQuote = (data, wizardLink) => {
        const { history } = this.props;
        const translator = this.context;

        if (data === 'QuickQuote' || data === 'Guidance') {
            history.push(wizardLink);
        } else {
            const modalPropsStartQuote = {
                modalTitle: translator(messages.checkingForAvailability),
                isOpen: true
            };
            this.setState({ isLoading: true, modalProps: modalPropsStartQuote });

            const showErrorModalNotAvailable = (availabilityResponse) => {
                const messageToShow = {};
                if (availabilityResponse !== null
                    && availabilityResponse !== undefined
                    && availabilityResponse.policyHoldConflict_WMIC) {
                    messageToShow.title = translator(messages.wereSorryTitle);
                    messageToShow.message = WMICRichTextUtil.translateRichText(translator(messages.policyholdMessage));
                } else {
                    messageToShow.title = messages.nooffer;
                    messageToShow.message = (
                        <span>
                            {translator(messages.weRecommend1)}
                        </span>
                    );
                }
                this.setState({ isLoading: false });
                this.props.modalContext.showModal(
                    <WMICModal
                        title={messageToShow.title}
                        message={messageToShow.message}
                        iconClass="error-text fa-3x fa fa-exclamation-circle"
                    />
                );
            };

            // eslint-disable-next-line radix
            const postalCodeInt = parseInt(data.postalCode.substring(0, 2));
            const postalCodePrefixRange = { low: 90, high: 96 };

            if (postalCodeInt < postalCodePrefixRange.low || postalCodeInt > postalCodePrefixRange.high) {
                showErrorModalNotAvailable();
            } else {
                data.effectiveRegion_WMIC = CONSTANTS.JURISDICTIONS.CA;
                WMICRegionUrlUtil.setRegion(`?region=${CONSTANTS.JURISDICTIONS.CA}`);

                data.effectiveDate_WMIC = LocalDateUtil.today();
                AvailabilityService.productAvailableBasedOnPostalCode(data).then((response) => {
                    if (response.isAvailable) {
                        const nextState = {
                            address: response.addressCompletion,
                            serverInfo: response.serverInfo_wmic,
                        };
                        history.push(wizardLink, nextState);
                        this.setState({ isLoading: false });
                    } else {
                        showErrorModalNotAvailable(response);
                    }
                });
            }
        }
    };

    onGoToRetrieveQuote = () => {
        const { history } = this.props;
        history.push('/retrieve-quote');
    }

    getWizardLink = (lobCode) => {
        const lobWizardLinks = {
            BusinessOwners: '/quote-bop',
            Homeowners: '/quote-ho',
            HOPHomeowners: '/quote-ho',
            PersonalAuto: '/quote-pa'
        };
        return lobWizardLinks[lobCode];
    };

    render() {
        const { submitted, formData, landingPageState, isLoading, modalProps } = this.state;
        const { capabilitiesConfig } = appConfig;

        const overrideProps = {
            '@field': {
                // apply to all fields
                onValueChange: this.handleValueChange,
                showErrors: submitted
            },
            personalContainer: {
                // modified to ensure start/retrieve quote displays correctly
                visible: formData.lobCategory === 'personal' && !(landingPageState === 'retrieveQuote')
            },
            businessContainer: {
                visible: formData.lobCategory === 'business'
            },
            startQuoteContainer: {
                visible: landingPageState === 'startQuote'
            },
            businessOwnersLOB: {
                visible: isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'quote', lob: 'bop' })
            }
        };

        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onGoToRetrieveQuote: this.onGoToRetrieveQuote,
                onEnterFlow: this.startQuote,
                onGetWizardLink: this.getWizardLink
            },
            // added custom start/retrieve quote components
            resolveComponentMap: {
                startQuoteComponent: StartQuoteComponent
            },
        };
        if (isLoading) {
            return <WMICProgressModal {...modalProps} />;
        }

        return (
            <div className={styles.main}>
                <MetadataContent
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    {...resolvers} />
            </div>
        );
    }
}
const LandingPage = withModalContext(LandingPageWithoutModalContext);
export const LandingPageComponent = LandingPage;
export default withAuthenticationContext(withRouter(LandingPage));
