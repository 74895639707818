/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICCustomInput, WMICModal } from 'gw-capability-quoteandbind-common-react';
import { WMICButton } from 'wmic-components-platform-react';

import metadata from './WMICEmailForm.metadata.json5';
import messages from './WMICEmailForm.messages';
import styles from './WMICEmailForm.module.scss';

function WMICEmailForm(props) {
    const modalApi = useModal();
    const translator = useTranslator();

    const {
        submissionVM, updateWizardData, onCancel, onResendEmail, setEditEmail, setEffectiveEmail
    } = props;
    const [isPageInitialized, setPageInitialized] = useState(false);
    const {
        onValidate
    } = useValidation('WMICContactPreferences');

    const areEmailAddressesValid = useCallback(() => {
        const email = _.get(submissionVM, 'bindData.payerEmail_WMIC');
        const emailConfirm = _.get(submissionVM, 'bindData.payerEmailConfirmation_WMIC');
        return email.value !== undefined && email.value !== '' && email.aspects && email.aspects.valid && emailConfirm.aspects && emailConfirm.aspects.valid;
    }, [submissionVM]);

    const areEmailAddressesMatching = useCallback(() => {
        const email1 = _.get(submissionVM, 'bindData.payerEmail_WMIC.value', '');
        const email2 = _.get(submissionVM, 'bindData.payerEmailConfirmation_WMIC.value', '');
        return email1.trim() === email2.trim();
    }, [submissionVM]);

    const areEmailAddressesValidAndMatching = () => {
        return areEmailAddressesValid() && areEmailAddressesMatching();
    };

    const sendUpdatedEmailAddress = () => {
        setEffectiveEmail(_.get(submissionVM, 'bindData.payerEmail_WMIC.value'));
        setEditEmail(false);
        onResendEmail(true, true);
    };

    const updateEmailAddress = () => {
        if (_.get(submissionVM, 'bindData.payerIsPNI_WMIC.value')) {
            modalApi.showModal(
                <WMICModal
                    title={messages.alert}
                    message={translator(messages.updatedEmail)}
                    iconClass="error-text fa-3x fa fa-exclamation-circle"
                    actionBtnLabel={messages.continue}
                    showCancelBtn
                    cancelBtnLabel={messages.cancel}
                />
            ).then(() => {
                return sendUpdatedEmailAddress();
            }, _.noop);
        } else {
            sendUpdatedEmailAddress();
        }
    };

    useEffect(() => {
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resolvers = {
        resolveComponentMap: {
            wmicInput: WMICCustomInput,
            WMICButton
        },
    };

    const overrideProps = {
        form: {
            className: styles.emailForm
        },
        emailAddress: {
            className: styles.field,
        },
        confirmEmailAddress: {
            className: styles.field
        },
        emailErrorContainer: {
            className: styles.errorEmail
        },
        emailError: {
            visible: areEmailAddressesValid() && !areEmailAddressesMatching()
        },
        emailButtonsContainer: {
            className: styles.buttons
        },
        saveAndSendButton: {
            onClick: updateEmailAddress,
            className: styles.emailFormButton,
            disabled: !areEmailAddressesValidAndMatching()
        },
        cancelButton: {
            onClick: onCancel,
            className: cx(styles.emailFormButton, styles.cancelButton)
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.emailForm}
            model={submissionVM}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={onValidate}
            componentMap={resolvers.resolveComponentMap}
            showErrors
        />
    );
}

WMICEmailForm.propTypes = {
    updateWizardData: PropTypes.func.isRequired,
    submissionVM: PropTypes.shape({}).isRequired,
    onCancel: PropTypes.func.isRequired,
    onResendEmail: PropTypes.func.isRequired,
    setEditEmail: PropTypes.func.isRequired,
    setEffectiveEmail: PropTypes.func.isRequired
};
export default WMICEmailForm;