import { defineMessages } from 'react-intl';

export default defineMessages({
    protectCar: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Products.HelpText.Personal Auto',
        defaultMessage: 'Protect your car with the right coverages for you.',
    },
    getQuote: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Features.HelpText.QuickQuote',
        defaultMessage: 'Get a quote for your car insurance in three quick steps.',
    },
    buyingHome: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Features.HelpText.Buying Home',
        defaultMessage: 'Buying home insurance that meets your needs has never been easier.',
    },
    protectFromRisks: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Products.HelpText.Business Owners',
        defaultMessage: 'Protect your business from a variety of risks.',
    },
    protectBusiness: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Features.HelpText.Guidance To Product',
        defaultMessage: 'Find the right protection for your business.',
    },
    business: {
        id: 'quoteandbind.views.Binary Choice - Business',
        defaultMessage: 'Business',
    },
    personal: {
        id: 'quoteandbind.views.Binary Choice - Personal',
        defaultMessage: 'Personal',
    },
    getGuidance: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.Get Guidance',
        defaultMessage: 'Get Guidance',
    },
    buisinessownerName: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Products.Name.Businessowners',
        defaultMessage: 'Businessowners',
    },
    homeownerName: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Products.Name.Homeowners',
        defaultMessage: 'Homeowners',
    },
    personalAutoName: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Products.Name.Personal Auto',
        defaultMessage: 'Personal Auto',
    },
    quickQuoteName: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Features.Name.Quick Quote',
        defaultMessage: 'Quick Quote',
    },
    guidanceToProductName: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Features.Name.Guidance To Product',
        defaultMessage: 'Guidance To Product',
    },
    checkingForAvailability: {
        id: 'wmic.quoteandbind.controllers.QuoteEntryCtrl.Checking for availability in your state...',
        defaultMessage: 'Checking for availability in your state...'
    },
    nooffer: {
        id: 'wmic.quoteandbind.controllers.QuoteEntryCtrl.Thanks for thinking of Wawanesa Insurance',
        defaultMessage: 'Thanks for thinking of Wawanesa Insurance'
    },
    weRecommend1: {
        id: 'wmic.quoteandbind.controllers.QuoteEntryCtrl.Unfortunately, Wawanesa does not offer renters insurance in your area.',
        defaultMessage: 'Unfortunately, Wawanesa does not offer renters insurance in your area.'
    },
    wereSorryTitle: {
        id: "wmic.quotenabind.pages.LandingPage.We're sorry",
        defaultMessage: "We're Sorry"
    },
    policyholdMessage: {
        id: 'wmic.quoteandbind.pages.LandingPage.Policy Hold Message',
        defaultMessage: '&lt;strong&gt;Wawanesa is not currently offering new renters policies in your area.&lt;/strong&gt;&lt;br/&gt;&lt;br/&gt;Please try again in 30 days. We apologize for the inconvenience and appreciate your understanding.'
    }
});
