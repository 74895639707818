import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'quoteandbind.recurring-payment-details.Payment Details',
        defaultMessage: 'Payment Details'
    },
    pageIntro: {
        id: 'quoteandbind.recurring-payment-details.Please tell us about the bank account for your monthly automated recurring payments',
        defaultMessage: 'Please tell us about the bank account for your monthly automated recurring payments.'
    },
    dateChangedModalTitle: {
        id: 'quoteandbind.recurring-payment-details.We have updated your policy effective date',
        defaultMessage: "We've updated your policy effective date"
    },
    dateChangedModalMessage: {
        id: 'quoteandbind.recurring-payment-details.The policy effective date you originally selected',
        defaultMessage: 'Your selected policy effective date is no longer available. The date in your quote has been updated to &lt;b&gt;{updatedEffectiveDate}&lt;/b&gt;. You can select a different policy effective date by &lt;a onClick=jumpToYourInfo&gt;returning to the Your Info page&lt;/a&gt;'
    },
    purchaseNow: {
        id: 'quoteandbind.recurring-payment-details.Purchase Now',
        defaultMessage: 'Purchase Now'
    },
    validationgBankingInfo: {
        id: 'quoteandbind.recurring-payment-details.Validating Banking Information',
        defaultMessage: 'Validating Banking Information'
    },
    authorizationTitle: {
        id: 'quoteandbind.recurring-payment-details.Monthly Automated Recurring Payment Authorization',
        defaultMessage: 'Monthly Automated Recurring Payment Authorization'
    },
    authorizationParagraph1: {
        id: 'quoteandbind.recurring-payment-details.authorizationParagraph1',
        defaultMessage: 'You have elected to participate in Wawanesa Insurance\'s Monthly Automated Recurring (MAR) Payment Plan for this policy and hereby authorize Wawanesa Insurance to electronically debit your specified banking account. All payments will be deducted from your account on the dates and in amounts indicated on the Automated Recurring Payment Notice (ARPN).'
    },
    authorizationParagraph2: {
        id: 'quoteandbind.recurring-payment-details.authorizationParagraph2',
        defaultMessage: 'You agree to contact us at least 7 days prior to a scheduled withdrawal for any changes to bank account information or withdrawal dates. Requests to change your policy may result in increased payment amounts. Depending on the timing of these requested changes, Wawanesa Insurance may initiate a withdrawal according to the original ARPN prior to you receiving an updated ARPN.'
    },
    authorizationParagraph3: {
        id: 'quoteandbind.recurring-payment-details.authorizationParagraph3',
        defaultMessage: 'You will no longer be eligible for MAR and be required to make payments under an alternate payment plan, if:'
    },
    authCondition1: {
        id: 'quoteandbind.recurring-payment-details.authCondition1',
        defaultMessage: 'Any payment is returned as unauthorized; or'
    },
    authCondition2: {
        id: 'quoteandbind.recurring-payment-details.authCondition2',
        defaultMessage: 'Two payments are returned for any reason in the same policy period for a 6-month policy; or'
    },
    authCondition3: {
        id: 'quoteandbind.recurring-payment-details.authCondition3',
        defaultMessage: 'Three payments are returned for any reason in the same policy period for a 12-month policy'
    },
    authorizationParagraph4: {
        id: 'quoteandbind.recurring-payment-details.authorizationParagraph4',
        defaultMessage: 'You acknowledge and agree that a non-sufficient funds fee will be assessed each time a payment is returned for insufficient funds.'
    },
    authorizationParagraph5: {
        id: 'quoteandbind.recurring-payment-details.authorizationParagraph5',
        defaultMessage: 'This authorization will remain in effect for all payments on this policy term and any renewal terms offered. You may choose to change your payment plan at any time through your online account, by notifying Wawanesa Insurance in writing or by calling 800-640-2920.'
    },
    iAgreeLabel: {
        id: 'quoteandbind.recurring-payment-details.iAgreeLabel',
        defaultMessage: 'I {fullName} ACKNOWLEDGE AND AGREE THAT I HAVE READ AND ACCEPT THE TERMS SET FORTH ABOVE'
    },
    authValidation: {
        id: 'quoteandbind.recurring-payment-details.Auth Validation',
        defaultMessage: 'Please indicate you\'ve read and agree to the authorization statement to purchase.'
    },
    loadingOneInc: {
        id: 'quoteandbind.recurring-payment-details.Loading...',
        defaultMessage: 'Loading...'
    }
});