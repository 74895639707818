// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PaQuoteInfo__infoBlock{display:flex;flex-direction:column;padding:var(--GW-SPACING-3);width:25%}.app__PaQuoteInfo__infoBlock:first-child{padding-left:0}.app__PaQuoteInfo__infoBlock:last-child{padding-right:0}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-pa-react/components/PaQuoteInfo/PaQuoteInfo.module.scss"],"names":[],"mappings":"AAAA,6BAGI,YAAA,CACA,qBAAA,CAFA,2BAAA,CADA,SAGA,CAEA,yCACI,cAAA,CAGJ,wCACI,eAAA","sourcesContent":[".infoBlock {\n    width: 25%;\n    padding: var(--GW-SPACING-3);\n    display: flex;\n    flex-direction: column;\n\n    &:first-child {\n        padding-left: 0;\n    }\n\n    &:last-child {\n        padding-right: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBlock": "app__PaQuoteInfo__infoBlock"
};
export default ___CSS_LOADER_EXPORT___;
