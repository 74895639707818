import React, {
    useState, useEffect, useContext, useCallback
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { GuidanceService } from 'gw-capability-guidance';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuickQuoteWizardPageTemplate } from 'gw-capability-quoteandbind-common-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import GuidanceToProductPageTemplate from '../../templates/GuidanceToProductPageTemplate';
import metadata from './YourBusinessPage.metadata.json5';

import './YourBusinessPage.messages';
import messages from '../../GTPWizard.messages';

function formatOccupationCodes(occupationCodes) {
    return occupationCodes.map((occupation) => ({
        ...occupation,
        name: occupation.classification
    }));
}

function YourBusinessPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const { onValidate, isComponentValid } = useValidation('YourBusinessPage');
    const [occupationCodes, updateOccupationCodes] = useState([]);
    const { wizardData: submissionVM, updateWizardData } = props;

    useEffect(() => {
        const fetchOccupations = async () => {
            const occupations = await GuidanceService.getOccupations();
            updateOccupationCodes(formatOccupationCodes(occupations));
        };
        fetchOccupations();
    }, []);

    const getIndustryCodeByCode = useCallback(
        (code) => {
            return occupationCodes.find((item) => item.code === code);
        },
        [occupationCodes]
    );

    const onChangeIndustryCode = useCallback(
        (value, path) => {
            const selectedOccupationCode = value ? getIndustryCodeByCode(value.code) : '';
            _.set(submissionVM, `${path}.value`, selectedOccupationCode);
            updateWizardData(submissionVM);
        },
        [getIndustryCodeByCode, submissionVM, updateWizardData]
    );

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true
        },
        yourBusiness: {
            availableValues: occupationCodes,
            onValueChange: onChangeIndustryCode
        }
    };

    return (
        <WizardPage
            showPrevious={false}
            disableNext={!isComponentValid}
            nextLabel={messages.continue}
            template={
                breakpoint === 'phoneWide' || breakpoint === 'phone'
                    ? QuickQuoteWizardPageTemplate
                    : GuidanceToProductPageTemplate
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

YourBusinessPage.propTypes = wizardProps;
export default YourBusinessPage;
