import React, { useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { MockUpUtil } from 'wmic-portals-utils-js';

import metadata from './AdditionalInformationPage.metadata.json5';
import './AdditionalInformationPage.messages';

const PATH_TO_MOCK_DATA = 'quote.pa';
const MOCK_DATA_TO_SET = ['baseData.accountHolder.emailAddress1'];
const MOCK_DATA_TO_REMOVE = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];

function AdditionalInformationPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate } = useValidation('AdditionalInformationPage');

    useEffect(() => {
        let vm = viewModelService.clone(submissionVM);

        vm.value = MockUpUtil.cleanUpMockedProperties(
            submissionVM.value,
            PATH_TO_MOCK_DATA,
            ...MOCK_DATA_TO_REMOVE
        );

        vm.value = MockUpUtil.setMockData(
            submissionVM.value,
            PATH_TO_MOCK_DATA,
            ...MOCK_DATA_TO_SET
        );

        if (!_.get(vm, '_context.Binding', false)) {
            vm = viewModelService.changeContext(vm, {
                Binding: true
            });
        }

        if (!_.isEqual(vm.value, submissionVM.value)) {
            updateWizardData(vm);
        }
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        submissionVM.value = await LoadSaveService.updateQuotedSubmissionWithLOBData(
            submissionVM.value,
            authHeader
        );
        return submissionVM;
    }, [LoadSaveService, authHeader, submissionVM]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        }
    };

    return (
        <WizardPage onNext={onNext} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

AdditionalInformationPage.propTypes = wizardProps;
export default AdditionalInformationPage;
