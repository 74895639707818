import { defineMessages } from 'react-intl';

export default defineMessages({
    frequentlyAskedQuestions: {
        id: 'portal.index.Frequently Asked Questions',
        defaultMessage: 'Frequently Asked Questions',
    },
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ',
    },
    checkAnswers: {
        id: 'portal.index.Check Answers',
        defaultMessage: 'Check Answers',
    },
    furtherQuestions: {
        id: 'portal.index.Further Questions?',
        defaultMessage: 'Further Questions?',
    },
    home: {
        id: 'portal.index.Home',
        defaultMessage: 'Home'
    },
    errorModalHeader: {
        id: 'agent.views.modal.Error',
        defaultMessage: 'Error'
    }
});
