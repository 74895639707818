import { defineMessages } from 'react-intl';

export default defineMessages({
    mainDriverInformation: {
        id: 'quoteandbind.views.contact-details.Main Driver Information',
        defaultMessage: 'Main Driver Information',
    },
    paDateOfBirth: {
        id: 'quoteandbind.views.contact-details.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    paFirstNameTooltip: {
        id: 'platform.inputs.contact-details.First Name.tooltip.content',
        defaultMessage: 'Here you can add helpful text related to the field. You can use links too, for example:'
    },
    paLastNameTooltip: {
        id: 'platform.inputs.contact-details.Last Name.tooltip.content',
        defaultMessage: 'Here you can add helpful text related to the field. You can use links too, for example:'
    },
    paTooltipLink: {
        id: 'platform.inputs.contact-details.tooltip.Link',
        defaultMessage: 'Link'
    },
    paPleaseSelect: {
        id: 'quoteandbind.pa.views.common.Please Select',
        defaultMessage: 'Please Select',
    },
});
