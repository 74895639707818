import { defineMessages } from 'react-intl';

export default defineMessages({
    hoYourHome: {
        id: 'quoteandbind.ho.views.ho-your-home.Your Home',
        defaultMessage: 'Your Home',
    },
    replacementCost: {
        id: 'quoteandbind.ho.views.ho-your-home.Estimated value of Your Home',
        defaultMessage: 'Estimated Value of Your Home',
    },
    locationType: {
        id: 'quoteandbind.ho.views.ho-your-home.Location Type',
        defaultMessage: 'Location Type',
    },
    distanceToFireHydrant: {
        id: 'quoteandbind.ho.views.ho-your-home.Distance to Fire Hydrant',
        defaultMessage: 'Distance to Fire Hydrant',
    },
    within500ft: {
        id: 'quoteandbind.ho.views.ho-your-home.Within 500ft',
        defaultMessage: 'Within 500ft',
    },
    within300ftOfCommercialProperty: {
        id: 'quoteandbind.ho.views.ho-your-home.Within 300ft of commercial property',
        defaultMessage: 'Within 300ft of commercial property',
    },
    floodingOrFireHazard: {
        id: 'quoteandbind.ho.views.ho-your-home.Flooding or Fire Hazard',
        defaultMessage: 'Flooding or Fire Hazard',
    },
    residenceType: {
        id: 'quoteandbind.ho.views.ho-your-home.Residence Type',
        defaultMessage: 'Residence Type',
    },
    formType: {
        id: 'quoteandbind.ho.views.ho-your-home.Form Type',
        defaultMessage: 'Form Type',
    },
    whoOccupiesYourHome: {
        id: 'quoteandbind.ho.views.ho-your-home.Your home is',
        defaultMessage: 'Specify who occupies the home',
    },
    homeType: {
        id: 'quoteandbind.ho.views.ho-your-home.You use your home as a',
        defaultMessage: 'Home Type',
    },
    over500ft: {
        id: 'quoteandbind.ho.views.ho-your-home.Over 500ft',
        defaultMessage: 'Over 500ft',
    },
    distanceToFireStation: {
        id: 'quoteandbind.ho.views.ho-your-home.Distance to Fire Station',
        defaultMessage: 'Distance to Fire Station'
    },
    within5Miles: {
        id: 'quoteandbind.ho.views.ho-your-home.Within 5 Miles',
        defaultMessage: 'Within 5 Miles'
    },
    over5Miles: {
        id: 'quoteandbind.ho.views.ho-your-home.Over 5 Miles',
        defaultMessage: 'Over 5 Miles',
    },
    additionalInterestForThisHome: {
        id: 'quoteandbind.ho.views.ho-your-home.Additional Interest for This Home',
        defaultMessage: 'Additional Interest for This Home'
    },
    anotherAdditionalInterest: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Add Another Additional Interest?',
        defaultMessage: 'Add Another Additional Interest?'
    },
    additionalModalYes: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Yes',
        defaultMessage: 'Yes'
    },
    additionalModalNo: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.No',
        defaultMessage: 'No'
    },
    additionalInterestCount: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Additional Interest:',
        defaultMessage: 'Additional Interest: { count }'
    },
    provideDetails: {
        id: 'quoteandbind.ho.views.ho-your-home.Please provide details',
        defaultMessage: 'Please provide details'
    },
    loadingQuote: {
        id: 'quoteandbind.ho.views.ho-your-home.Loading your quote...',
        defaultMessage: 'Loading your quote...'
    },
    formTypeTooltip: {
        id: 'quoteandbind.ho.views.ho-your-home.Broad Form policies',
        defaultMessage: "Broad Form policies insure against the \"named perils\" only, which are specifically listed in the policy contract. Some perils, or causes of loss, generally covered on a Broad Form policy are: Fire or Lightning; Windstorm or Hail; Aircraft; Vandalism; Theft; Falling Objects; Weight of Ice, Snow, or Sleet; and Freezing. Special Form policies insure against \"direct physical loss to property\" except for some excluded causes, which are specifically listed in the policy contract. Some perils generally excluded on a Special Form policy are: wear and tear; rust or dry rot; discharge or escape of pollutants; and nesting or infestation. Some common perils which could be covered on a Special Form policy, but could not be on a Broad Form policy include damage to your own property which you accidentally cause, or coverage for some property which has been lost or misplaced."
    }
});
