import React from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICRegionUrlUtil } from 'wmic-portals-url-js';
import { useTranslator } from '@jutro/locale';
import { CONSTANTS } from 'wmic-portals-utils-js';
import messages from './PrivacyInfoComponent.messages';
import metadata from './PrivacyInfoComponent.metadata.json5';

function PrivacyInfoComponent() {
    const translator = useTranslator();

    const getCAPrivacyInfo = () => {
        return (
            <span>
                {
                    translator(messages.privacyInfoDescription)
                }
                <a href={CONSTANTS.CA_PRIVACY_LINK}>
                    {
                        translator(messages.privacyPolicyName)
                    }
                </a>

            </span>
        );
    };

    const isPrivacyVisible = () => {
        return WMICRegionUrlUtil.getRegion() === CONSTANTS.JURISDICTIONS.CA;
    };

    const overrides = {
        privacyDisclaimerInfo: {
            visible: isPrivacyVisible(),
            content: getCAPrivacyInfo()
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrides}
        />
    );
}

export default PrivacyInfoComponent;
