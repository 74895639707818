import { defineMessages } from 'react-intl';

export default defineMessages({
    yourVehicle: {
        id: 'quoteandbind.quickquote.pa.directives.Vehicle.Hi, tell us about your vehicle',
        defaultMessage: 'Hi, tell us about your vehicle',
    },
    makeModelYear: {
        id: 'quoteandbind.quickquote.pa.directives.Vehicle.Make, Model, Year',
        defaultMessage: 'Make, Model, Year',
    },
    orEnterVIN: {
        id: 'quoteandbind.quickquote.pa.directives.Vehicle.or Enter VIN',
        defaultMessage: 'or Enter VIN',
    },
    make: {
        id: 'quoteandbind.quickquote.pa.directives.Vehicle.Make',
        defaultMessage: 'Make',
    },
    model: {
        id: 'quoteandbind.quickquote.pa.directives.Vehicle.Model',
        defaultMessage: 'Model',
    },
    year: {
        id: 'quoteandbind.quickquote.pa.directives.Vehicle.Year',
        defaultMessage: 'Year',
    },
    enterVin: {
        id: 'quoteandbind.quickquote.pa.directives.Vehicle.Enter vin Number',
        defaultMessage: 'Enter VIN (Vehicle ID number)'
    }
});
