import { defineMessages } from 'react-intl';

export default defineMessages({
    confirmationOfPurchase: {
        id: 'quoteandbind.common.views.policyInProcess.Confirmation of Purchase',
        defaultMessage: 'Confirmation of Purchase'
    },
    thankYou: {
        id: 'quoteandbind.common.views.policyInProcess.Thank you for purchasing. Selected Method',
        defaultMessage: 'Thank you for purchasing your renters insurance policy with Wawanesa Insurance. You will receive your confirmation documents shortly.'
    },
    contactUs: {
        id: 'quoteandbind.common.views.policyInProcess.contact-us',
        defaultMessage: 'Please contact us at {phoneNumber} for any questions about your new policy.'
    },
    quoteNumber: {
        id: 'quoteandbind.common.views.policyInProcess.Your Quote Number is {quoteNumber}',
        defaultMessage: 'Your Quote Number is {quoteNumber}.'
    },
    callCenterHours: {
        id: 'quoteandbind.views.policyInProcess.Call Center Business Hours',
        defaultMessage: 'Call Center Business Hours',
    },
    weekdayHours: {
        id: 'quoteandbind.views.policyInProcess.Monday to Friday 8:00 AM - 3:00 PM',
        defaultMessage: 'Monday to Friday: 8:00 AM - 3:00 PM',
    }
});