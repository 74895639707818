import { defineMessages } from 'react-intl';

export default defineMessages({
    greatNowTellUsYourLocation: {
        id: 'quoteandbind.quickquote.pa.directives.Address.Great, now tell us your location',
        defaultMessage: 'Great, now tell us your location'
    },
    addressSearchPlaceholder: {
        id: 'quoteandbind.quickquote.pa.directives.Address.Search',
        defaultMessage: 'Search'
    },
    street: {
        id: 'quoteandbind.quickquote.pa.directives.Address.Street',
        defaultMessage: 'Street'
    },
    addressNotInList: {
        id: 'quoteandbind.quickquote.pa.directives.Address.My address is not in this list',
        defaultMessage: 'My address is not in this list',
    },
    informationTextDesktop: {
        id: 'quoteandbind.quickquote.pa.directives.Address.Having your correct address will allow us to make a more accurate estimate, as where you park your car affects your rate.',
        defaultMessage: 'Having your correct address will allow us to make a more accurate estimate, as where you park your car affects your rate.',
    },
    city: {
        id: 'quoteandbind.quickquote.pa.directives.Address.City',
        defaultMessage: 'City'
    },
    state: {
        id: 'quoteandbind.quickquote.pa.directives.Address.State',
        defaultMessage: 'State'
    },
    zipCode: {
        id: 'quoteandbind.quickquote.pa.directives.Address.ZIP Code',
        defaultMessage: 'ZIP Code'
    },
    or: {
        id: 'quoteandbind.quickquote.pa.directives.Address.or',
        defaultMessage: '-- or --'
    },
    backToAddressSearch: {
        id: 'quoteandbind.quickquote.pa.directives.Address.Back to Address Search',
        defaultMessage: 'Back to Address Search'
    },
    informationTextMobile: {
        id: 'quoteandbind.quickquote.pa.directives.Address.Are you at home? Quickly get your address by using your phones current location.',
        defaultMessage: 'Are you at home? Quickly get your address by using your phones current location.'
    },
    useMyCurrentLocationButtonText: {
        id: 'quoteandbind.quickquote.pa.directives.Address.Use my current location',
        defaultMessage: 'Use my current location'
    },
    wrongLocation: {
        id: 'quoteandbind.quickquote.pa.directives.Address.This is not the right address?',
        defaultMessage: 'This is not the right address?'
    },
    tryAgain: {
        id: 'quoteandbind.quickquote.pa.directives.Address.Try again to get your phone location',
        defaultMessage: 'Try again to get your phone location'
    },
    addressNotFound: {
        id: 'quoteandbind.quickquote.pa.directives.Address.We were unable to find your address. Please enter your address manually.',
        defaultMessage: 'We were unable to find your address. Please enter your address manually.'
    },
    somethingWentWrong: {
        id: 'quoteandbind.quickquote.pa.directives.Address.Something went wrong. Please enter your address manually.',
        defaultMessage: 'Something went wrong. Please enter your address manually.'
    },
    error: {
        id: 'quoteandbind.quickquote.pa.directives.Address.Error',
        defaultMessage: 'Error'
    }
});
