/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import appConfig from 'app-config';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { WMICCheckbox } from 'wmic-components-platform-react';
import { WMICCustomTooltip, WMICCustomDropdownSelect } from 'gw-capability-quoteandbind-common-react';
import { WMICVariousUtil, CONSTANTS } from 'wmic-portals-utils-js';
import { useTranslator } from '@jutro/locale';

import cx from 'classnames';

import metadata from './WMICAdditionalCoverageOR.metadata.json5';
import styles from './WMICAdditionalCoverageOR.module.scss';
import messages from './WMICAdditionalCoverageOR.messages';


function getTooltipInfo(coverage, translator) {
    let overrideText;
    let mobileTooltipOnTerm0 = false;
    let isTooltipVisible = true;
    switch (coverage.publicID) {
        case CONSTANTS.COVERAGE_NAMES.HO_SCHEDULEDPERSONALPROPERTY:
            overrideText = translator({
                id: 'quoteandbind.ho.directives.policy-info.help-text.Credit Card',
                defaultMessage: 'This provides coverage for losses created by your legal obligation to pay expenses due to theft or unauthorized use of your credit cards.'
            });
            break;
        case CONSTANTS.COVERAGE_NAMES.HO_CREDITCARD:
            overrideText = translator({
                id: 'quoteandbind.ho.directives.policy-info.help-text.Credit Card',
                defaultMessage: 'This provides coverage for losses created by your legal obligation to pay expenses due to theft or unauthorized use of your credit cards.'
            });
            mobileTooltipOnTerm0 = true;
            break;
        case CONSTANTS.COVERAGE_NAMES.HO_EARTHQUAKE:
            overrideText = translator({
                id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Earthquake.Limit',
                defaultMessage: 'This endorsement provides coverage for losses resulting from earthquake, which is excluded under the base policy. Coverage must be purchased to equal the amount of contents coverage purchased and is subject to a separate deductible.'
            });
            break;
        case CONSTANTS.COVERAGE_NAMES.HO_HOMESYSTEMSPROTECTION:
            overrideText = translator({
                id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Home Systems Protection.Limit',
                defaultMessage: 'Provides coverage for sudden and accidental breakdown that causes direct physical damage to personal property, including but not limited to: alarm systems, appliances, computers, entertainment systems, electronics, exercise equipment, power equipment. The limit for Home Systems Protection coverage is $50,000 with a $500 deductible.'
            });
            mobileTooltipOnTerm0 = true;
            break;
        case CONSTANTS.COVERAGE_NAMES.HO_IDENTITYFRAUDEXPENSE:
            overrideText = translator({
                id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Identity Fraud Expense.Limit',
                defaultMessage: 'Covers up to $25,000 for expenses incurred as the direct result of any one identity fraud during the policy period.  A $500 deductible applies.'
            });
            mobileTooltipOnTerm0 = true;
            break;
        case CONSTANTS.COVERAGE_NAMES.HO_INCRLMTBUSPROPERTY:
            overrideText = translator({
                id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Increased Limits on Business Property.Limit',
                defaultMessage: 'This increases property coverage used primarily for business purposes.'
            });
            mobileTooltipOnTerm0 = true;
            break;
        case CONSTANTS.COVERAGE_NAMES.HO_PERSONALPROPERTYREPLACEMENTCOSTSETTLEMENT:
            overrideText = translator({
                id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Personal Property Replacement Cost Loss Settlement.Limit',
                defaultMessage: 'Under the base policy,  in the event of a claim, the loss settlement applicable to Contents is Actual Cash Value. This means that depreciation will be applied, and considers factors such as age, condition, wear and tear etc.  When the Personal Property Replacement Cost Loss Settlement is added, the loss settlement is changed to Replacement Cost, meaning the cost to repair or replace without deduction for depreciation (except for property listed as not eligible).'
            });
            break;
        case CONSTANTS.COVERAGE_NAMES.HO_SPECIALPERSONALPROPERTY:
            overrideText = "";
            isTooltipVisible = false;
            break;
        default:
    }
    return {
        overrideText: overrideText,
        mobileTooltipOnTerm0: mobileTooltipOnTerm0,
        isTooltipVisible: isTooltipVisible
    };
}
function WMICAdditionalCoverageOR(props) {
    const {
        submissionVM,
        updateCoverage
    } = props;

    const translator = useTranslator();
    const { phoneNumber } = appConfig;

    const generateAdditionalCoverageOptions = useCallback(() => {
        const { additionalCoverages } = submissionVM
            .lobData.homeowners.offerings.value[0].coverages;

        const overrides = additionalCoverages.map(
            (coverage, index) => {
                const term0 = _.get(submissionVM, `lobData.homeowners.offerings.value[0].coverages.additionalCoverages[${index}].terms[0]`);
                const term1 = _.get(submissionVM, `lobData.homeowners.offerings.value[0].coverages.additionalCoverages[${index}].terms[1]`);

                const term0Exists = !_.isEmpty(term0);
                const term1Exists = !_.isEmpty(term1);

                const hasBeenUpdated = _.get(submissionVM, `lobData.homeowners.offerings.value[0].coverages.additionalCoverages[${index}].updated`);

                if (!hasBeenUpdated && coverage.name === CONSTANTS.COVERAGE_NAMES.PERSONAL_PROPERTY_REPLACEMENT) {
                    _.set(submissionVM, `lobData.homeowners.offerings.value[0].coverages.additionalCoverages[${index}].selected`, true);
                }

                const isSelected = _.get(submissionVM, `lobData.homeowners.offerings.value[0].coverages.additionalCoverages[${index}].selected`);
                
                const isTerm0Disabled = _.get(coverage, 'terms[0].options.length') < 2;
                const isTerm1Disabled = _.get(coverage, 'terms[1].options.length') < 2;

                const tooltipInfo = getTooltipInfo(coverage, translator);

                return {
                    [`addlCoverageName${index}`]: {
                        label: coverage.name,
                        path: `lobData.homeowners.offerings.value[0].coverages.additionalCoverages[${index}].selected`,
                        onValueChange: updateCoverage,
                        className: styles.addCovOr,
                        labelClassName: styles.wmicAddlCheckboxLabel,
                        value: isSelected,
                        hideCheckbox: coverage.name === CONSTANTS.COVERAGE_NAMES.SPECIAL_PERSONAL_PROPERTY
                    },
                    [`iterableDropdownsContainer${index}`]: {
                        visible: isSelected && (term0Exists || term1Exists),
                    },
                    [`term0Dropdown${index}`]: {
                        availableValues: _.get(coverage, 'terms[0].options'),
                        disabled: isTerm0Disabled,
                        visible: isSelected && term0Exists,
                        value: submissionVM?.lobData.homeowners.offerings.value[0].coverages.additionalCoverages[index].terms[0]?.chosenTerm,
                        onValueChange: (value) => updateCoverage(value, `lobData.homeowners.offerings.value[0].coverages.additionalCoverages[${index}].terms[0].chosenTerm`),
                        label: _.get(term0, 'name'),
                        className: styles.wmicDropDown,
                        labelClassName: styles.wmicAddlCovDropdownLabel
                    },
                    [`term0DropdownTooltip${index}`]: {
                        showCustomTooltip: isSelected && term0Exists && tooltipInfo.mobileTooltipOnTerm0,
                        tooltipTextOverride: tooltipInfo.overrideText,
                        showOnlyOnMobile: true
                    },
                    [`term1Dropdown${index}`]: {
                        availableValues: _.get(coverage, 'terms[1].options'),
                        disabled: isTerm1Disabled,
                        visible: isSelected && term1Exists,
                        value: submissionVM?.lobData.homeowners.offerings.value[0].coverages.additionalCoverages[index].terms[1]?.chosenTerm,
                        onValueChange: (value) => updateCoverage(value, `lobData.homeowners.offerings.value[0].coverages.additionalCoverages[${index}].terms[1].chosenTerm`),
                        label: _.get(term1, 'name'),
                        className: cx(styles.wmicDropDown, styles.wmicSecondDropdown),
                        labelClassName: styles.wmicAddlCovDropdownLabel
                    },
                    [`coverageCheckboxTooltip${index}`]: {
                        tooltipTextOverride: tooltipInfo.overrideText,
                        showOnMobile: false,
                        showCustomTooltip: tooltipInfo.isTooltipVisible
                    }
                };
            }
        );

        return Object.assign({}, ...overrides);
    }, [submissionVM, updateCoverage]);

    const resolvers = {
        resolveComponentMap: {
            wmicCheckbox: WMICCheckbox,
            wmicCustomTooltip: WMICCustomTooltip,
            wmicCustomDropdownSelect: WMICCustomDropdownSelect
        },
        resolveClassNameMap: styles
    };

    const overrideProps = {
        noteContainer: {
            content: WMICVariousUtil.getTextWithIcon(cx('fa fa-question-circle', styles.wmicIcon), translator(messages.toAddCoverage, {phoneNumber}), styles.wmicNoteText)
        },
        ...generateAdditionalCoverageOptions()
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICAdditionalCoverageOR.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    updateCoverage: PropTypes.func.isRequired
};

export default withViewModelService(WMICAdditionalCoverageOR);