import React, { useCallback, useState, useEffect } from 'react';
import stereotype from 'stereotype';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuickQuoteWizardPageTemplate } from 'gw-capability-quoteandbind-common-react';
import { VehicleInfoLookupService } from 'gw-capability-vehicleinfo';
import './YourVehicle.messages';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import styles from './YourVehicle.module.scss';
import metadata from './YourVehiclePage.metadata.json5';
// eslint-disable-next-line import/order
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';

function YourVehiclePage(props) {
    const { wizardData, updateWizardData } = props;
    const { submissionVM } = wizardData;
    const [manualEntryMode, updateEntryMode] = useState(true);
    const {
        isComponentValid,
        disregardFieldValidation,
        registerComponentValidation
    } = useValidation(
        'YourVehiclePage'
    );

    /* Holds the list of available values for makes, models, years */
    const [makes, updatePossibleMakes] = useState([]);
    const [models, updatePossibleModels] = useState([]);
    const [years, updatePossibleYears] = useState([]);

    const fetchModelsFn = useCallback(() => {
        const fetchModels = async () => {
            if (!_.isUndefined(submissionVM.lobCoverables.personalAuto.make.value)) {
                const vehicle = _.get(submissionVM, 'lobCoverables.personalAuto');
                const dtoObj = { make: vehicle.make.value };
                const result = await VehicleInfoLookupService.autofillBasedOnPartialDto(dtoObj);
                const vehicleModels = result.models.map((model) => ({ code: model, name: model }));
                updatePossibleModels(vehicleModels);
            }
        };
        if (submissionVM.lobCoverables.personalAuto.make.value) {
            fetchModels();
        }
    }, [submissionVM]);

    const fetchYearsFn = useCallback(() => {
        const fetchYears = async () => {
            if (
                !_.isUndefined(submissionVM.lobCoverables.personalAuto.make.value)
                && !_.isUndefined(submissionVM.lobCoverables.personalAuto.model.value)
            ) {
                const vehicle = submissionVM.lobCoverables.personalAuto;
                const dtoObj = { make: vehicle.make.value, model: vehicle.model.value };
                const result = await VehicleInfoLookupService.autofillBasedOnPartialDto(dtoObj);
                const vehicleYears = result.years
                    .map(_.toString)
                    .map((year) => ({ code: year, name: year }));
                updatePossibleYears(vehicleYears);
            }
        };
        if (
            submissionVM.lobCoverables.personalAuto.make.value
            && submissionVM.lobCoverables.personalAuto.model.value
        ) {
            fetchYears();
        }
    }, [submissionVM]);

    const validateForm = useCallback(() => {
        return submissionVM.lobCoverables.personalAuto.aspects.valid
            && submissionVM.lobCoverables.personalAuto.aspects.subtreeValid;
    }, [submissionVM]);

    // effect hook to fetch the available makes on page load and re-render.
    useEffect(() => {
        const fetchMakes = async () => {
            const result = await VehicleInfoLookupService.lookupMakes();
            const vehicleMakes = result.makes.map((make) => ({ code: make, name: make }));
            updatePossibleMakes(vehicleMakes);
        };
        fetchMakes();
        fetchModelsFn();
        fetchYearsFn();
    }, [fetchModelsFn, fetchYearsFn]);

    useEffect(() => {
        registerComponentValidation(validateForm);
    }, [validateForm, registerComponentValidation]);

    const writeValue = useCallback(
        (value, path) => {
            if (path === 'lobCoverables.personalAuto.make') {
                updatePossibleModels([]);
                submissionVM.lobCoverables.personalAuto.model.value = undefined;
                updatePossibleYears([]);
                submissionVM.lobCoverables.personalAuto.year.value = undefined;
                fetchModelsFn();
            }
            if (path === 'lobCoverables.personalAuto.model') {
                updatePossibleYears([]);
                submissionVM.lobCoverables.personalAuto.year.value = undefined;
                fetchYearsFn();
            }
            if (manualEntryMode) {
                submissionVM.lobCoverables.personalAuto.vin.value = undefined;
            }
            const vmPath = path.replace(/\.value$/, '');
            const inputCtrlType = _.get(submissionVM, `${vmPath}.aspects.inputCtrlType`);
            const effectiveValue = inputCtrlType === 'number' ? stereotype(value) : value;
            _.set(wizardData.submissionVM, path, effectiveValue);
            updateWizardData(wizardData);
        },
        [manualEntryMode, submissionVM, wizardData, updateWizardData, fetchModelsFn, fetchYearsFn]
    );

    const handleEntryType = useCallback(
        (value) => {
            if (value) {
                // In case the current active section is the manually entry,
                // removing the field validation for the vin lookup field
                disregardFieldValidation('vinlookup');
            }
            updateEntryMode(value);
        },
        [disregardFieldValidation, updateEntryMode]
    );

    const overrideProps = {
        vehicleEntryType: {
            value: manualEntryMode,
            onValueChange: handleEntryType
        },
        manualEntryContainer: {
            visible: manualEntryMode
        },
        vehicleLookupContainer: {
            visible: !manualEntryMode
        },
        vinlookup: {
            showOptional: manualEntryMode
        },
        make: {
            availableValues: makes
        },
        model: {
            availableValues: models
        },
        year: {
            availableValues: years
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            updateEntryMode
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    return (
        <WizardPage
            showPrevious={false}
            disableNext={!isComponentValid}
            template={QuickQuoteWizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

YourVehiclePage.propTypes = wizardProps;
export default YourVehiclePage;
