// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__DiscountPage__accordionHeaderTitle{margin-left:var(--GW-SPACING-10);padding-top:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-ho-react/pages/Discount/DiscountPage.module.scss"],"names":[],"mappings":"AAAA,yCACE,gCAAA,CACA,+BAAA","sourcesContent":[".accordionHeaderTitle {\n  margin-left: var(--GW-SPACING-10);\n  padding-top: var(--GW-SPACING-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionHeaderTitle": "app__DiscountPage__accordionHeaderTitle"
};
export default ___CSS_LOADER_EXPORT___;
