import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as messages } from 'gw-capability-quoteandbind-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import vehicleMessages from '../../pages/Vehicles/VehiclesPage.messages';
import metadata from './AdditionalInterestComponent.metadata.json5';


function AdditionalInterestComponent(props) {
    const modalApi = useModal();
    const {
        id, value: vehicleVM, updateAdditionalInterests, onValidate
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const translator = useTranslator();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, vehicleVM, onValidate, isComponentValid]);

    const addedAdditionalInterestsTitle = useCallback((index) => {
        if (index === 0) {
            return translator(vehicleMessages.additionalInterestsForThisVehicle);
        }
        return translator(vehicleMessages.additionalInterestCount, {
            count: index + 1
        });
    }, [translator]);

    const generateOverrides = useCallback(() => {
        const overrides = vehicleVM.additionalInterests.children.map((additionalInterest, i) => {
            const subtype = _.get(additionalInterest, 'policyAdditionalInterest.subtype.value');
            return {
                [`companyContainer${i}`]: {
                    visible: subtype === 'Company'
                },
                [`personalContainer${i}`]: {
                    visible: subtype === 'Person'
                },
                [`paVehicleAdditionalInterest${i}`]: {
                    content: addedAdditionalInterestsTitle(i)
                },
                [`additionalInterestState${i}`]: {
                    visible: !_.isEmpty(
                        _.get(
                            additionalInterest,
                            'policyAdditionalInterest.primaryAddress.state.aspects.availableValues'
                        )
                    )
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [vehicleVM, addedAdditionalInterestsTitle]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        ...generateOverrides()
    };

    const removeAdditionalInterest = useCallback(
        (evt) => {
            const additionInterestPath = evt.path;
            // removes `.children[index].value` and replace with `.value`
            // so we can get an array of Additional Interests
            const additionalInterestListPath = additionInterestPath.replace(
                /\.children\[\d+\]$/,
                '.value'
            );
            const additionalInterestToRemove = _.get(vehicleVM, additionInterestPath);
            const additionalInterestList = _.get(vehicleVM, additionalInterestListPath);

            modalApi.showConfirm({
                title: messages.removeAdditionalInterest,
                message: messages.removeAdditionalInterestDesc,
                status: 'warning',
                icon: 'mi-error-outline'
            }).then(
                (results) => {
                    if (results === 'cancel') {
                        return _.noop();
                    }
                    const newAdditionalInterestList = additionalInterestList.filter(
                        (additionalInterest, index) => {
                            // eslint-disable-next-line
                            const additionalInterestListPathWithoutValue = additionalInterestListPath.replace(
                                '.value',
                                ''
                            );
                            const currentAdditionalInterestPath = `${additionalInterestListPathWithoutValue}.children[${index}]`;
                            // eslint-disable-next-line
                            const isCorrectPath = currentAdditionalInterestPath === additionInterestPath;
                            return !(
                                isCorrectPath
                                && _.isEqual(additionalInterest, additionalInterestToRemove.value)
                            );
                        }
                    );

                    _.set(vehicleVM, additionalInterestListPath, newAdditionalInterestList);

                    updateAdditionalInterests();
                    return true;
                }, _.noop
            );
        },
        [vehicleVM, updateAdditionalInterests]
    );

    const resolvers = {
        resolveCallbackMap: {
            onRemoveAdditionalInterest: removeAdditionalInterest,
            onValidate: onValidate
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onModelChange={updateAdditionalInterests}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
        />
    );
}

AdditionalInterestComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    updateAdditionalInterests: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
};

export default AdditionalInterestComponent;
