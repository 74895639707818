import { defineMessages } from 'react-intl';

export default defineMessages({
    cannotProceed: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Cannot Proceed',
        defaultMessage: 'Cannot Proceed'
    },
    cannotProceedMsg: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Cannot Proceed with quote',
        defaultMessage: 'Cannot Proceed with quote'
    },
    contactUs: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Contact Us',
        defaultMessage: 'Contact Us'
    },
    paymentDetailsFailure: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Payment Details Failure',
        defaultMessage: 'Payment Details Failure'
    },
    paymentUnsuccessful: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Payment unsuccessful',
        defaultMessage: 'Payment unsuccessful'
    },
    paymentUnsuccessfulMsg: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.The payment system is currently unavailable. Please choose another payment method, or try again later.',
        defaultMessage: 'The payment system is currently unavailable. Please choose another payment method, or try again later.'
    },
    authorizationFailure: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Authorization Failure',
        defaultMessage: 'Authorization Failure'
    },
    weHaveUpdated: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.We have updated your policy effective date',
        defaultMessage: 'We have updated your policy effective date'
    },
    confirmationOfPurchase: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Confirmation of Purchase',
        defaultMessage: 'Confirmation of Purchase'
    },
    paymentSystemUnavailable: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Payment system unavailable',
        defaultMessage: 'Payment system unavailable'
    },
    paymentSystemUnavailableMsg: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.The payment system is currently unavailable.  Please try again later or call us for assistance...',
        defaultMessage: 'The payment system is currently unavailable.  Please try again later or call us for assistance...'
    }
});
