/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    WMICCustomInput,
    WMICCustomDropdownSelect,
    WMICCustomRadioButton,
    WMICAnimalTable,
    WMICCustomTooltip,
    WMICScrollToError,
    WMICProgressModal
} from 'gw-capability-quoteandbind-common-react';
import { WMICCheckbox, WMICButton } from 'wmic-components-platform-react';
import { CONSTANTS, DwellingUsageType } from 'wmic-portals-utils-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';

import metadata from './YourHomePage.metadata.json5';
import messages from './YourHomePage.messages';


const DEFAULT_FAR_FIRE_STATION_DISTANCE = 10;
const DEFAULT_CLOSE_FIRE_STATION_DISTANCE = 3;

const DEFAULT_FAR_FIRE_HYDRANT_VALUE = 'greater1000';
const DEFAULT_CLOSE_FIRE_HYDRANT_VALUE = 'lessequal1000';

const yourHomePath = 'lobData.homeowners.coverables.yourHome';

function YourHomePage(props) {
    const translator = useTranslator();
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPageInitialized, setPageInitialized] = useState(false);
    const { initialValidation, onValidate } = useValidation('YourHomePage');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { defaultValuesEnabled = false } = appConfig;
    const [timestamp] = useState(Date.now());
    const [showErrors, setShowErrors] = useState(false);
    const [displaySelectAtLeastOneMessage, setDisplaySelectAtLeastOneMessage] = useState(false);

    const [distanceToHydrantOption, setDistanceToHydrantOption] = useState(false);
    const [distanceToFireStation, setDistanceToFireStation] = useState(false);
    const [noneOfTheAbove, setNoneOfTheAbove] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const isOR = (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR);
    const isCA = (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.CA);
    const isSingleFamily = _.get(submissionVM, `${yourHomePath}.value.residenceType`) === CONSTANTS.RESIDENCE_TYPES.SINGLE_FAMILY;
    const hasDogs = _.get(submissionVM, 'lobData.homeowners.coverables.yourHome.animalsInDwelling.value') === true;

    const getHomeFeaturesAndUseFields = useCallback(() => {
        const homeFeaturesAndUseFields = ['physicalCondition_wmic', 'hasDwellingEmployees_wmic', 'isBusinessConducted'];
        if (isSingleFamily) {
            homeFeaturesAndUseFields.push('fireplaceOrWoodStoveExists');
            homeFeaturesAndUseFields.push('trampolineExists');
            homeFeaturesAndUseFields.push('securityBars_wmic');
        }
        if (isCA) {
            homeFeaturesAndUseFields.push('distanceToFireStation');
            homeFeaturesAndUseFields.push('distanceToHydrantOption');
            homeFeaturesAndUseFields.push('nearBrushArea');
        }
        if (_.get(submissionVM, `${yourHomePath}.isBusinessConducted.value`)) {
            homeFeaturesAndUseFields.push('businessAssociatesVisit');
            homeFeaturesAndUseFields.push('hazardousMaterialsStored');
            homeFeaturesAndUseFields.push('spouseAndIHaveFinancialStake');
        }
        return homeFeaturesAndUseFields;
    }, [isCA, isSingleFamily, submissionVM]);

    const onDogsTableDataChange = (data) => {
        _.set(submissionVM.value, 'lobData.homeowners.coverables.yourHome.dwellingAnimals', data);
        updateWizardData(submissionVM);
    };

    const initNoneOfTheAbove = () => {
        let result = true;
        let unansweredCount = 0;
        const homeFeaturesAndUseFields = getHomeFeaturesAndUseFields();
        const yourHome = _.get(submissionVM, yourHomePath);

        for (let i = 0; i < homeFeaturesAndUseFields.length; i++) {
            const fieldName = homeFeaturesAndUseFields[i];
            const field = yourHome.value[fieldName];
            if (field === undefined) {
                unansweredCount++;
            } else if (field === true || field === DEFAULT_FAR_FIRE_STATION_DISTANCE || field === DEFAULT_FAR_FIRE_HYDRANT_VALUE) {
                result = false;
            }
        }
        if (unansweredCount === homeFeaturesAndUseFields.length) {
            result = false;
        }
        return result;
    };


    useEffect(() => {
        if (_.isEmpty(submissionVM.lobData.homeowners.coverables.yourHome.value)) {
            submissionVM.lobData.homeowners.coverables.yourHome = {};
            updateWizardData(submissionVM);
        }
        if (_.isNil(submissionVM.lobData.homeowners.coverables.yourHome.dwellingAnimals.value)) {
            _.set(submissionVM.value, `${yourHomePath}.dwellingAnimals`, []);
        }
        _.set(submissionVM.value, `${yourHomePath}.dwellingUsage`, DwellingUsageType.primary);
        if (_.get(submissionVM.value, `${yourHomePath}.distanceToFireStation`) === DEFAULT_FAR_FIRE_STATION_DISTANCE) {
            setDistanceToFireStation(true);
        }
        if (_.get(submissionVM.value, `${yourHomePath}.distanceToHydrantOption`) === DEFAULT_FAR_FIRE_HYDRANT_VALUE) {
            setDistanceToHydrantOption(true);
        }
        setNoneOfTheAbove(initNoneOfTheAbove());
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setDefaultValues = () => {
        const yourHomeVM = submissionVM.lobData.homeowners.coverables.yourHome;
        if (submissionVM.region_WMIC.value.code === CONSTANTS.JURISDICTIONS.CA) {
            yourHomeVM.distanceToHydrantOption = DEFAULT_CLOSE_FIRE_HYDRANT_VALUE;
            yourHomeVM.distanceToFireStation = '3';
            yourHomeVM.nearBrushArea = false;
        } else if (submissionVM.region_WMIC.value.code === CONSTANTS.JURISDICTIONS.OR) {
            yourHomeVM.formType = 'broad';
        }

        yourHomeVM.value.dwellingType = 'HO4';
        yourHomeVM.value.residenceType = 'Apt';
        yourHomeVM.roomerBoardersNumber = 1;
        yourHomeVM.hasDwellingEmployees_wmic = false;
        yourHomeVM.isBusinessConducted = false;
        yourHomeVM.physicalCondition_wmic = false;
        yourHomeVM.fireplaceOrWoodStoveExists = false;
        yourHomeVM.trampolineExists = false;
        yourHomeVM.securityBars_wmic = false;
        yourHomeVM.animalsInDwelling = true;
        yourHomeVM.dwellingAnimals.value = [
            {
                animalType: 'Dog',
                animalBreed: 'Other',
                describeOther: 'Greyhound',
                animalBiteHistory: false
            }
        ];
        setNoneOfTheAbove(true);
        yourHomeVM.hasRecentClaimsOrLosses = false;
        yourHomeVM.deniedPolicy = false;

        updateWizardData(submissionVM);
    };


    const noneOfTheAboveChanged = useCallback((value) => {
        setNoneOfTheAbove(value);
        const yourHome = _.get(submissionVM, yourHomePath);
        getHomeFeaturesAndUseFields().forEach((fieldName) => {
            if (fieldName === 'distanceToFireStation') {
                setDistanceToFireStation(false);
                _.set(submissionVM.value, `${yourHomePath}.distanceToFireStation`, DEFAULT_CLOSE_FIRE_STATION_DISTANCE);
            } else if (fieldName === 'distanceToHydrantOption') {
                setDistanceToHydrantOption(false);
                _.set(submissionVM.value, `${yourHomePath}.distanceToHydrantOption`, DEFAULT_CLOSE_FIRE_HYDRANT_VALUE);
            } else {
                const field = yourHome[fieldName];
                if (field) {
                    field.value = false;
                }
            }
        });
        const newSubmissionVM = viewModelService.clone(submissionVM);
        updateWizardData(newSubmissionVM);
    }, [getHomeFeaturesAndUseFields, submissionVM, updateWizardData, viewModelService]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const onHomeFeaturesCheckboxValueChange = (value, path) => {
        setNoneOfTheAbove(false);
        writeValue(value, path);
    };

    const updateCheckboxData = useCallback((value, path, updater, trueValue, falseValue) => {
        updater(value);
        setNoneOfTheAbove(false);
        if (value) {
            _.set(submissionVM, path, trueValue);
        } else {
            _.set(submissionVM, path, falseValue);
        }
    }, [submissionVM]);

    const finalizeHomeFeaturesAndUseFields = useCallback(() => {
        const yourHome = _.get(submissionVM, yourHomePath);

        if (yourHome.value.distanceToFireStation === undefined) {
            yourHome.value.distanceToFireStation = DEFAULT_CLOSE_FIRE_STATION_DISTANCE;
        }
        if (yourHome.value.distanceToHydrantOption === undefined) {
            yourHome.value.distanceToHydrantOption = DEFAULT_CLOSE_FIRE_HYDRANT_VALUE;
        }

        getHomeFeaturesAndUseFields().forEach((fieldName) => {
            const field = _.get(yourHome, fieldName);
            if (field) {
                // for all relevant fields, set the value to true or false. Undefined gets set to false.
                if (field.value === undefined) {
                    field.value = false;
                }
            }
        });
    }, [getHomeFeaturesAndUseFields, submissionVM]);

    const homeFeaturesAndUseIsValid = useCallback(() => {
        let isValid = false;
        const homeFeaturesAndUseFields = getHomeFeaturesAndUseFields();
        const yourHome = _.get(submissionVM, yourHomePath);
        if (noneOfTheAbove) {
            return true;
        }
        for (let i = 0; i < homeFeaturesAndUseFields.length; i++) {
            const viewField = yourHome.value[homeFeaturesAndUseFields[i]];
            if (viewField === true || viewField === DEFAULT_FAR_FIRE_STATION_DISTANCE || viewField === DEFAULT_FAR_FIRE_HYDRANT_VALUE) {
                // at least one field has been checked so it passes validation
                isValid = true;
                break;
            }
        }
        return isValid;
    }, [getHomeFeaturesAndUseFields, noneOfTheAbove, submissionVM]);

    const isFormValid = useCallback(() => {
        const homeFeaturesAndUseCompleted = homeFeaturesAndUseIsValid();
        setDisplaySelectAtLeastOneMessage(!homeFeaturesAndUseCompleted);
        const yourHome = _.get(submissionVM, yourHomePath);

        return yourHome.aspects.subtreeValid && homeFeaturesAndUseCompleted;
    }, [homeFeaturesAndUseIsValid, submissionVM]);

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');

        finalizeHomeFeaturesAndUseFields();

        if (!hasDogs) {
            _.set(submissionVM.value, 'lobData.homeowners.coverables.yourHome.dwellingAnimals', []);
        }

        if (!isFormValid()) {
            setShowErrors(true);
            return false;
        }

        setIsLoading(true);

        submissionVM.value = await LoadSaveService.updateDraftSubmission(
            submissionVM.value,
            authHeader
        );
        setIsLoading(false);
        return submissionVM;
    }, [LoadSaveService, authHeader, finalizeHomeFeaturesAndUseFields, isFormValid, submissionVM, hasDogs]);

    const modalProps = {
        modalTitle: translator(messages.loadingQuote),
        isOpen: true
    };

    const overrideProps = {
        defaultValuesButton: {
            visible: defaultValuesEnabled === 'true'
        },
        formTypeTooltipContainer: {
            visible: isOR
        },
        distanceToHydrantOptionContainer: {
            visible: isCA,
        },
        distanceToHydrantOption: {
            onValueChange: (value, path) => updateCheckboxData(value, path, setDistanceToHydrantOption, DEFAULT_FAR_FIRE_HYDRANT_VALUE, DEFAULT_CLOSE_FIRE_HYDRANT_VALUE),
            value: distanceToHydrantOption,
            showErrors: false
        },
        distanceToFireStationContainer: {
            visible: isCA,
        },
        distanceToFireStation: {
            onValueChange: (value, path) => updateCheckboxData(value, path, setDistanceToFireStation, DEFAULT_FAR_FIRE_STATION_DISTANCE, DEFAULT_CLOSE_FIRE_STATION_DISTANCE),
            value: distanceToFireStation,
            showErrors: false
        },
        nearBrushAreaContainer: {
            visible: isCA,
        },
        nearBrushArea: {
            showErrors: false
        },
        physicalCondition: {
            showErrors: false
        },
        hasDwellingEmployees: {
            showErrors: false
        },
        isBusinessConducted: {
            showErrors: false
        },
        businessAssociatesVisit: {
            showErrors: false
        },
        hazardousMaterialsStored: {
            showErrors: false
        },
        spouseAndIHaveFinancialStake: {
            showErrors: false
        },
        fireplaceOrWoodStoveExistsContainer: {
            visible: isSingleFamily
        },
        fireplaceOrWoodStoveExists: {
            showErrors: false
        },
        trampolineExistsContainer: {
            visible: isSingleFamily
        },
        trampolineExists: {
            showErrors: false
        },
        securityBarsContainer: {
            visible: isSingleFamily
        },
        securityBars: {
            showErrors: false
        },
        noneOfTheAbove: {
            value: noneOfTheAbove,
            onValueChange: noneOfTheAboveChanged,
        },
        selectAtLeastOneMessageContainer: {
            visible: displaySelectAtLeastOneMessage
        },
        deniedPolicyDescription: {
            visible: _.get(submissionVM, 'lobData.homeowners.coverables.yourHome.deniedPolicy.value', false)
        },
        hasRecentClaimsOrLosses: {
            visible: isCA
        },
        dogsTable: {
            data: _.get(submissionVM, 'lobData.homeowners.coverables.yourHome.dwellingAnimals'),
            onDataChange: onDogsTableDataChange,
            showErrors: showErrors,
            visible: hasDogs
        },
        residenceType: {
            onValueChange: (value) => writeValue(value, 'lobData.homeowners.coverables.yourHome.residenceType'),
            value: submissionVM?.lobData.homeowners.coverables.yourHome.residenceType?.value?.code,
            availableValues: submissionVM?.lobData.homeowners.coverables.yourHome.residenceType?.aspects.availableValues,
            validationMessages: submissionVM?.lobData.homeowners.coverables.yourHome.residenceType?.aspects.validationMessages
        },
        formType: {
            onValueChange: (value) => writeValue(value, 'lobData.homeowners.coverables.yourHome.formType'),
            value: submissionVM?.lobData.homeowners.coverables.yourHome.formType?.value?.code,
            availableValues: submissionVM?.lobData.homeowners.coverables.yourHome.formType?.aspects.availableValues
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onSetDefaultValues: setDefaultValues,
            onHomeFeaturesCheckboxValueChange
        },
        resolveComponentMap: {
            wmicInput: WMICCustomInput,
            wmicCustomDropdownSelect: WMICCustomDropdownSelect,
            wmicCustomRadioButton: WMICCustomRadioButton,
            wmicCheckbox: WMICCheckbox,
            wmicCustomTooltip: WMICCustomTooltip,
            wmicAnimalTable: WMICAnimalTable,
            WMICButton
        },
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showEmailQuoteButton={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
            <WMICScrollToError counter={timestamp} />
            {isLoading && <WMICProgressModal {...modalProps} />}
        </WizardPage>
    );
}

YourHomePage.propTypes = wizardProps;
export default YourHomePage;