import { defineMessages } from 'react-intl';

export default defineMessages({
    whatBusinessAreYouIn: {
        id: 'guidance.directives.occupation.What business are you in?',
        defaultMessage: 'What business are you in?'
    },
    typeHere: {
        id: 'guidance.directives.occupation.Type Here',
        defaultMessage: 'Type Here'
    }
});
