/* eslint-disable max-len */
import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { WMICRegionUrlUtil } from 'wmic-portals-url-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    isValidNumberForRegion,
    isPossibleNumber
} from 'libphonenumber-js';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useModal } from '@jutro/components';
import {
    CONSTANTS,
    DeviceBreakpoint,
    Position,
    LocalDateUtil,
    WMICRichTextUtil
} from 'wmic-portals-utils-js';

import dayjs from 'dayjs';
import {
    WMICCustomInput,
    WMICModal,
    WMICPremiumBox,
    WMICPremiumBoxFooter,
    WMICCustomTooltip,
    WMICCustomDropdownSelect,
    WMICProgressModal,
    WMICRetrieveInfoContext
} from 'gw-capability-quoteandbind-common-react';

import printLogo from 'wmic-qb-digital-theme-styles/images/Wawanesa_Insurance_black.svg';
import metadata from './WMICQuotePage.metadata.json5';
import styles from './WMICQuotePage.module.scss';
import messages from './WMICQuotePage.messages';
import WMICSpecialCoverageCA from '../../components/WMICSpecialCoverageCA/WMICSpecialCoverageCA';
import WMICAdditionalCoverageOR from '../../components/WMICAdditionalCoverageOR/WMICAdditionalCoverageOR';
import WMICPolicySummaryUtil from '../WMICPolicySummary/WMICPolicySummaryUtil';

const personalProperyLimitOptions = [
    {
        code: '15000.0000', maxValue: 4, name: '15,000', optionValue: 1
    },
    {
        code: '30000.0000', maxValue: 4, name: '30,000', optionValue: 2
    },
    {
        code: '50000.0000', maxValue: 4, name: '50,000', optionValue: 3
    },
    {
        code: 'Other', maxValue: 4, name: 'Other', optionValue: 4
    }
];

function getTooltipText(coverage, translator) {
    let overrideText;
    switch (coverage.publicID) {
        case CONSTANTS.COVERAGE_NAMES.HO_PACKAGE:
            overrideText = translator(messages.deductible);
            break;
        case CONSTANTS.COVERAGE_NAMES.HO_MEDICALPAYMENTS:
            overrideText = translator(messages.medicalPayments);
            break;
        case CONSTANTS.COVERAGE_NAMES.HO_PERSONALLIABILITY:
            overrideText = translator(messages.personalLiability);
            break;
        case CONSTANTS.COVERAGE_NAMES.HO_PERSONALPROPERTY:
            overrideText = translator(messages.personalPropertyCA);
            break;
        default:
    }
    return overrideText;
}

function getAvailableValues(coverage) {
    const patternCode = _.get(coverage, 'terms[0].patternCode');
    if (coverage.publicID === CONSTANTS.COVERAGE_NAMES.HO_PERSONALPROPERTY
        && patternCode === CONSTANTS.COVERAGE_NAMES.HO_PERSONALPROPERTY_LMT) {
        return personalProperyLimitOptions;
    }
    return _.get(coverage, 'terms[0].options');
}

const getCustomQuote = (vm) => {
    const lobCoverages = _.get(vm, 'lobData.homeowners.offerings.value[0].coverages');
    const quoteOffering = _.get(vm, 'quoteData.offeredQuotes.value[0]');
    const clausesToUpdate = ClausesUtil.structureClausesForServer(
        lobCoverages,
        'homeowners',
        null
    );

    return {
        quote: quoteOffering,
        quoteID: vm.quoteID.value,
        sessionUUID: vm.sessionUUID.value,
        periodStart: vm.baseData.periodStartDate.value,
        periodEnd: vm.baseData.periodEndDate.value,
        coverages: clausesToUpdate
    };
};

function displayLabelInsteadOfDropdown(coverage, translator) {
    let toReturn = '';

    if (coverage.name === WMICPolicySummaryUtil.CONSTANTS_COV.ADDL_LIVING_EXPENSES) {
        toReturn = translator(messages.actualLossSustained);
    }

    return toReturn;
}

function getBaseState(submissionVM) {
    return _.get(submissionVM, 'baseData.policyAddress.value.state');
}
function WMICQuote(props) {
    const modalApi = useModal();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData, goNext, updateWizardSnapshot } = props;
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const retrieveContext = useContext(WMICRetrieveInfoContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [isLoading, setIsLoading] = useState(false);
    const { phoneNumber } = appConfig;
    const [showOtherField, setShowOtherField] = useState(false);

    const [validCoverages, setValidCoverages] = useState(true);
    const [pplCode, setPPLCode] = useState("30000.0000");

    const {
        isComponentValid,
        onValidate
    } = useValidation('WMICQuote');

    const [quoteStatus, setQuoteStatus] = useState({
        calculatingQuote: false,
        showLoading: false,
        displayed: true,
        isStale: false,
        isEmailQuoteButtonDisabled: false
    });

    const getChosenQuote = useCallback((vm) => {
        return _.get(vm.value, 'quoteData.offeredQuotes[0]');
    });

    const getNewSubmissionVM = useCallback(
        (response, lobPath, quotePath, lobName) => {
            const clonedSubmissionVM = viewModelService.clone(submissionVM);
            const updatedClauses = _.get(response, `coverages.${lobName}`);
            // Update local offering with new one from xcenter
            _.set(clonedSubmissionVM, `${lobPath}.coverages`, updatedClauses);
            // Update local quote with new one from xcenter
            const status = _.get(response, 'quote.status', 'Draft');
            _.set(clonedSubmissionVM, `${quotePath}.status`, status);
            // Update premium with new one from xcenter
            _.set(clonedSubmissionVM, `${quotePath}.premium`, response.quote.premium);
            return clonedSubmissionVM;
        },
        [viewModelService, submissionVM]
    );

    const getPPLPath = () => {
        const basePath = 'lobData.homeowners.offerings.value[0].coverages';
        const initialValue = _.get(submissionVM, basePath);
        const baseCoveragesIndex = initialValue.baseCoverages.findIndex((item) => {
            return item.publicID === 'HO_PersonalPropertyCov'; 
        });
        const termsIndex = initialValue.baseCoverages[baseCoveragesIndex].terms.findIndex((item) => {
            return item.publicID === 'HO_PersonalPropertyCovLmt';
        });
        const fullPath = `${basePath}.baseCoverages[${baseCoveragesIndex}].terms[${termsIndex}]`;
        return fullPath;
    };

    const getPPLValue = () => {
        const path = getPPLPath();
        const term = _.get(submissionVM, path);
        return term.directValue;
    };

    const recalculate = useCallback(() => {
        let otherFieldValid = true;
        const pplValue = getPPLValue();

        if(!validCoverages) {
            modalApi.showModal(<WMICModal
                iconClass="error-text fa-3x fa fa-exclamation-circle"
                title={translator(messages.coveragesInputErrorModalTitle)}
                message={translator(messages.coveragesInputErrorModalDescription)}
            />).catch(_.noop);
        } 
        if(pplValue < 15000 || pplValue > 210000) {
            otherFieldValid = false;
            modalApi.showModal(
                <WMICModal
                    title={translator(messages.personalPropertyModalTitle)}
                    message={pplValue < 15000? 
                        translator(messages.personalPropertyModal1)
                        : translator(messages.personalPropertyModal2)
                    }
                    iconClass="error-text fa-3x fa fa-exclamation-circle"
                />
            ).catch(_.noop);
        }

        if(validCoverages && otherFieldValid) {
            setQuoteStatus({
                calculatingQuote: true,
                showLoading: true,
                displayed: false,
                isStale: false,
                isEmailQuoteButtonDisabled: false
            });
            const customQuote = getCustomQuote(submissionVM);
            return CustomQuoteService.updateCustomQuote(customQuote, authHeader).then(
                (response) => {
                    const newSubmissionVM = getNewSubmissionVM(
                        response,
                        'lobData.homeowners.offerings.value[0].coverages',
                        'quoteData.offeredQuotes.value[0]',
                        'homeowners'
                    );
                    updateWizardData(newSubmissionVM);
                    setQuoteStatus({
                        calculatingQuote: false,
                        showLoading: false,
                        displayed: true,
                        isStale: false,
                        isEmailQuoteButtonDisabled: false
                    });
                    return response;
                }
            );
        }
        
    }, [CustomQuoteService, authHeader, getNewSubmissionVM, submissionVM, updateWizardData]);

    const isValidNumberForRegionWMIC = (propertyValue) => {
        let returnVal = false;
        const countryCodeCA = 'CA';
        const countryCodeUS = 'US';
        const countryCodePR = 'PR';
        let areaCode = '000';
        let extPos = null;
        let number = null;
        if (propertyValue !== undefined && propertyValue !== null && propertyValue !== '') {
            extPos = propertyValue.indexOf(' x');
            number = extPos > 0 ? propertyValue.substring(0, extPos) : propertyValue;
            number = number.replace(/[-_]/g, '');
            try {
                returnVal = isValidNumberForRegion(number, countryCodeUS);
                returnVal = returnVal || isValidNumberForRegion(number, countryCodeCA);
                returnVal = returnVal || isValidNumberForRegion(number, countryCodePR);
                if (!returnVal) {
                    areaCode = number.substring(0, 3);
                    if (areaCode === '340' || areaCode === '684' || areaCode === '671' || areaCode === '670') {
                        returnVal = isPossibleNumber(number, countryCodeUS);
                    }
                }
            } catch (e) {
                return false; // Suppress log error message from libphonenumber when number is not valid
            }
        }

        return returnVal;
    };

    const buy = useCallback(async () => {
        setIsLoading(true);
        const chosenQuote = getChosenQuote(submissionVM);
        _.set(submissionVM, 'bindData.chosenQuote.value', chosenQuote.publicID);
        if (!submissionVM.bindData.billingAddress.value) {
            _.set(submissionVM, 'bindData.billingAddress.value', submissionVM.baseData.policyAddress.value);
        }
        if (submissionVM.bindData.contactPhone.value !== undefined
            && submissionVM.bindData.contactPhone.value !== ''
            && !isValidNumberForRegionWMIC(submissionVM.bindData.contactPhone.value)) {
            _.set(submissionVM, 'bindData.contactPhone.value', '');
        }
        if (submissionVM.bindData.paymentDetails.value !== undefined) {
            delete submissionVM.value.bindData.paymentDetails
        }
        
        _.set(submissionVM, 'bindData.contactPhone.value', submissionVM.bindData.contactPhone.value || '');
        _.set(submissionVM, 'bindData.contactEmail.value', submissionVM.bindData.contactEmail.value || submissionVM.baseData.accountHolder.emailAddress1.value);
        _.set(submissionVM, 'bindData.contactOccupation.value', submissionVM.bindData.contactOccupation.value || '');
        _.set(submissionVM, 'bindData.primaryNamedInsuredOccupationType_WMIC.value', submissionVM.bindData.primaryNamedInsuredOccupationType_WMIC.value || undefined);
        _.set(submissionVM, 'bindData.hasDapForNotification_WMIC.value', submissionVM.bindData.hasDapForNotification_WMIC.value || false);
        _.set(submissionVM, 'bindData.isLandlordCompany_WMIC.value', submissionVM.bindData.isLandlordCompany_WMIC.value || false);
        _.set(submissionVM, 'bindData.dapPersonEmailAddress_WMIC.value', submissionVM.bindData.dapPersonEmailAddress_WMIC.value || '');
        _.set(submissionVM, 'bindData.hasLandlordForCertificateHolder_WMIC.value', submissionVM.bindData.hasLandlordForCertificateHolder_WMIC.value || false);

        submissionVM.value = await LoadSaveService.updateQuotedSubmission(submissionVM.value, authHeader);
        updateWizardData(submissionVM);
        updateWizardSnapshot(submissionVM);
        goNext();
        return submissionVM;
    }, [submissionVM, LoadSaveService, authHeader, getChosenQuote, goNext, updateWizardData]);

    function getCell(item, index, { id: property }) {
        if( Number(item[property])){
            return `$${Number(item[property]).toFixed(2)}`;
        }
        return item[property];
    }

    const validateCoverages = (coverages) => {
        return new Set(coverages.map((coverage) => coverage)).size === coverages.length;
    };

    const updatePPLValue = (newValue, initial=false) => {
        if(!initial) {
            setQuoteStatus({
                calculatingQuote: false,
                showLoading: false,
                isStale: true,
                isEmailQuoteButtonDisabled: false
            });
        }
        if(!newValue || newValue.length === 1 && newValue.match(/[a-z]/i)) {
            newValue = 0;
        }
        if(newValue.length > 6) {
            newValue = newValue.slice(0,-1);
        }
        const fullPath = getPPLPath();
        const term = _.get(submissionVM, fullPath);

        term.chosenTermValue = newValue.toLocaleString();
        term.directValue = parseInt(newValue);

        // set 'code' for PPL dropdown
        if(typeof(newValue) === 'number') {
            setPPLCode(`${newValue}.0000`);
        }

        _.set(submissionVM, fullPath, term);
        updateWizardData(submissionVM);
    };

    const updateCoverage = useCallback(
        (value, path, coverages = []) => {
            if(coverages.fieldUniqueId && coverages.fieldUniqueId.includes('coverageDropdown1') && value === 'Other') {
                updatePPLValue(0);
                setShowOtherField(true);
            }
            if(coverages.fieldUniqueId && coverages.fieldUniqueId.includes('coverageDropdown1') && value !== 'Other') {
                updatePPLValue(parseInt(value));
                setShowOtherField(false);
            }

            if (getBaseState(submissionVM) === CONSTANTS.JURISDICTIONS.CA && Array.isArray(coverages)) {
                setValidCoverages(validateCoverages(coverages));
            }
            setQuoteStatus({
                isStale: true
            });
            const covPath = path.substring(0, path.indexOf('terms') - 1);
            if (covPath !== "" && !covPath.includes('additionalCoverages')) {
                const specialCov = _.get(submissionVM, covPath);
                specialCov.updated = true
                specialCov.terms[0].updated = true
            }
            else if(coverages.length > 0 && coverages.every((coverage) => coverage.startsWith('HO'))) {
                const addCov = _.get(submissionVM, path.substring(0, path.indexOf('selected') - 1));
                if (addCov !== undefined) {
                    addCov.updated = true;
                    addCov.terms[0].updated = true;
                }
            }

            if (path.match(/additionalCoverages\[\d+\].selected/g) !== null) {
                const additionalCovPath = path.substring(0, path.indexOf('selected') - 1);
                const cov = _.get(submissionVM, additionalCovPath);
                if (cov.updated !== true) {
                    cov.updated = true
                    if (!_.isEmpty(cov.terms)) {
                        cov.terms[0].updated = true;
                    }
                    _.set(submissionVM, additionalCovPath.concat('.updated'), true)
                }
            }
            _.set(submissionVM, path, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const generateCoverageOptions = useCallback(() => {
        const { baseCoverages } = submissionVM.lobData.homeowners.offerings.value[0]
            .coverages;
        const packageItems = baseCoverages.filter((coverage) => {
            return coverage.name === WMICPolicySummaryUtil.CONSTANTS_COV.PACKAGE;
        });
        const otherItems = baseCoverages.filter((coverage) => {
            return coverage.name !== WMICPolicySummaryUtil.CONSTANTS_COV.PACKAGE;
        });
        // Set order priority for otherItems
        otherItems.forEach((elem) => {
            switch (elem.name) {
                case CONSTANTS.COVERAGE_NAMES.PERSONAL_PROPERTY:
                    elem.OrderPriority = 10;
                    break;
                case  CONSTANTS.COVERAGE_NAMES.ADDITIONAL_LIVING_EXPENSES:
                    elem.OrderPriority = 20;
                    break;
                case  CONSTANTS.COVERAGE_NAMES.PERSONAL_LIABILITY:
                    elem.OrderPriority = 30;
                    break;
                case  CONSTANTS.COVERAGE_NAMES.MEDICAL_PAYMENTS:
                    elem.OrderPriority = 40;
                    break;
                default:
                    elem.OrderPriority = 50;
            }
        })
        otherItems.sort((a, b) => (a.OrderPriority > b.OrderPriority) ? 1 : -1);
        // Filtered and concatenated so Deductibles show first
        const newOrderedCoverages = packageItems.concat(otherItems);

        const overrides = newOrderedCoverages.map(
            (coverage, index) => {
                const optionalLabel = displayLabelInsteadOfDropdown(coverage, translator);
                const baseCoverageIndex = baseCoverages.findIndex((pathCoverage) => {
                    return pathCoverage.publicID === coverage.publicID;
                });
                return {
                    [`coverageName${index}`]: {
                        visible: coverage.name !== WMICPolicySummaryUtil.CONSTANTS_COV.PACKAGE,
                        content: coverage.name
                    },
                    [`coverageDropdown${index}`]: {
                        availableValues: getAvailableValues(coverage),
                        onValueChange: (value) => updateCoverage(value, `lobData.homeowners.offerings.value[0].coverages.baseCoverages[${baseCoverageIndex}].terms[0].chosenTerm`, {fieldUniqueId: `coverageDropdown${index}`}),
                        label: _.get(coverage, 'terms[0].name'),
                        showLabel: true,
                        labelPosition: 'top',
                        ...(index === 1 && {value: pplCode}),
                        // Reordering messes up the path, so this fixes it.
                        value: submissionVM?.lobData.homeowners.offerings.value[0].coverages.baseCoverages[baseCoverageIndex].terms[0]?.chosenTerm,
                        alwaysShowPlaceholder: false
                    },
                    [`otherTextField${index}`]: {
                        visible: index === 1 && showOtherField,
                        onValueChange: updatePPLValue,
                        path: `lobData.homeowners.offerings.value[0].coverages.baseCoverages[${baseCoverageIndex}].terms[0].directValue`
                    },
                    [`dropdownAndTooltipContainer${index}`]: {
                        visible: _.isEmpty(optionalLabel),
                    },
                    [`optionalLabel${index}`]: {
                        visible: !_.isEmpty(optionalLabel),
                        content: optionalLabel
                    },
                    [`coverageDropdownTooltip${index}`]: {
                        tooltipTextOverride: getTooltipText(coverage, translator)
                    }
                };
            }
        );

        return Object.assign({}, ...overrides);
    }, [submissionVM.lobData.homeowners.offerings.value, translator, updateCoverage, showOtherField]);

    const resolvers = {
        resolveComponentMap: {
            wmicInput: WMICCustomInput,
            wmicPremiumBox: WMICPremiumBox,
            wmicPremiumBoxFooter: WMICPremiumBoxFooter,
            wmicCustomTooltip: WMICCustomTooltip,
            wmicCustomDropdownSelect: WMICCustomDropdownSelect,
            wmicSpecialCoverageCA: WMICSpecialCoverageCA,
            wmicAdditionalCoverageOR: WMICAdditionalCoverageOR,
            wmicCustomLoading: WMICProgressModal
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            updateCoverage: updateCoverage,
            getCell: getCell
        }
    };

    const getEffDate = () => {
        let date = LocalDateUtil.toMidnightDate((submissionVM.baseData.value.periodStartDate));
        date = dayjs(date).format('MMMM DD, YYYY');
        return date;
    };

    const getAddress = () => {
        const address = submissionVM.baseData.value.policyAddress;
        return (
            <span>
                {address.addressLine1}
                <br />
                {address.city}
                ,
                {address.state}
                {address.postalCode}
            </span>
        );
    };
    const isCA = () => {
        return WMICRegionUrlUtil.getRegion() === CONSTANTS.JURISDICTIONS.CA;
    }

    const getLinkForState = () => {
        return isCA() ? 'ca' : 'or';
    };

    const createDataForCoveragesTable = useCallback(() => {
        return WMICPolicySummaryUtil.createDataForCoveragesTable(submissionVM,
            messages, translator);
    }, [submissionVM, translator]);

    const createDataForDeductibleTable = useCallback(() => {
        const toReturn = WMICPolicySummaryUtil.createDataForDeductibleTable(submissionVM,
            messages, translator);
        return toReturn;
    }, [submissionVM, translator]);

    const skipPageWhen = useCallback(() => {
        return new Promise(((resolve) => { resolve(retrieveContext.allowNavigationToPaymentDetails || retrieveContext.allowNavigationToRecurringPaymentAuthorization || retrieveContext.allowNavigationToRecurringPaymentDetails); }));
    }, [retrieveContext]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
        },
        caSpecialCoverages: {
            visible: getBaseState(submissionVM) === CONSTANTS.JURISDICTIONS.CA,
            submissionVM: submissionVM,
            updateCoverage,
            validCoverages
        },
        orAdditionalCoverages: {
            visible: getBaseState(submissionVM) === CONSTANTS.JURISDICTIONS.OR,
            submissionVM: submissionVM,
            updateCoverage: updateCoverage
        },
        wmicPremiumBox: {
            isStale: quoteStatus.isStale,
            showLoading: quoteStatus.showLoading,
            displayed: quoteStatus.displayed,
            monthlyAmount: _.get(submissionVM.value, 'quoteData.offeredQuotes[0].premium.monthlyPremium'),
            fullAmount: _.get(submissionVM.value, 'quoteData.offeredQuotes[0].premium.total'),
            recalculate
        },
        wmicPremiumBoxTopContainer: {
            className: styles.quoteTop
        },
        wmicPremiumBoxFooter: {
            isStale: quoteStatus.isStale,
            showLoading: quoteStatus.showLoading,
            displayed: quoteStatus.displayed,
            monthlyAmount: _.get(submissionVM.value, 'quoteData.offeredQuotes[0].premium.monthlyPremium'),
            fullAmount: _.get(submissionVM.value, 'quoteData.offeredQuotes[0].premium.total'),
            onBuy: buy,
            validCoverages,
            recalculate
        },

        ...generateCoverageOptions(),

        printWawaLogo: {
            src: printLogo
        },
        printQuoteNumber: {
            content: translator(messages.quoteNumber, { quoteID: submissionVM.value.quoteID })
        },
        printQuotePrice: {
            content: translator(messages.quotePrice, { quotePrice: submissionVM.value.quoteData.offeredQuotes[0].premium.monthlyPremium.amount })
        },
        total: {
            content: translator(messages.quoteTotalPrice, { quoteTotalPrice: submissionVM.value.quoteData.offeredQuotes[0].premium.total.amount })
        },
        printQuotePremiumFor12Months: {
            content: translator(messages.effDateText, { effectiveDate: getEffDate() })
        },
        printQuoteNameValue: {
            content: submissionVM.baseData.value.accountHolder.displayName
        },
        printQuoteAddressValue: {
            content: getAddress()
        },
        customerContactLink: {
            content: WMICRichTextUtil.translateRichText(translator(messages.retrieveQuoteOnlineLink, { stateCode: getLinkForState() }))
        },
        coveragesTable: {
            data: createDataForCoveragesTable()
        },
        deductibleTable: {
            data: createDataForDeductibleTable()
        },
        contactWithSpecialist: {
            content: translator(messages.toSpeakWithQuoteSpecialist, { phoneNumber })
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
    };

    const buyModalProps = {
        modalTitle: translator(messages.buyModalMessage),
        isOpen: true
    };

    return (
        <WizardPage
            skipWhen={skipPageWhen}
            disableNext={!isComponentValid}
            quoteStatus={quoteStatus}
            setQuoteStatus={setQuoteStatus}
            showCaliforniaLawDisclaimer={isCA()}
            recalculate={recalculate}
            validCoverages={validCoverages}
            showNext={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={readValue}
            />
            {isLoading && <WMICProgressModal {...buyModalProps} />}
        </WizardPage>
    );
}

WMICQuote.propTypes = wizardProps;
export default WMICQuote;