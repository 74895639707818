import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@jutro/components';
import DynamicContentUtil from '../../util/DynamicContentUtil';
import styles from './GTPQuestion.module.scss';

import { IconButton, CheckboxField } from '@jutro/legacy/components';

function GTPQuestion(props) {
    const { value, path, onValueChange } = props;
    const {
        text, code, helpText, answer
    } = value;
    const [isHelpTextVisible, setHelpTextVisiblity] = useState(false);

    const writeValue = useCallback(() => {
        const newValue = {
            ...value,
            answer: _.has(value, 'answer') ? !value.answer : true
        };
        onValueChange(newValue, path);
    }, [onValueChange, path, value]);

    const toggleHelpTextVisiblity = useCallback(
        (event) => {
            event.stopPropagation();
            setHelpTextVisiblity(!isHelpTextVisible);
        },
        [isHelpTextVisible]
    );

    const containerClasses = classNames(styles.questionContainer, {
        [styles.selectedQuestion]: answer
    });

    return (
        <button
            type="button"
            className={containerClasses}
            id={`question_${code}`}
            onClick={writeValue}
        >
            <CheckboxField
                id={`questionCheckbox_${code}`}
                path={path}
                value={answer}
                onValueChange={writeValue}
                className={styles.questionCheckbox}
            />
            <Icon className={styles.icon} icon={DynamicContentUtil.getQuestionIcon(code)} />

            <div className={styles.textContainer}>
                <span className={styles.questionTitle}>{text}</span>
                {isHelpTextVisible ? <span className={styles.helpText}>{helpText}</span> : null}
            </div>

            <IconButton
                icon="mi-help_outline"
                onClick={toggleHelpTextVisiblity}
                className={styles.helpTextButton}
            />
        </button>
    );
}

GTPQuestion.propTypes = {
    value: PropTypes.shape({
        text: PropTypes.string,
        code: PropTypes.string,
        helpText: PropTypes.string,
        answer: PropTypes.bool
    }).isRequired,
    path: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired
};
export default GTPQuestion;
