import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { WMICErrorHandlingService } from 'gw-capability-quoteandbind-common-react';
import { WMICJsonRPCService } from 'wmic-portals-transport-js';

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class MultiProductService
 */
export default class MultiProductService {
    /**
     * Invokes the given method on the backend endpoint passing the given data as parameters
     *
     * @param {String} QuoteId quote id of the submission being reffered to
     * @param {String} MPDPolicyNumber policy number of the multi product discount to be validated
     * @returns {Promise} the promise from the backend call
     */
    static validateMultiProductPolicyNumber(QuoteId, MPDPolicyNumber = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('quote'), 'validateMultiProductPolicyNumber', [QuoteId, MPDPolicyNumber]).then(
            (response) => {
                WMICErrorHandlingService.checkValidationError(response);
                return response;
            },
            (errorResponse) => {
                WMICErrorHandlingService.checkFailedRequestError(errorResponse, QuoteId);
            }
        );
    }
}
