import React from 'react';
import _ from 'lodash';
import { InlineLoader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { pageTemplateProps } from '@xengage/gw-portals-wizard-react';
import { ImageComponent } from 'gw-components-platform-react';
import GTPPreviousSteps from '../components/GTPPreviousSteps/GTPPreviousSteps';
import DynamicContentUtil from '../util/DynamicContentUtil';
import styles from './GuidanceToProductPageTemplate.module.scss';
import messages from '../GTPWizard.messages';

import { Button } from '@jutro/legacy/components';

function renderLoader(isLoading) {
    return isLoading ? <InlineLoader loading={isLoading} className="gw-inline-loader" /> : null;
}

function GuidanceToProductPageTemplate(props) {
    const translator = useTranslator();
    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        isLoadingPrevious,
        isLoadingCancel,
        wizardData: submissionVM
    } = props;

    const isPerformingTransiton = isLoadingCancel || isLoadingPrevious || isLoadingNext;
    const industryCode = _.get(submissionVM, 'industryCode.code.value');
    const industryName = _.get(submissionVM, 'industryCode.classification.value');

    return (
        <div className={styles.digitalWizard}>
            <div className={styles.wizardContent}>
                <div className={styles.wizardNavigation}>
                    <GTPPreviousSteps />
                </div>
                <div className={styles.wizardPageContent}>{content}</div>
                <div className={styles.wizardButtons}>
                    {showNext && (
                        <Button
                            id="gw-wizard-Next"
                            disabled={disableNext || isPerformingTransiton}
                            onClick={onNext}
                        >
                            {translator(messages.continue)}
                            {renderLoader(isLoadingNext)}
                        </Button>
                    )}
                </div>
            </div>
            <div className={styles.wizardImage}>
                <ImageComponent
                    src={DynamicContentUtil.getOccupationImage(industryCode)}
                    alt={industryName}
                />
            </div>
        </div>
    );
}

GuidanceToProductPageTemplate.propTypes = pageTemplateProps;

export default GuidanceToProductPageTemplate;
