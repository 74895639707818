import { defineMessages } from 'react-intl';

export default defineMessages({
    addressSearchPlaceholder: {
        id: 'guidance.directives.address.Find your Address',
        defaultMessage: 'Find your Address'
    },
    tellUsYourLocation: {
        id: 'guidance.directives.address.Great, now tell us your location',
        defaultMessage: 'Great, now tell us your location'
    },
    addressNotInList: {
        id: 'guidance.directives.address.My address is not in this list',
        defaultMessage: 'My address isn\'t in this list'
    },
    backToSearch: {
        id: 'guidance.directives.address.Back to search',
        defaultMessage: 'Back to search'
    },
});
