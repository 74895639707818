import { defineMessages } from 'react-intl';

export default defineMessages({
    optionalPropertyCoverages: {
        id: 'quoteandbind.ho.directives.ho-additional-coverages.Optional Property Coverages',
        defaultMessage: 'Optional Property Coverages'
    },
    toAddCoverage: {
        id: 'quoteandbind.ho.directives.ho-main-coverages.To add coverage for personal items',
        defaultMessage: 'To add coverage for personal items to your policy (Scheduled Personal Property coverage), please call us at {phoneNumber} once your policy purchase is complete'
    }
});
