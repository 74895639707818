/* eslint-disable max-len */
/* eslint-disable no-throw-literal */
import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { WMICPaymentSteps } from 'gw-capability-quoteandbind-ho-react';
import { WMICModal, WMICProgressModal, WMICRetrieveInfoContext } from 'gw-capability-quoteandbind-common-react';
import { WMICESignatureService } from 'wmic-capability-quoteandbind';
import { WMICFeatureFlagUtil, ERROR_CODE, ESIGNATURE_STATUS, WMICRichTextUtil } from 'wmic-portals-utils-js';
import { LoadSaveService } from 'gw-capability-quoteandbind';

import metadata from './WMICRecurringPaymentAuthorization.metadata.json5';
import messages from './WMICRecurringPaymentAuthorization.messages';

const SENDER_EMAIL = 'insure.us@wawanesa.com';

function WMICRecurringPaymentAuthorization(props) {
    const modalApi = useModal();
    const {
        wizardData: submissionVM, updateWizardData, jumpTo, steps, goNext, updateWizardSnapshot
    } = props;
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const translator = useTranslator();
    const retrieveContext = useContext(WMICRetrieveInfoContext);
    const { phoneNumber } = appConfig;

    const [isPageInitialized, setPageInitialized] = useState(false);
    const [isMarpEnabled, setMarpEnabled] = useState(true);
    const [effectiveEmail, setEffectiveEmail] = useState(_.get(submissionVM, 'bindData.payerEmail_WMIC.value'));
    const [ldFlags, setLdFlags] = useState({});
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const baseState = _.get(submissionVM, 'baseData.policyAddress.value.state');
    const serverInfo = _.get(submissionVM, 'serverInfo_WMIC.value');

    const handleNext = useCallback(() => {
        updateWizardData(submissionVM);
        goNext();
    }, [goNext, submissionVM, updateWizardData]);

    const determineMarpAvailability = () => {
        const params = {
            featureName: featureFlags.marp,
            regionCode: baseState,
            serverInfo
        };
        const response = WMICFeatureFlagUtil.queryAvailabilityQB(ldFlags, params);
        setMarpEnabled(response.isAvailable);
    }

    const showMarpUnavailablePopup = useCallback(() => {
        modalApi.showModal(
            <WMICModal
                title={messages.serviceTemporarilyUnavailable}
                message={translator(messages.onlineServiceUnavailable)}
                description={WMICRichTextUtil.translateRichText(translator(messages.onlinePaymentPlanUnavailable, { phoneNumber, quoteNumber: _.get(submissionVM, 'quoteID.value') }))}
                iconClass="error-text fa-3x fa fa-info-circle"
                actionBtnLabel={messages.continue}
            />
        );
    }, [phoneNumber, submissionVM, translator]);

    const onResendEmail = useCallback((showModal, updateEmailAddress) => {
        const params = {
            featureName: featureFlags.marp,
            regionCode: baseState,
            serverInfo
        };
        const marpAvailable = WMICFeatureFlagUtil.queryAvailabilityQB(ldFlags, params).isAvailable;
        setMarpEnabled(marpAvailable);

        if (marpAvailable) {
            const resendEmailPromise = WMICESignatureService.resendESignature(
                _.get(submissionVM, 'quoteID.value'),
                _.get(submissionVM, 'sessionUUID.value'),
                updateEmailAddress ? _.get(submissionVM, 'bindData.payerEmail_WMIC.value') : null,
                authHeader
            );

            resendEmailPromise.then((response) => {
                if (updateEmailAddress) {
                    const payerPID = response.publicID;
                    const newEmailAddress = _.get(submissionVM, 'bindData.payerEmail_WMIC.value');
                    switch (payerPID) {
                        case _.get(submissionVM, 'baseData.accountHolder.publicID.value'):
                            submissionVM.bindData.contactEmail = newEmailAddress;
                            submissionVM.baseData.accountHolder.emailAddress1 = newEmailAddress;
                            break;
                        case _.get(submissionVM, 'baseData.aniperson_WMIC.publicID.value'):
                            submissionVM.baseData.addlNamedInsuredEmailAddress1 = newEmailAddress;
                            break;
                        case _.get(submissionVM, 'bindData.dapPerson_WMIC.publicID.value'):
                            submissionVM.bindData.dapPersonEmailAddress_WMIC = newEmailAddress;
                            break;
                        default: // i.e. payer is 'other'
                    }
                    updateWizardSnapshot(submissionVM);
                }
                if (showModal) {
                    modalApi.showModal(
                        <WMICModal
                            title={messages.authorizationInstructionsEmailed}
                            message={translator(messages.authorizationInstructionsSent, { emailAddress: _.get(submissionVM, 'bindData.payerEmail_WMIC.value') })}
                            iconClass="error-text fa-3x fa fa-info-circle"
                            actionBtnLabel={messages.continue}
                        />
                    );
                }
            }).catch((error) => {
                throw { quoteID: _.get(submissionVM, 'quoteID.value'), code: error.code };
            });
        } else {
            showMarpUnavailablePopup();
        }
    }, [authHeader, baseState, featureFlags.marp, ldFlags, serverInfo, showMarpUnavailablePopup, submissionVM, translator, updateWizardSnapshot]);


    const navigateToPaymentDetails = useCallback(() => {
        const indexOfPaymentDetails = _.findIndex(
            steps,
            ({ path }) => path === '/policy-summary'
        );
        jumpTo(indexOfPaymentDetails);
    }, [jumpTo, steps]);

    const proceedToNextStep = useCallback(() => {
        const params = {
            featureName: featureFlags.marp,
            regionCode: baseState,
            serverInfo
        };
        const marpAvailable = WMICFeatureFlagUtil.queryAvailabilityQB(ldFlags, params).isAvailable;
        setMarpEnabled(marpAvailable);

        if (marpAvailable) {
            const getESignatureStatusPromise = WMICESignatureService.getESignatureStatus(
                _.get(submissionVM, 'quoteID.value'),
                _.get(submissionVM, 'sessionUUID.value'),
                null,
                true,
                authHeader
            );

            modalApi.showModal(<WMICProgressModal modalTitle={translator(messages.retrievingSigningStatus)} promise={getESignatureStatusPromise} />).then((response) => {
                if (response.esignatureDocumentStatusName === ESIGNATURE_STATUS.SIGNED || response.esignatureDocumentStatusName === ESIGNATURE_STATUS.OPTIMISTICALLY_SIGNED) {
                    // get the latest submission and go to the confirmation screen
                    const retrieveSubmissionRequest = {
                        quoteID: _.get(submissionVM, 'quoteID.value'),
                        postalCode: _.get(submissionVM, 'baseData.policyAddress.postalCode.value'),
                        email: _.get(submissionVM, 'bindData.payerIsPNI_WMIC.value') ? _.get(submissionVM, 'bindData.payerEmail_WMIC.value')
                            : submissionVM.baseData.accountHolder.emailAddress1
                    };
                    const retrieveSubmissionPromise = LoadSaveService.retrieveSubmission(retrieveSubmissionRequest, authHeader);
                    modalApi.showModal(<WMICProgressModal modalTitle={translator(messages.retrievingSubmission)} promise={retrieveSubmissionPromise} />).then((submission) => {
                        submissionVM.value = submission;
                        handleNext();
                    }).catch(() => {
                        throw { quoteID: _.get(submissionVM, 'quoteID.value'), code: ERROR_CODE.TECHNICAL_ERROR_RETRIEVE_QUOTE };
                    });
                } else if (response.esignatureDocumentStatusName === ESIGNATURE_STATUS.EXPIRED
                    || response.esignatureDocumentStatusName === ESIGNATURE_STATUS.DECLINED
                    || response.esignatureDocumentStatusName === ESIGNATURE_STATUS.CANCELLED) {
                    navigateToPaymentDetails();
                }
            }).catch((error) => {
                throw { quoteID: _.get(submissionVM, 'quoteID.value'), code: error.code };
            });
        } else {
            showMarpUnavailablePopup();
        }
    }, [authHeader, baseState, featureFlags.marp, handleNext, ldFlags, navigateToPaymentDetails, serverInfo, showMarpUnavailablePopup, submissionVM, translator]);

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    useEffect(() => {
        initFeatureFlags();
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        determineMarpAvailability();    
    }, [ldFlags])

    const skipPageWhen = useCallback(() => {
        return new Promise(((resolve) => { resolve(retrieveContext.allowNavigationToRecurringPaymentDetails); }));
    }, [retrieveContext]);

    const resolvers = {
        resolveComponentMap: {
            paymentSteps: WMICPaymentSteps
        }
    };

    const overrideProps = {
        marpNotEnabledNotificationContainer: {
            visible: !isMarpEnabled
        },
        onlinePaymentPlanUnavailable: {
            content: WMICRichTextUtil.translateRichText(translator(messages.onlinePaymentPlanUnavailable, { quoteNumber: _.get(submissionVM, 'quoteID.value') }))
        },
        paymentSteps: {
            steps: {
                checkEmail: {
                    email: effectiveEmail,
                },
                openEmail: {
                    email: SENDER_EMAIL,
                    link: {
                        label: translator(messages.resendEmail),
                        onClick: () => onResendEmail(true, false)
                    }
                },
                readAndSign: {
                    link: {
                        label: translator(messages.proceedToNextStep),
                        onClick: proceedToNextStep
                    }
                }
            },
            onResendEmail: onResendEmail,
            submissionVM: submissionVM,
            updateWizardData: updateWizardData,
            setEffectiveEmail: setEffectiveEmail
        },
        needHelp: {
            content: WMICRichTextUtil.translateRichText(translator(messages.needHelp, { phoneNumber, quoteNumber: _.get(submissionVM, 'quoteID.value') }))
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            showNext={false}
            showEmailQuoteButton={false}
            showQuoteDisclaimer={false}
            skipWhen={skipPageWhen}
        >
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers} />
        </WizardPage>
    );
}

WMICRecurringPaymentAuthorization.propTypes = wizardProps;
export default WMICRecurringPaymentAuthorization;