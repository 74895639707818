// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICRetrievePage__main{display:flex;flex-grow:1;justify-content:center}.app__WMICRetrievePage__main>.app__WMICRetrievePage__content{padding-top:15px}.app__WMICRetrievePage__main>.app__WMICRetrievePage__content>.app__WMICRetrievePage__uiView{flex:1 1}.app__WMICRetrievePage__main>.app__WMICRetrievePage__container{flex:0 1 90em}.app__WMICRetrievePage__retrieveQuoteContainer{--retrieve-quote-container-padding:64px 16px 0;--retrieve-quote-container-width:250px;display:flex;flex-direction:column;height:auto;margin:0 auto;padding:var(--retrieve-quote-container-padding);text-align:center;width:var(--retrieve-quote-container-width)}.app__WMICRetrievePage__retrieve-container{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./src/pages/WMICRetrievePage/WMICRetrievePage.module.scss"],"names":[],"mappings":"AAGA,6BACE,YAAA,CACA,WAAA,CACA,sBAAA,CAEA,6DACE,gBAAA,CAEA,4FACE,QAAA,CAIJ,+DACE,aAAA,CAIJ,+CACE,8CAAA,CACA,sCAAA,CAGA,YAAA,CAGA,qBAAA,CAJA,WAAA,CAEA,aAAA,CAGA,+CAAA,CAFA,iBAAA,CAGA,2CAAA,CAGF,2CACE,YAAA,CACA,sBAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~wmic-qb-digital-theme-styles/sass/customer_variables\";\n\n.main {\n  display: flex;\n  flex-grow: 1;\n  justify-content: center;\n\n  > .content {\n    padding-top: 15px;\n\n    > .uiView {\n      flex: 1;\n    }\n  }\n\n  > .container {\n    flex: 0 1 $maximumPageWidth;\n  }\n}\n\n.retrieveQuoteContainer {\n  --retrieve-quote-container-padding: 64px 16px 0;\n  --retrieve-quote-container-width: 250px;\n\n  height: auto;\n  display: flex;\n  margin: 0 auto;\n  text-align: center;\n  flex-direction: column;\n  padding: var(--retrieve-quote-container-padding);\n  width: var(--retrieve-quote-container-width);\n}\n\n.retrieve-container {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "app__WMICRetrievePage__main",
	"content": "app__WMICRetrievePage__content",
	"uiView": "app__WMICRetrievePage__uiView",
	"container": "app__WMICRetrievePage__container",
	"retrieveQuoteContainer": "app__WMICRetrievePage__retrieveQuoteContainer",
	"retrieve-container": "app__WMICRetrievePage__retrieve-container"
};
export default ___CSS_LOADER_EXPORT___;
