// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__YourAgePage__quickQuoteStepperField{display:flex;justify-content:space-between}.app__YourAgePage__quickQuoteStepperField input{background:none;width:100%}.app__YourAgePage__informationTextContainer{margin-top:var(--GW-LAYOUT-6);text-align:center}.app__YourAgePage__termsAndConditions{margin-top:var(--GW-LAYOUT-12);text-align:left}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-slq-react/pages/YourAge/YourAgePage.module.scss"],"names":[],"mappings":"AAAA,0CACE,YAAA,CACA,6BAAA,CAEA,gDAEE,eAAA,CADA,UACA,CAIJ,4CAEE,6BAAA,CADA,iBACA,CAGF,sCAEE,8BAAA,CADA,eACA","sourcesContent":[".quickQuoteStepperField {\n  display: flex;\n  justify-content: space-between;\n\n  input {\n    width: 100%;\n    background: none;\n  }\n}\n\n.informationTextContainer {\n  text-align: center;\n  margin-top: var(--GW-LAYOUT-6);\n}\n\n.termsAndConditions {\n  text-align: left;\n  margin-top: var(--GW-LAYOUT-12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quickQuoteStepperField": "app__YourAgePage__quickQuoteStepperField",
	"informationTextContainer": "app__YourAgePage__informationTextContainer",
	"termsAndConditions": "app__YourAgePage__termsAndConditions"
};
export default ___CSS_LOADER_EXPORT___;
