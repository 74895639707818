import React, { useContext, useMemo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { GuidanceService } from 'gw-capability-guidance';
import { QuickQuoteWizardPageTemplate } from 'gw-capability-quoteandbind-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import DynamicContentUtil from '../../util/DynamicContentUtil';
import GuidanceToProductPageTemplate from '../../templates/GuidanceToProductPageTemplate';
import metadata from './RecommendationsPage.metadata.json5';

import styles from './RecommendationsPage.module.scss';
import './RecommendationsPage.messages';

function getWizardLink(lob) {
    if (lob === 'BusinessOwners') {
        return '/quote-bop';
    }
    if (lob === 'PersonalAuto') {
        return '/quote-pa';
    }
    return null;
}

function RecommendationsPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM } = props;

    const getAQuote = useCallback(
        (index) => async () => {
            const { history } = props;
            const selectedProduct = _.get(submissionVM, `recommendedProducts.value[${index}]`);
            const data = [submissionVM.value, selectedProduct.code];
            const submission = await GuidanceService.createAccountAndSubmission(data);

            const wizardLink = getWizardLink(selectedProduct.code);
            const nextState = { submission };

            // Using replace to send user back to the landing page
            // instead of the Guidance to Product flow
            history.replace(wizardLink, nextState);
        },
        [props, submissionVM]
    );

    const overrideProps = useMemo(() => {
        const recommendations = _.get(submissionVM, 'recommendedProducts.value');
        const overrides = recommendations.map((recommendation, index) => ({
            [`productImage${index}`]: {
                src: DynamicContentUtil.getProductCodeImage(recommendation.code),
                alt: recommendation.name
            },
            [`getQuote${index}`]: {
                visible: recommendation.availableForQuote,
                onClick: getAQuote(index)
            },
            [`talkToAnAgent${index}`]: {
                visible: !recommendation.availableForQuote
            }
        }));
        return Object.assign({}, ...overrides);
    }, [getAQuote, submissionVM]);

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <WizardPage
            showNext={false}
            showPrevious={false}
            template={
                breakpoint === 'phoneWide' || breakpoint === 'phone'
                    ? QuickQuoteWizardPageTemplate
                    : GuidanceToProductPageTemplate
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

RecommendationsPage.propTypes = wizardProps;
export default withRouter(RecommendationsPage);
