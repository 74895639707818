import { defineMessages } from 'react-intl';

export default defineMessages({
    privacyInfoDescription: {
        id: 'quoteandbind.common.views.privacy-info.Description',
        defaultMessage: 'For information about the categories of personal information collected, the purposes for which it is collected or used and whether that information is sold or shared, visit our'
    },
    privacyPolicyName: {
        id: 'quoteandbind.common.views.privacy-info.Name',
        defaultMessage: ' Privacy Policy.'
    }
});
