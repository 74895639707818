// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__LoadingModal__span2{width:16.6666666667%}.app__LoadingModal__span2,.app__LoadingModal__span8{float:left;min-height:1px;padding-left:.8333333333%;padding-right:.8333333333%}.app__LoadingModal__span8{width:66.6666666667%}", "",{"version":3,"sources":["webpack://./../common/modules-react/wmic-components-qnb-common-react/LoadingModal/LoadingModal.module.scss"],"names":[],"mappings":"AAAA,0BACI,oBAIA,CAGJ,oDANI,UAAA,CAGA,cAAA,CAFA,yBAAA,CACA,0BASA,CALJ,0BACI,oBAIA","sourcesContent":[".span2 {\n    width: 16.6666666667%;\n    float: left;\n    padding-left: 0.8333333333%;\n    padding-right: 0.8333333333%;\n    min-height: 1px;\n}\n\n.span8 {\n    width: 66.6666666667%;\n    float: left;\n    padding-left: 0.8333333333%;\n    padding-right: 0.8333333333%;\n    min-height: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"span2": "app__LoadingModal__span2",
	"span8": "app__LoadingModal__span8"
};
export default ___CSS_LOADER_EXPORT___;
