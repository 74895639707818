/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { WMICButton } from 'wmic-components-platform-react';
import {
    WMICCustomInput,
    WMICCustomDropdownSelect,
    WMICAddressLookupComponent,
    WMICProgressModal,
    WMICSwitchIconTooltip,
    WMICCustomRadioButton,
    WMICCustomTooltip,
    WMICDatePicker,
    WMICRetrieveInfoContext,
    WMICModal,
    WMICTimezoneUtilService
} from 'gw-capability-quoteandbind-common-react';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { AvailabilityService } from 'gw-capability-policycommon';
import { useModal } from '@jutro/components';
import { CONSTANTS, WMICVariousUtil, LocalDateUtil, WMICRichTextUtil } from 'wmic-portals-utils-js';
import YourInfoPageUtil from './YourInfoPageUtil';

import metadata from './YourInfoPage.metadata.json5';
import messages from './YourInfoPage.messages';
import styles from './YourInfoPage.module.scss';

dayjs.extend(utc);

const VALIDATORS = {
    mmddyyy: /(?=\d)^(?:(?!(?:10\D(?:0?[5-9]|1[0-4])\D(?:1582))|(?:0?9\D(?:0?[3-9]|1[0-3])\D(?:1752)))((?:0?[13578]|1[02])|(?:0?[469]|11)(?!\/31)(?!-31)(?!\.31)|(?:0?2(?=.?(?:(?:29.(?!000[04]|(?:(?:1[^0-6]|[2468][^048]|[3579][^26])00))(?:(?:(?:\d\d)(?:[02468][048]|[13579][26])(?!\x20BC))|(?:00(?:42|3[0369]|2[147]|1[258]|09)\x20BC))))))|(?:0?2(?=.(?:(?:\d\D)|(?:[01]\d)|(?:2[0-8])))))([-.\/])(0?[1-9]|[12]\d|3[01])\2(?!0000)((?=(?:00(?:4[0-5]|[0-3]?\d)\x20BC)|(?:\d{4}(?!\x20BC)))\d{4}(?:\x20BC)?)(?:$|(?=\x20\d)\x20))?((?:(?:0?[1-9]|1[012])(?::[0-5]\d){0,2}(?:\x20[aApP][mM]))|(?:[01]\d|2[0-3])(?::[0-5]\d){1,2})?$/
}
function YourInfoPage(props) {
    const modalApi = useModal();
    const { wizardData: submissionVM, updateWizardData } = props;
    const { onValidate, isComponentValid } = useValidation('YourInfoPage');
    const retrieveContext = useContext(WMICRetrieveInfoContext);
    const translator = useTranslator();
    const [showErrors, setShowErrors] = useState(false);
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [dateOfBirth, setDateOfBirth] = useState(undefined);
    const [dateOfBirthANI, setDateOfBirthANI] = useState(undefined);
    const { defaultValuesEnabled = false } = appConfig;

    const setDefaultFieldsBeforeNext = useCallback(() => {
        const mailingAddress = YourInfoPageUtil.getMailingAddress(submissionVM);
        _.set(submissionVM, 'baseData.mailingAddress_WMIC.value', mailingAddress);
        _.set(submissionVM, 'baseData.addlNamedInsuredAddress.value', submissionVM.baseData.policyAddress.value);
        _.set(submissionVM, 'value.region_WMIC', submissionVM.baseData.policyAddress.value.state);
    }, [submissionVM]);

    const onDateValueChange = (value) => {
        const inputDate = value.target.value;

        const dateFormats = ['MM/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'M/DD/YYYY', 'YYYY-MM-DD'];
        const parsedInputDate = dayjs(inputDate, dateFormats, true);
        const isValid = parsedInputDate.isValid();

        if(parsedInputDate && isValid) {
            const dateObject = {
                month: `${parsedInputDate.month()}`,
                day: `${parsedInputDate.date()}`,
                year: `${parsedInputDate.year()}`
            };

            _.set(submissionVM, 'baseData.periodStartDate', dateObject);
            updateWizardData(submissionVM);
        } else {
            _.set(submissionVM, 'baseData.periodStartDate', undefined);
            updateWizardData(submissionVM);
        }
    }

    const onNext = useCallback(() => {
        setDefaultFieldsBeforeNext();

        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }

        const ANIOtherValue = _.get(submissionVM, 'baseData.addlNamedInsuredRelationship.value.code');
        if(ANIOtherValue && ANIOtherValue === CONSTANTS.UNKNOWN) {
            return false;
        }

        const hasAddlNamedInsured = _.get(submissionVM, 'baseData.hasAddlNamedInsured.value');
        const aniperson_WMIC = _.get(submissionVM, 'baseData.aniperson_WMIC');
        const addlNamedInsuredRelationship = _.get(submissionVM, 'baseData.addlNamedInsuredRelationship');

        // Check if value changed after backnav
        if(!hasAddlNamedInsured && aniperson_WMIC && addlNamedInsuredRelationship) {
            _.unset(submissionVM.value, 'baseData.aniperson_WMIC');
            _.unset(submissionVM.value, 'baseData.addlNamedInsuredRelationship');
        }

        const availabilityData = {
            effectiveDate_WMIC: submissionVM.value.baseData.periodStartDate,
            effectiveRegion_WMIC: submissionVM.value.baseData.policyAddress.state,
            postalCode: submissionVM.value.baseData.policyAddress.postalCode,
            productCode: CONSTANTS.POLICY_TYPES.HO.HOMEOWNERS_HOE
        };

        const availabilityPromise = AvailabilityService.productAvailableBasedOnPostalCode(availabilityData)

        return modalApi.showModal(
            <WMICProgressModal
                modalTitle={translator(messages.covAvailable)}
                isOpen
                promise={availabilityPromise}
            />
        ).then((response) => {
            if (response.isAvailable) {
                _.unset(submissionVM.value, 'bindData');
                
                if (submissionVM.value.baseData?.hasAddlNamedInsured === "false") {
                    submissionVM.value.baseData.hasAddlNamedInsured = false;
                    submissionVM.value.baseData.addlNamedInsuredAddress = submissionVM.value.baseData.policyAddress;
                    _.unset(submissionVM.value, 'baseData.aniperson_WMIC');
                }
                const data = {
                    baseData: {
                        accountHolder: submissionVM.value.baseData.accountHolder,
                        policyAddress: submissionVM.value.baseData.policyAddress,
                        periodStartDate: submissionVM.value.baseData.periodStartDate,
                        minEffectiveDate_WMIC: submissionVM.baseData.minEffectiveDate_WMIC.value,
                        maxEffectiveDate_WMIC: submissionVM.baseData.maxEffectiveDate_WMIC.value,
                        productCode: CONSTANTS.POLICY_TYPES.HO.HOMEOWNERS_HOE,

                        mailingAddress_WMIC: submissionVM.value.baseData.mailingAddress_WMIC,

                        hasAddlNamedInsured: submissionVM.value.baseData.hasAddlNamedInsured,
                        aniperson_WMIC: submissionVM.value.baseData.aniperson_WMIC,
                        addlNamedInsuredAddress: submissionVM.value.baseData.addlNamedInsuredAddress,
                        addlNamedInsuredRelationship: submissionVM.value.baseData.addlNamedInsuredRelationship,
                        addlNamedInsuredEmailAddress1: submissionVM.value.baseData.addlNamedInsuredEmailAddress1,
                        addlNamedInsuredOccupationType_WMIC: submissionVM.value.baseData.addlNamedInsuredOccupationType_WMIC,
                        addlNamedInsuredOccupation: submissionVM.value.baseData.addlNamedInsuredOccupation,
                        insuranceScoreConsent_WMIC: submissionVM.value.baseData.insuranceScoreConsent_WMIC
                    },
                    region_WMIC: submissionVM.baseData.policyAddress.value.state
                };
                let savePromise;
                let loadingMsg;
                if (submissionVM.quoteID.value !== undefined) {
                    savePromise = LoadSaveService.updateDraftSubmission(submissionVM.value);
                    loadingMsg = messages.loadingQuote;
                } else {
                    savePromise = LoadSaveService.createSubmission(data);
                    loadingMsg = messages.startingQuote;
                }
                return modalApi.showModal(
                    <WMICProgressModal
                        modalTitle={translator(loadingMsg)}
                        isOpen
                        promise={savePromise}
                    />
                ).then((submission) => {
                    _.set(submissionVM, 'value', submission);
                    updateWizardData(submissionVM);
                    return submissionVM;
                });   
            // eslint-disable-next-line no-else-return
            } else {
                const messageToShow = {
                    title: '',
                    message: ''
                };

                if (response.policyHoldConflict_WMIC) {
                    messageToShow.title = translator(messages.wereSorryTitle);
                    messageToShow.message = WMICRichTextUtil.translateRichText(translator(messages.policyholdMessage));
                } else {
                    messageToShow.title = translator(messages.notCovered);
                    messageToShow.message = translator(messages.notOfferCoverage);
                }

                modalApi.showModal(
                    <WMICModal
                        title={messageToShow.title}
                        message={messageToShow.message}
                        iconClass="error-text fa-3x fa fa-exclamation-circle"
                        shouldCloseOnOverlayClick={false}
                    />
                );

                return false;
            } 
        });
    }, [isComponentValid, setDefaultFieldsBeforeNext, submissionVM, translator, updateWizardData]);

    const defaultCovDate = LocalDateUtil.today(1);
    const minDateOfBirth = new Date(1900, 0, 1);
    let today = new Date();
    today.setHours(0,0,0,0);

    const relationshipOnValueChange = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            const aniValue = YourInfoPageUtil.getANIValueForRelationship(submissionVM);
            _.set(submissionVM, 'baseData.aniperson_WMIC', aniValue);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const onAgreeCheckboxValueChange = (value, path) => {
        writeValue(value, path);
    };

    const onDOBValueChange = (value, path) => {
        const valueInJSDate = new Date(value)
        
        if (YourInfoPageUtil.isDate(valueInJSDate)) {
            const dateObject = {
                year: valueInJSDate.getFullYear(),
                month: valueInJSDate.getMonth(),
                day: valueInJSDate.getDate(),
            };

            setDateOfBirth(value);
            writeValue(dateObject, path);
        }
        else {
            setDateOfBirth(value);
            writeValue(value, path);
        }
    };

    const onDOBANIValueChange = (value, path) => {
        const valueInJSDate = new Date(value)
        if (YourInfoPageUtil.isDate(valueInJSDate)) {
            const dateObject = {
                year: valueInJSDate.getFullYear(),
                month: valueInJSDate.getMonth(),
                day: valueInJSDate.getDate(),
            };

            setDateOfBirthANI(value);
            writeValue(dateObject, path);
        }
        else {
            setDateOfBirthANI(value);
            writeValue(value, path);
        }
    };

    const getDOBValidationMessages = (dob) => {
        if(!dob || !dob.match(VALIDATORS.mmddyyy)) {
            return translator(messages.invalidDate);
        }
        const dateOfBirthInJSDate = new Date(dob);
        if (dateOfBirthInJSDate < minDateOfBirth || dateOfBirthInJSDate >= today) {
            return translator(messages.invalidDate);
        }
        return false;
    };

    const getDOBValueFromSubmissionVM = (path) => {
        const dobValue = _.get(submissionVM, `${path}.value`);
        if (dobValue) {
            return (`${(dobValue.month + 1).toLocaleString(undefined, {
                minimumIntegerDigits: 2,
            })}/${dobValue.day.toLocaleString(undefined, {
                minimumIntegerDigits: 2,
            })}/${dobValue.year}`)
        }
    }

    const showANIField = useCallback(
        () => {
            return YourInfoPageUtil.showANIField(submissionVM);
        }, [submissionVM]
    );

    const setDefaultValues = () => {
        submissionVM.baseData.accountHolder.firstName = 'John';
        submissionVM.baseData.accountHolder.lastName = 'Doe';
        submissionVM.baseData.accountHolder.emailAddress1 = 'jdoe@wawanesa.com';
        submissionVM.baseData.policyAddress.addressLine1 = '100 Fake Street';
        submissionVM.baseData.policyAddress.state = submissionVM.region_WMIC.value;
        submissionVM.baseData.mailingAddress_WMIC.addressLine1 = '200 Mailing Street';
        submissionVM.baseData.mailingAddress_WMIC.state = submissionVM.region_WMIC.value;
        submissionVM.mailAndHomeAddrsEqual = true;
        submissionVM.baseData.insuranceScoreConsent_WMIC = true;

        if (submissionVM.region_WMIC.value.code === CONSTANTS.JURISDICTIONS.CA) {
            submissionVM.baseData.policyAddress.city = 'Pasadena';
            submissionVM.baseData.policyAddress.postalCode = '90210';
            submissionVM.baseData.mailingAddress_WMIC.city = 'Pasadena';
            submissionVM.baseData.mailingAddress_WMIC.postalCode = '90210';
        } else if (submissionVM.region_WMIC.value.code === CONSTANTS.JURISDICTIONS.OR) {
            submissionVM.baseData.policyAddress.addressLine1 = '4975 SE Division St';
            submissionVM.baseData.policyAddress.city = 'Portland';
            submissionVM.baseData.policyAddress.county = 'Multnomah';
            submissionVM.baseData.policyAddress.postalCode = '97206';
            submissionVM.baseData.mailingAddress_WMIC.addressLine1 = '4975 SE Division St';
            submissionVM.baseData.mailingAddress_WMIC.city = 'Portland';
            submissionVM.baseData.mailingAddress_WMIC.county = 'Multnomah';
            submissionVM.baseData.mailingAddress_WMIC.postalCode = '97206';
            
            setDateOfBirth('01/01/1960');
            setDateOfBirthANI('01/01/1960');
            submissionVM.baseData.accountHolder.dateOfBirth = {
                year: 1960,
                month: 1,
                day: 1
            };
            submissionVM.baseData.aniperson_WMIC.dateOfBirth = {
                year: 1960,
                month: 1,
                day: 1
            };
        }
        updateWizardData(submissionVM);
    };

    useEffect(() => {
        _.set(submissionVM, 'value.region_WMIC', submissionVM.baseData.policyAddress.value.state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.baseData.policyAddress.value.state]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const emailIsEmpty = () => {
        const emailValue = _.get(submissionVM, 'baseData.accountHolder.emailAddress1.value');
        return !emailValue;
    }

    const emailIsInvalid = () => {
        const emailValue = _.get(submissionVM, 'baseData.accountHolder.emailAddress1.value');
        const re = /\S+@\S+\.(\S+){2}/;

        if(!re.test(emailValue)) {
            return true;
        }
        return !emailValue;
    }

    useEffect(() => {
        const serverInfo = submissionVM.serverInfo_WMIC.value;
        const now = serverInfo ? new Date(serverInfo.date) : new Date();
        const dateInPolicyLocation = WMICTimezoneUtilService.getDateInPolicyLocationTimeZone(now, 'YYYY-MM-DD');
        const today = `${dateInPolicyLocation}T00:00:00Z`;
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const maximumDate = new Date(today);
        maximumDate.setDate(maxDate.getDate() + 30);
        maximumDate.setUTCHours(23, 59, 59, 999);
        setMaxDate(maximumDate);
        setMinDate(tomorrow);

        const longDateInPolicyLocation = WMICTimezoneUtilService.getDateInPolicyLocationTimeZone(now, 'MMMM D, YYYY');
        const maxEffectiveDate = new Date(`${longDateInPolicyLocation} 00:00:00`);
        maxEffectiveDate.setDate(maxEffectiveDate.getDate() + 30);
        maxEffectiveDate.setHours(23, 59, 59, 999);

        const tomorrowInServerTime = WMICTimezoneUtilService.toServerTimeZone(
            tomorrow,
            serverInfo.timezoneOffset,
            serverInfo.inDaylightTime
        );

        submissionVM.baseData.minEffectiveDate_WMIC.value = tomorrowInServerTime;
        submissionVM.baseData.maxEffectiveDate_WMIC.value = maxEffectiveDate;

        updateWizardData(submissionVM);

        if (_.get(submissionVM, 'dateHasChanged_WMIC.value')) {
            const newDateTime = LocalDateUtil.toMidnightDate(_.get(submissionVM, 'baseData.periodStartDate.value'));
            const newDate = dayjs(newDateTime).format('MMMM D, YYYY');
            const message = <FormattedMessage
                {...messages.dateChangedModalMessage}
                values={{
                    updatedEffectiveDate: (
                        <b>
                            {newDate}
                        </b>
                    )
                }}
            />
            modalApi.showModal(
                <WMICModal
                    title={messages.dateChangedModalTitle}
                    message={message}
                    iconClass="error-text fa-3x fa fa-exclamation-circle"
                />
            ).then(
                () => {
                    submissionVM.dateHasChanged_WMIC = null;
                    updateWizardData(submissionVM);
                    scrollToTop();
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const skipPageWhen = useCallback(() => {
        return new Promise(((resolve) => { resolve(false || retrieveContext.sameDayESignatureExpiry || retrieveContext.isQuoteDataDefined); }));
    }, [retrieveContext]);

    const aniRelationshipValue = _.get(submissionVM, 'baseData.addlNamedInsuredRelationship.value.code');

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top'
        },
        defaultValuesButton: {
            visible: defaultValuesEnabled === 'true'
        },
        addressLookup: {
            stateField: {
                readOnly: true
            },
            showErrors: showErrors,
            model: submissionVM
        },
        mailingAddresslookup: {
            visible: (_.get(submissionVM, 'mailAndHomeAddrsEqual') === false),
            showErrors: (_.get(submissionVM, 'mailAndHomeAddrsEqual') === false) && showErrors,
            model: submissionVM,
            stateField: {
                readOnly: false
            }
        },
        additionalNamedInsuredContainer: {
            visible: (_.get(submissionVM, 'baseData.hasAddlNamedInsured.value') === true)
        },
        aniAddresslookup: {
            visible: (_.get(submissionVM, 'isANIAddressEqualToPNIAddress') === false),
            model: submissionVM
        },
        otherDescription: {
            visible: (_.get(submissionVM, 'baseData.addlNamedInsuredOccupationType_WMIC.value.code') === CONSTANTS.OTHER)
        },
        oregonDOB: {
            visible: (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR),
            required: (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR),
            value: dateOfBirth || getDOBValueFromSubmissionVM('baseData.accountHolder.dateOfBirth'),
            onValueChange: (value, path) => { onDOBValueChange(value, path) },
            onGetValidationMessages: () => {getDOBValidationMessages(dateOfBirth)},
            className: styles.wmicOregonDOB
        },
        dobContainerDiv: {
            visible: (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR)
        },
        addlNamedInsuredDobContainerDiv: {
            visible: (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR)
        },
        insuranceScoreContainer: {
            visible: (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR)
        },
        addlNamedInsuredFirstName: {
            visible: showANIField()
        },
        addlNamedInsuredLastName: {
            visible: showANIField()
        },
        addlNamedInsuredOregonDOB: {
            visible: (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR
                && showANIField()),
            required: (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR
                && showANIField()),
            value: dateOfBirthANI || getDOBValueFromSubmissionVM('baseData.aniperson_WMIC.dateOfBirth'),
            onValueChange: (value, path) => { onDOBANIValueChange(value, path) },
            onGetValidationMessages: () => {getDOBValidationMessages(dateOfBirthANI)},
            className: styles.wmicOregonDOB
        },
        otherRelationshipError: {
            visible: (aniRelationshipValue === CONSTANTS.UNKNOWN)
        },
        relationshipDropdown: {
            onValueChange: (value) => relationshipOnValueChange(value, 'baseData.addlNamedInsuredRelationship'),
            className: aniRelationshipValue === CONSTANTS.UNKNOWN ? 'wmicWizardInput wmicInputPosition addlRelationshipInput' : 'wmicWizardInput wmicInputPosition',
            value: submissionVM?.baseData.addlNamedInsuredRelationship.value?.code,
            availableValues: submissionVM?.baseData.addlNamedInsuredRelationship.aspects.availableValues,
            validationMessages: !!!aniRelationshipValue ? [translator(messages.relationshipToPNIValidationMessage)]: new Array(),
        },
        periodStartDate: {
            minDate: minDate,
            maxDate: maxDate,
            onBlur: onDateValueChange
        },
        primaryNamedInsuredText: {
            content: WMICVariousUtil.getTextWithIcon('fa fa-question-circle wmicIconBig', translator(messages.thePrimaryNamedInsured))
        },
        insuranceScoreText: {
            content: translator(messages.theInsuranceScore)
        },
        agreeCheckboxContainer: {
            showErrors: false
        },
        emailAddress: {
            ...(emailIsEmpty() && {
                validationMessages: [
                    translator(messages.noEmail)
                ]
            }),
            ...(!emailIsEmpty() && emailIsInvalid() && {
                validationMessages: [
                    translator(messages.invalidEmail)
                ]
            })
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: onValidate,
            onSetDefaultValues: setDefaultValues,
            onAgreeCheckboxValueChange
        },
        resolveComponentMap: {
            wmicInput: WMICCustomInput,
            wmicAddressLookupComponent: WMICAddressLookupComponent,
            wmicCustomDropdownSelect: WMICCustomDropdownSelect,
            wmicSwitchIconTooltip: WMICSwitchIconTooltip,
            wmicCustomRadioButton: WMICCustomRadioButton,
            wmicCustomTooltip: WMICCustomTooltip,
            wmicDatePicker: WMICDatePicker,
            WMICButton
        },
        resolveClassNameMap: styles
    };

    useEffect(() => {
        if (_.isUndefined(submissionVM.baseData.accountHolder.value)) {
            submissionVM.baseData.accountHolder.value = {
                subtype: CONSTANTS.Person
            };
        }

        if (submissionVM.baseData.hasAddlNamedInsured.value && _.isUndefined(submissionVM.baseData.aniperson_WMIC.value)) {
            submissionVM.baseData.aniperson_WMIC.value = {};
        }
        
        if (_.isUndefined(submissionVM.baseData.mailingAddress_WMIC.value)) {
            submissionVM.baseData.mailingAddress_WMIC.value = {
                zipCodeMatchesCity: true
            };
            _.extend(submissionVM.baseData.mailingAddress_WMIC.value, submissionVM.baseData.policyAddress.value);
        }

        if (_.isUndefined(submissionVM.baseData.addlNamedInsuredAddress.value)) {
            submissionVM.baseData.addlNamedInsuredAddress.value = {};
            _.extend(submissionVM.baseData.addlNamedInsuredAddress.value, submissionVM.baseData.policyAddress.value);
        }

        if (_.isUndefined(submissionVM.baseData.periodStartDate.value)) {
            submissionVM.baseData.periodStartDate.value = defaultCovDate;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={skipPageWhen}
            showPrevious={false}
            showEmailQuoteButton={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

YourInfoPage.propTypes = wizardProps;
export default YourInfoPage;