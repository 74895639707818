import { defineMessages } from 'react-intl';

export default defineMessages({
    email: {
        id: 'quoteandbind.views.recurring-payment-authorization.Email',
        defaultMessage: 'Email'
    },
    emailConfirm: {
        id: 'quoteandbind.views.recurring-payment-authorization.Confirm Email',
        defaultMessage: 'Confirm Email'
    },
    emailsNotMatch: {
        id: 'quoteandbind.views.recurring-payment-authorization.Addresses not matching',
        defaultMessage: "Addresses don't match"
    },
    saveAndSend: {
        id: 'quoteandbind.views.recurring-payment-authorization.Save and Send',
        defaultMessage: 'Save and Send'
    },
    cancel: {
        id: 'quoteandbind.views.recurring-payment-authorization.Cancel',
        defaultMessage: 'Cancel'
    },
    alert: {
        id: 'quoteandbind.views.recurring-payment-authorization.Alert',
        defaultMessage: 'Alert'
    },
    updatedEmail: {
        id: 'quoteandbind.views.recurring-payment-authorization.All future correspondence and electronic policy documents will be sent to the updated email address',
        defaultMessage: 'All future correspondence and electronic policy documents will be sent to the updated email address. Do you wish to proceed?'
    },
    continue: {
        id: 'quoteandbind.views.recurring-payment-authorization.Continue',
        defaultMessage: 'Continue'
    }
});