/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { WMICProgressModal } from 'gw-capability-quoteandbind-common-react';
import messages from './WMICPaymentCallback.messages';

function WMICPaymentCallback() {
    const { search } = useLocation();
    const history = useHistory();
    const translator = useTranslator();

    const queryStrings = new URLSearchParams(search);
    const postalCode = queryStrings.get('postalCode');
    const quoteID = queryStrings.get('quoteID');
    const email = queryStrings.get('email');
    const paymentID = queryStrings.get('paymentID');

    const gotoRetrieveQuote = () => {
        const nextState = {
            pathname: '/retrieve-quote',
            state: {
                postalCode: postalCode,
                quoteID: quoteID,
                email: email,
                paymentCallback: true
            }
        };
        history.push(nextState);
    }

    useEffect(() => {
        if (quoteID !== undefined && postalCode !== undefined && email !== undefined && paymentID !== undefined) {
            gotoRetrieveQuote();
        } else {
            history.push('/');
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WMICProgressModal
            modalTitle={translator(messages.returningToWawanesa)}
            isOpen
        />
    );
}

export default WMICPaymentCallback;