import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import SLQYourVehiclePage from './pages/YourVehicle/YourVehiclePage';
import SLQYourLocationPage from './pages/YourLocation/YourLocationPage';
import SLQYourAgePage from './pages/YourAge/YourAgePage';
import SLQYourQuotePage from './pages/YourQuote/YourQuotePage';

setComponentMapOverrides(
    {
        SLQYourVehiclePage: { component: 'SLQYourVehiclePage' },
        SLQYourLocationPage: { component: 'SLQYourLocationPage' },
        SLQYourAgePage: { component: 'SLQYourAgePage' },
        SLQYourQuotePage: { component: 'SLQYourQuotePage' }
    },
    {
        SLQYourVehiclePage,
        SLQYourLocationPage,
        SLQYourAgePage,
        SLQYourQuotePage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as SLQWizard } from './SLQWizard';
