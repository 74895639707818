import React, { useContext } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Icon, Tooltip } from '@jutro/components';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import DynamicContentUtil from '../../util/DynamicContentUtil';
import styles from './GTPPreviousSteps.module.scss';

import { Button } from '@jutro/legacy/components';

function getAddressDisplayName(address) {
    return `${address.addressLine1}, ${address.city}, ${address.state}, ${address.postalCode}`;
}

function getArrayIcons(stepData) {
    const icons = stepData
        .filter((data) => data.answer)
        .map((data) => (
            <Tooltip
                id={`previousStepID_${data.code}`}
                placement="bottom"
                trigger="mouseenter"
                content={data.text}
            >
                <div className={styles.iconContainer}>
                    <Icon
                        className={styles.icons}
                        icon={DynamicContentUtil.getQuestionIcon(data.code)}
                    />
                </div>
            </Tooltip>
        ));
    return <div className={styles.arrayIconContainer}>{icons}</div>;
}

function renderSteps(translator, steps, currentStepIndex, stepsWithErrors, jumpTo, wizardData) {
    return steps
        .filter((step, index) => index < currentStepIndex)
        .map((step, index) => {
            const stepData = _.get(wizardData, step.stepProps.path);
            const buttonClassName = classNames('actionIcon', {
                [styles.active]: index === currentStepIndex
            });
            let previousStepData = stepData;

            if (step.stepProps.path === 'userAddress.value') {
                previousStepData = getAddressDisplayName(stepData);
            } else if (_.isArray(stepData)) {
                previousStepData = getArrayIcons(stepData);
            }

            return (
                <div key={step.id} className={styles.stepContainer}>
                    <div>
                        <span>{translator(step.title)}</span>
                        <span className={styles.stepValue}>{previousStepData}</span>
                    </div>
                    <Button
                        aria-label={translator(step.title)}
                        className={buttonClassName}
                        onClick={() => jumpTo(index)}
                        type="outlined"
                        icon="mi-edit"
                    />
                </div>
            );
        });
}

function GTPPreviousSteps() {
    const wizardContext = useContext(WizardContext);
    const translator = useTranslator();
    const {
        steps, currentStepIndex, jumpTo, stepsWithErrors, wizardData
    } = wizardContext;

    return (
        <div className={styles.stepsNav} role="navigation">
            {renderSteps(translator, steps, currentStepIndex, stepsWithErrors, jumpTo, wizardData)}
        </div>
    );
}

export default GTPPreviousSteps;
