import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { QuickQuoteWizardPageTemplate } from 'gw-capability-quoteandbind-common-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuickQuoteService } from 'gw-capability-quoteandbind';
import { MockUpUtil } from 'wmic-portals-utils-js';
import './YourAgePage.messages';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import styles from './YourAgePage.module.scss';
import metadata from './YourAgePage.metadata.json5';

function removeMockData(data) {
    return MockUpUtil.cleanUpMockedProperties(
        data,
        'quote.slq',
        'baseData.accountHolder.emailAddress1'
    );
}

function YourAgePage(props) {
    const { wizardData, updateWizardData } = props;
    const { submissionVM } = wizardData;
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation(
        'YourAgePage'
    );
    const onNext = useCallback(async () => {
        const quoteData = await QuickQuoteService.createSubmission(submissionVM.value);
        const cleanedData = removeMockData(quoteData);
        _.set(wizardData, 'quoteVM.value', cleanedData);
        updateWizardData(wizardData);
        return wizardData;
    }, [submissionVM, updateWizardData, wizardData]);

    const resolvers = {
        resolveClassNameMap: styles
    };

    const isTermChecked = useCallback(() => {
        return _.get(submissionVM, 'termsChecked.value') === true;
    }, [submissionVM]);

    useEffect(() => {
        registerComponentValidation(isTermChecked);
    }, [isTermChecked, registerComponentValidation]);

    const handleModelChange = useCallback((newModel) => {
        updateWizardData({
            ...wizardData,
            submissionVM: newModel
        });
    }, [updateWizardData, wizardData]);

    return (
        <WizardPage
            template={QuickQuoteWizardPageTemplate}
            disableNext={!isComponentValid}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={handleModelChange}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

YourAgePage.propTypes = wizardProps;
export default YourAgePage;
