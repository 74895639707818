import React, { useCallback } from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { WMICSidebarBoxes } from 'gw-portals-wizard-components-ui';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { WMICRegionUrlUtil } from 'wmic-portals-url-js';
import { ERROR_CODE, WMICRichTextUtil } from 'wmic-portals-utils-js';
import styles from './WMICContactUs.module.scss';
import metadata from './WMICContactUs.metadata.json5';
import messages from './WMICContactUs.messages';

function WMICContactUs(props) {
    const { location, match } = props;
    const translator = useTranslator();
    const { phoneNumber, phoneNumberRaw } = appConfig;

    const quoteID = _.get(location, 'state.quoteID');

    const errorMessages = _.get(location, 'state.declineMessages');

    const currentRegion = localStorage.getItem(WMICRegionUrlUtil.CONSTANTS.REGION);

    let callSpecialistsMessage;
    let fairPlan;

    switch (currentRegion) {
        case WMICRegionUrlUtil.CONSTANTS.CA_SHORT:
            callSpecialistsMessage = WMICRichTextUtil.translateRichText(translator(messages.callWithNumCA, {phoneNumberRaw, phoneNumber}));
            fairPlan = WMICRichTextUtil.translateRichText(translator(messages.calFP));
            break;
        case WMICRegionUrlUtil.CONSTANTS.OR_SHORT:
        default:
            callSpecialistsMessage = WMICRichTextUtil.translateRichText(translator(messages.callWithNumOR, {phoneNumberRaw, phoneNumber}));
            fairPlan = WMICRichTextUtil.translateRichText(translator(messages.orFP));
    }

    const {
        params: { displayMode }
    } = match;

    const getAssistanceText = useCallback(() => {
        return (
            <FormattedMessage
                {...messages.furtherAssist}
                values={{
                    link: <Link to='/retrieve-quote'>{translator(messages.furtherAssistLink)}</Link>,
                    phoneNumber
                }}
            />
        );
    }, [phoneNumber, translator]);

    const overrideProps = {
        decline: {
            visible: displayMode === ERROR_CODE.DECLINE
        },
        contactMain: {
            visible: displayMode === ERROR_CODE.UW_ISSUE
                || displayMode === ERROR_CODE.CLUE_REPORT
                || displayMode === ERROR_CODE.PORTAL_VALIDATION
        },
        contact2: {
            content: translator(messages.specialSolution, { phoneNumber })
        },
        techErrorMain: {
            visible: displayMode === ERROR_CODE.TECHNICAL_ERROR
            || displayMode === ERROR_CODE.TECHNICAL_ERROR_UPDATE_QUOTE
            || displayMode === ERROR_CODE.TECHNICAL_ERROR_RETRIEVE_QUOTE
            || displayMode === ERROR_CODE.TECHNICAL_ERROR_CREATE_QUOTE
        },
        technicalError: {
            visible: displayMode === ERROR_CODE.TECHNICAL_ERROR
                || displayMode === ERROR_CODE.TECHNICAL_ERROR_RETRIEVE_QUOTE
                || displayMode === ERROR_CODE.TECHNICAL_ERROR_CREATE_QUOTE
                || displayMode === ERROR_CODE.TECHNICAL_ERROR_UPDATE_QUOTE
        },
        invalidSession: {
            visible: displayMode === ERROR_CODE.INVALID_SESSION
        },
        techErrorMessage1: {
            visible: displayMode === ERROR_CODE.TECHNICAL_ERROR
        },
        techErrorMessage2: {
            visible: displayMode === ERROR_CODE.TECHNICAL_ERROR_RETRIEVE_QUOTE
            || displayMode === ERROR_CODE.TECHNICAL_ERROR_CREATE_QUOTE
            || displayMode === ERROR_CODE.TECHNICAL_ERROR_UPDATE_QUOTE
        },
        techError: {
            visible: displayMode === ERROR_CODE.TECHNICAL_ERROR
            || displayMode === ERROR_CODE.TECHNICAL_ERROR_UPDATE_QUOTE
            || displayMode === ERROR_CODE.TECHNICAL_ERROR_RETRIEVE_QUOTE,
            content: translator(messages.callTechError, { phoneNumber })
        },
        techErrorCreate: {
            visible: displayMode === ERROR_CODE.TECHNICAL_ERROR_CREATE_QUOTE,
            content: translator(messages.callTechErrorCreate, { phoneNumber })
        },
        quoteSaved: {
            visible: !_.isNil(quoteID),
            content: translator(messages.quoteSaved, { quoteId: quoteID })
        },
        declineQuoteId: {
            visible: !_.isNil(quoteID),
            content: translator(messages.quoteIs, { quoteId: quoteID })
        },
        contactQuoteId: {
            visible: !_.isNil(quoteID),
            content: translator(messages.quoteIs, { quoteId: quoteID })
        },
        unavailable: {
            visible: displayMode === ERROR_CODE.QNB_UNAVAILABLE
        },
        callAgent: {
            content: WMICRichTextUtil.translateRichText(translator(messages.callAgent))
        },
        emailUs: {
            content: WMICRichTextUtil.translateRichText(translator(messages.emailUs))
        },
        callSpecialists: {
            content: callSpecialistsMessage
        },
        declineMessage: {
            visible: !_.isEmpty(errorMessages),
            content: errorMessages
        },
        fairPlan: {
            content: fairPlan
        },
        callOrRetrieveQuote: {
            content: getAssistanceText()
        }
    };

    const resolvers = {
        resolveComponentMap: {
            wmicSidebarBoxes: WMICSidebarBoxes,
        },
        resolveClassNameMap: styles
    };

    return (
        <div>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers} />
        </div>
    );
}

WMICContactUs.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            error: PropTypes.shape({
                gwInfo: PropTypes.shape({})
            })
        })
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            displayMode: PropTypes.string
        })
    })
};
WMICContactUs.defaultProps = {
    location: undefined,
    match: {
        params: {
            displayMode: 'invalid'
        }
    }
};
export default withRouter(WMICContactUs);
