import { defineMessages } from 'react-intl';

export default defineMessages({
    continue: {
        id: 'guidance.views.guidance-product.Continue',
        defaultMessage: 'Continue'
    },
    yourBusiness: {
        id: 'guidance.directives.occupation.Your business:',
        defaultMessage: 'Your business:'
    },
    yourAddress: {
        id: 'guidance.directives.address.Your address:',
        defaultMessage: 'Your address:'
    },
    youveSelectedTheFollowing: {
        id: 'guidance.directives.recommendations.You\'ve selected the following:',
        defaultMessage: 'You\'ve selected the following:'
    },
    basedOnWhatYouToldUs: {
        id: 'guidance.directives.recommendations.Based on what you told us, you could consider...',
        defaultMessage: 'Based on what you told us, you could consider...'
    },
});
