/* eslint-disable max-len */
/* eslint-disable no-throw-literal */
import React, {
    useContext, useState, useEffect, useCallback, useRef
} from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICCheckbox } from 'wmic-components-platform-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import {
    LocalDateUtil,
    ERROR_CODE,
    PAYMENT_METHOD,
    CONSTANTS,
    useDeferredPromise,
    WMICRichTextUtil,
    WMICFeatureFlagUtil
} from 'wmic-portals-utils-js';
import {
    WMICModal,
    WMICProgressModal
} from 'gw-capability-quoteandbind-common-react';
import { WMICOneIncPaymentModalQnB } from 'gw-capability-quoteandbind-ho-react';

import metadata from './WMICRecurringPaymentDetails.metadata.json5';
import messages from './WMICRecurringPaymentDetails.messages';
import styles from './WMICRecurringPaymentDetails.module.scss';

dayjs.extend(utc);

const getCustomQuote = (vm) => {
    const lobCoverages = _.get(vm, 'lobData.homeowners.offerings.value[0].coverages');
    const quoteOffering = _.get(vm, 'quoteData.offeredQuotes.value[0]');

    return {
        quote: quoteOffering,
        quoteID: vm.quoteID.value,
        sessionUUID: vm.sessionUUID.value,
        periodStart: vm.baseData.periodStartDate.value,
        periodEnd: vm.baseData.periodEndDate.value,
        coverages: lobCoverages
    };
};

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function WMICRecurringPaymentDetails(props) {
    const modalApi = useModal();
    const { wizardData: submissionVM, updateWizardData, finish, jumpTo } = props;
    const history = useHistory();
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const translator = useTranslator();
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const viewModelService = useContext(ViewModelServiceContext);

    const [isPageInitialized, setPageInitialized] = useState(false);
    const [paymentErrors, setPaymentErrors] = useState([]);
    const [showErrors, setShowErrors] = useState(false);
    const [consentedToMAR, setConsentedToMAR] = useState(_.get(submissionVM.value, 'pendingMARConsent_WMIC', true) === false);
    const [showConsentRequiredMessage, setShowConsentRequiredMessage] = useState(false);
    const [featureFlags, setFeatureFlags] = useState({});
    const [isOneIncFeatureFlagAvailable, setIsOneIncFeatureFlagAvailable] = useState(false);
    const [makingOneIncPayment, updateMakingOneIncPayment] = useState(false);
    const [oneIncPaymentDetails, updateOneIncPaymentDetails] = useState({});
    const [oneIncSavePaymentEnabled, setOneIncSavePaymentEnabled] = useState(false);
    const isOneIncPaymentMethodSaveCompleteRef = useRef(false);
    const { defer: deferOneIncLoadingPromise, deferRef: deferOneIncLoadingPromiseRef } = useDeferredPromise();

    const baseState = _.get(submissionVM, 'baseData.policyAddress.value.state');
    const serverInfo = _.get(submissionVM, 'serverInfo_WMIC.value');

    const featureFlagsTypes = WMICFeatureFlagUtil.getFeatureFlags();

    const initFeatureFlags = useCallback(async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const fFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setFeatureFlags(fFlags);
    }, [authUserData]);

    useEffect(() => {
        const params = {
            featureName: featureFlagsTypes.ONEINCPAYMENTS,
            regionCode: baseState,
            serverInfo
        };
        const oneIncAvailability = WMICFeatureFlagUtil.queryAvailabilityQB(featureFlags, params);
        setIsOneIncFeatureFlagAvailable(oneIncAvailability?.isAvailable);
    }, [featureFlags, baseState, serverInfo, featureFlagsTypes]);

    useEffect(() => {
        initFeatureFlags();
    }, [initFeatureFlags]);

    const updatePremium = useCallback(() => {
        const customQuote = getCustomQuote(submissionVM);
        const operation = CustomQuoteService.updateCustomQuote(customQuote, authHeader);
        operation.then(() => {
            const saveSubmissionPromise = LoadSaveService.updateQuotedSubmission(
                submissionVM.value,
                authHeader
            );
            saveSubmissionPromise.then((submission) => {
                submissionVM.value = submission;
                updateWizardData(submissionVM);
            });
        });
        operation.catch((error) => {
            const errorObj = error.baseError.data.error;
            if (errorObj && errorObj.data) {
                const appErrorCode = errorObj.data.appErrorCode.valueOf();
                switch (appErrorCode) {
                    case 601:
                    case 602:
                    case 603:
                    case 606:
                    case 608:
                    case 609:
                    case 610:
                        throw { quoteID: submissionVM.quoteID.value, code: ERROR_CODE.TECHNICAL_ERROR_UPDATE_QUOTE };
                    case 600:
                        throw { quoteID: submissionVM.quoteID.value, code: ERROR_CODE.INVALID_SESSION };
                    default:
                        throw { quoteID: submissionVM.quoteID.value, code: ERROR_CODE.TECHNICAL_ERROR };
                }
            } else {
                throw { quoteID: submissionVM.quoteID.value, code: ERROR_CODE.TECHNICAL_ERROR };
            }
        });
    }, [CustomQuoteService, LoadSaveService, submissionVM, authHeader, updateWizardData]);

    useEffect(() => {
        const filteredErrors = _.filter(submissionVM.value.systemMessages_WMIC, (msg) => {
            return msg.validationExceptionType === ERROR_CODE.PAYMENT || msg.validationExceptionType === ERROR_CODE.OTWCONSENT;
        });
        setPaymentErrors(filteredErrors);

        submissionVM.bindData.paymentDetails.value = _.get(submissionVM, 'bindData.paymentDetails.value', {});
        submissionVM.bindData.paymentDetails.paymentMethod.value = _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value', '');
        submissionVM.bindData.paymentDetails.tokenId.value = _.get(submissionVM, 'bindData.paymentDetails.tokenId.value', '');
        submissionVM.bindData.paymentDetails.bankAccountData.value = _.get(submissionVM, 'bindData.paymentDetails.bankAccountData.value', {});
        submissionVM.bindData.isRecordEmailDistributionConsent_WMIC = false;

        const { addlNamedInsured } = submissionVM.baseData;
        const hasDapForNotification = submissionVM.bindData.hasDapForNotification_WMIC !== undefined ? submissionVM.bindData.hasDapForNotification_WMIC.value : false;
        if (hasDapForNotification) {
            submissionVM.bindData.dapPerson_WMIC.emailAddress1 = submissionVM.bindData.dapPersonEmailAddress_WMIC;
        }
        if (addlNamedInsured) {
            addlNamedInsured.emailAddress1 = submissionVM.baseData.addlNamedInsuredEmailAddress1;
        }

        submissionVM.bindData.paymentDetails.paymentMethod.value = PAYMENT_METHOD.AUTO_RECURRING_ACCOUNT_INFO;
        const newSubmissionVM = viewModelService.clone(submissionVM);
        updateWizardData(newSubmissionVM);

        if (_.get(submissionVM, 'dateHasChanged_WMIC.value')) {
            const newDateTime = LocalDateUtil.toMidnightDate(_.get(submissionVM, 'baseData.periodStartDate.value'));
            const newDate = dayjs(newDateTime).format('MMMM D, YYYY');
            const message = WMICRichTextUtil.translateRichText(translator(messages.dateChangedModalMessage, { updatedEffectiveDate: newDate }));

            modalApi.showModal(
                <WMICModal
                    title={messages.dateChangedModalTitle}
                    message={message}
                    parseMessage
                    jumpTo={jumpTo}
                    iconClass="error-text fa-3x fa fa-exclamation-circle"
                />
            ).then(
                () => {
                    submissionVM.dateHasChanged_WMIC = null;
                    updateWizardData(submissionVM);
                    scrollToTop();
                    updatePremium(); // re-quote as date change might affect the rate
                }
            );
        }
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(submissionVM.value.systemMessages_WMIC)]);

    const openOneInc = useCallback(() => {
        const oneIncDetails = {
            submission: submissionVM,
            userDisplayName: _.get(submissionVM, 'bindData.payerFullName_WMIC.value'),
            email: _.get(submissionVM, 'bindData.paymentDetails.bankAccountData.email_WMIC.value'),
            payerPublicID: _.get(submissionVM, 'bindData.payerPublicID_Ext.value', undefined),
            bankAccountFirstName: _.get(submissionVM, 'bindData.payerFirstName_Ext.value'),
            bankAccountLastName: _.get(submissionVM, 'bindData.payerLastName_Ext.value')
        };
       
        updateOneIncPaymentDetails(oneIncDetails);
        setOneIncSavePaymentEnabled(true);
        updateMakingOneIncPayment(true);
        modalApi.showModal(
            <WMICProgressModal
                modalTitle={translator(messages.loadingOneInc)}
                isOpen
                promise={deferOneIncLoadingPromise().promise}
            />
        )
    }, [submissionVM, translator, deferOneIncLoadingPromise]);

    const paymentModalLoaded = useCallback(() => {
        deferOneIncLoadingPromiseRef?.current?.resolve();
    }, [deferOneIncLoadingPromiseRef]);

    const paymentModalClosed = useCallback(() => {
        deferOneIncLoadingPromiseRef?.current?.resolve();
        updateMakingOneIncPayment(false);
        if (isOneIncPaymentMethodSaveCompleteRef.current && consentedToMAR) {
            const bindPromise = LoadSaveService.bindSubmission(submissionVM.value);
            return modalApi.showModal(<WMICProgressModal modalTitle={translator(messages.validationgBankingInfo)} promise={bindPromise} />)
                .then((submission) => {
                    _.set(submissionVM, 'value', submission);
                    updateWizardData(submissionVM);
                    finish();
                    return submissionVM;
                })
                .catch((error) => {
                    if (error.code === ERROR_CODE.DAY_TRANSITION) {
                        updatePremium();
                    } else if (error.code === ERROR_CODE.CONSENT) {
                        setConsentedToMAR(false);
                        _.set(submissionVM.value, 'pendingMARConsent_WMIC', true);
                    }
                    throw error;
                });
        }
        
    }, [deferOneIncLoadingPromiseRef, consentedToMAR, LoadSaveService,
        submissionVM, translator, updateWizardData, updatePremium, finish]);

    const oneIncSaveComplete = useCallback(({ details }) => {
        isOneIncPaymentMethodSaveCompleteRef.current = true;
        _.set(submissionVM, 'bindData.paymentDetails.tokenId.value', details.tokenId);
        _.set(submissionVM, 'bindData.paymentDetails.lastFourDigits.value', `****${details.lastFourDigits}`);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const gotoContactUs = useCallback((displayMode) => {
        const nextState = {
            pathname: `/contact-us/${displayMode}`,
            state: {
                quoteID: submissionVM.value.quoteID
            }
        };
        history.push(nextState);
    }, [submissionVM, history]);

    const gotoPolicyInProcess = useCallback(() => {
        history.push({
            pathname: '/policyinprocess',
            search: `?quoteID=${submissionVM.value.quoteID}`
        });
    }, [submissionVM, history]);

    const paymentError = useCallback((redirectPage) => {
        deferOneIncLoadingPromiseRef?.current?.reject();
        if (redirectPage === 'policyInProcess') {
            return gotoPolicyInProcess();
        }
        return gotoContactUs(ERROR_CODE.TECHNICAL_ERROR);
    }, [deferOneIncLoadingPromiseRef, gotoPolicyInProcess, gotoContactUs]);

    const makeOneIncPayment = useCallback(() => {
        if (!consentedToMAR) {
            setShowConsentRequiredMessage(true);
        } else {
            _.set(submissionVM.value, 'pendingMARConsent_WMIC', false);
        }

        if (!submissionVM.bindData.aspects.subtreeValid) {
            setShowErrors(true);
            return;
        }

        if (consentedToMAR) {
            openOneInc();
        }
    }, [submissionVM, consentedToMAR, openOneInc]);

    const onNext = useCallback(() => {
        if (!consentedToMAR) {
            setShowConsentRequiredMessage(true);
        } else {
            _.set(submissionVM.value, 'pendingMARConsent_WMIC', false);
        }

        if (!submissionVM.bindData.aspects.subtreeValid) {
            setShowErrors(true);
            return false;
        }

        if (consentedToMAR) {
            const bindPromise = LoadSaveService.bindSubmission(submissionVM.value);
            return modalApi.showModal(<WMICProgressModal modalTitle={translator(messages.validationgBankingInfo)} promise={bindPromise} />)
                .then((submission) => {
                    _.set(submissionVM, 'value', submission);
                    updateWizardData(submissionVM);
                    return submissionVM;
                }).catch((error) => {
                    if (error.code === ERROR_CODE.DAY_TRANSITION) {
                        updatePremium();
                    } else if (error.code === ERROR_CODE.CONSENT) {
                        setConsentedToMAR(false);
                        _.set(submissionVM.value, 'pendingMARConsent_WMIC', true);
                    }
                    throw error;
                });
        }
        return false;
    }, [LoadSaveService, submissionVM, translator, updatePremium, updateWizardData, consentedToMAR]);

    const handleConsentedToMARClick = (value) => {
        // hide the message if it was shown and the checkbox was just checked
        if (showConsentRequiredMessage === true && value === true) {
            setShowConsentRequiredMessage(false);
        }
        setConsentedToMAR(value);
    };

    const resolvers = {
        resolveComponentMap: {
            wmicCheckbox: WMICCheckbox
        },
        resolveClassNameMap: styles
    };

    const overrideProps = {
        paymentErrorsContainer: {
            className: paymentErrors.length > 0 ? 'control-group error ' : 'control-group'
        },
        paymentErrorsIterable: {
            data: paymentErrors
        },
        marAuthorizationCheckbox: {
            label: translator(messages.iAgreeLabel, { fullName: _.get(submissionVM.value, 'bindData.payerFullName_WMIC', '') }),
            onValueChange: handleConsentedToMARClick,
            value: consentedToMAR
        },
        marAuthorizationError: {
            visible: showConsentRequiredMessage
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            onNext={onNext}
            nextLabel={messages.purchaseNow}
            finish
            showEmailQuoteButton={false}
            showQuoteDisclaimer={false}
            customWizardButton={{
                callbackFunction: makeOneIncPayment,
                enabled: isOneIncFeatureFlagAvailable,
                label: messages.purchaseNow,
                hideWizardNextButton: isOneIncFeatureFlagAvailable
            }}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
            {makingOneIncPayment && (
                <WMICOneIncPaymentModalQnB
                    paymentModalClosed={paymentModalClosed}
                    paymentDetails={oneIncPaymentDetails}
                    payNowEnabled={false}
                    savePaymentEnabled={oneIncSavePaymentEnabled}
                    saveComplete={oneIncSaveComplete}
                    paymentModalLoaded={paymentModalLoaded}
                    paymentError={paymentError}
                />
            )}
        </WizardPage>
    );
}

WMICRecurringPaymentDetails.propTypes = wizardProps;
export default WMICRecurringPaymentDetails;