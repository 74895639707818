import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import { MetadataContent } from '@jutro/legacy/uiconfig';

import sunsetSrc from './images/house_sunset.jpg';
import intersectionSrc from './images/intersection.jpg';
import streetSrc from './images/sf_street3.jpg';
import trafficSrc from './images/traffic.jpg';

import styles from './CustomLandingPage.module.scss';
import metadata from './CustomLandingPage.metadata.json5';
import messages from './CustomLandingPage.messages';

import React from 'react';

const data = [
    {
        src: sunsetSrc,
        name: 'Sunset',
        text: {
            header: messages.thisIsStrapline,
            description: messages.thisIsTheSublineAboutInsurance
        }
    },
    {
        src: intersectionSrc,
        name: 'Intersection',
        text: {
            header: messages.slideTitle,
            description: messages.loremIpsum
        }
    },
    {
        src: streetSrc,
        name: 'Street',
        text: {
            header: messages.titleGoesHere,
            description: messages.loremIpsumDolor
        }
    },
    {
        src: trafficSrc,
        name: 'Traffic',
        text: {
            header: messages.slideTitle,
            description: messages.loremIpsum
        }
    }
]

export default function CustomLandingPage() {
    const overrides = {
        firstLayer: {
            data: data
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            carousel: Carousel
        }
    };
    return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
}
