/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
// eslint-disable-next-line no-unused-vars
import { Loader, useModal } from '@jutro/components';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { useTranslator } from '@jutro/locale';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import {
    QuoteInfoComponentContext,
    WMICProgressModal,
    WMICModal,
    WMICRetrieveInfoContext
} from 'gw-capability-quoteandbind-common-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { MockUpUtil, CONSTANTS } from 'wmic-portals-utils-js';
import { WMICRegionUrlUtil } from 'wmic-portals-url-js';
import wizardStepToFieldMapping from './config/ho-wizard-step-to-field-mapping.json5';
import messages from './HOWizard.messages';
import HoQuoteInfo from './components/HoQuoteInfo/HoQuoteInfo';
import wmicWizardConfigCA from './config/wmic-ho-wizard-config-CA.json5';
import wmicWizardConfigOR from './config/wmic-ho-wizard-config-OR.json5';


const PATH_TO_MOCK_DATA = 'quote.ho';

function removeMockData(submission) {
    return MockUpUtil.cleanUpMockedProperties(
        submission,
        PATH_TO_MOCK_DATA,
        'baseData.accountHolder.emailAddress1',
        'baseData.policyAddress.addressLine1',
        'baseData.policyAddress.city',
        'baseData.policyAddress.displayName'
    );
}

// Creates an object required to proceed to next page without creating a submission
function createDummyBaseData(address, serverInfo) {
    const state = WMICRegionUrlUtil.getRegion();
    return {
        baseData: {
            productCode: 'HOPHomeowners',
            policyAddress: {
                state: state,
                country: `${address.country}`,
                postalCode: `${address.postalCode}`,
                addressType: 'home',
            },
        },
        serverInfo_WMIC: serverInfo,
    };
}

function HOWizard() {
    const modalApi = useModal();
    const history = useHistory();
    const location = useLocation();
    const { steps, title } = WMICRegionUrlUtil.getRegion() === CONSTANTS.JURISDICTIONS.OR ? wmicWizardConfigOR : wmicWizardConfigCA;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [shouldSkipValidSteps, setShouldSkipValidSteps] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [retrieveInfoProps, setRetrieveInfoProps] = useState({});
    const [cancelButtonClicked, setCancelButtonClicked] = useState(false);

    const { getRegion } = useDependencies('getRegion');

    const sameDayESignatureExpiry = (submission) => {
        const now = new Date();
        const expiryDate = _.get(submission, 'esignatureStatus_WMIC') ? new Date(_.get(submission, 'esignatureStatus_WMIC.expiryDate')) : now;
        return _.get(submission, 'esignatureStatus_WMIC.isExpired', false) && (expiryDate - now > 0);
    };


    useEffect(() => {
        getRegion();
    }, []);

    useEffect(() => {
        const viewModelContext = {
            AccountEmailRequired: true,
            AccountDOBRequired: true
        };

        let submissionPromise;
        let submissionVM;

        if (!viewModelService) {
            if (_.isEmpty(location.search)) {
                history.push('/');
            }

            return;
        }

        if (_.has(location, 'state.address')) {
            const { address, serverInfo } = location.state;
            const dummyBaseData = createDummyBaseData(address, serverInfo);
            submissionVM = viewModelService.create(
                removeMockData(dummyBaseData),
                'pc',
                'wmic.edge.us.capabilities.quote.submission.dto.QuoteDataDTO',
                viewModelContext
            );
            setInitialSubmission(submissionVM);
        } else if (_.has(location, 'state.submission')) {
            const { submission } = location.state;
            setShouldSkipValidSteps(true);
            setRetrieveInfoProps({
                allowNavigationToPaymentDetails: _.get(submission, 'allowNavigationToPaymentDetails'),
                allowNavigationToRecurringPaymentAuthorization: _.get(submission, 'allowNavigationToRecurringPaymentAuthorization'),
                allowNavigationToRecurringPaymentDetails: _.get(submission, 'allowNavigationToRecurringPaymentDetails'),
                sameDayESignatureExpiry: sameDayESignatureExpiry(submission),
                isQuoteDataDefined: !_.isUndefined(submission.quoteData)
            });
            submissionPromise = Promise.resolve(submission);
        } else if (_.has(location, 'search')) {
            const parsedParms = queryString.parse(location.search);
            submissionPromise = LoadSaveService.retrieveAccountSubmission(parsedParms);
        } else {
            return;
        }

        if (!_.isUndefined(submissionPromise)) {
            submissionPromise.then((response) => {
                submissionVM = viewModelService.create(
                    removeMockData(response),
                    'pc',
                    'wmic.edge.us.capabilities.quote.submission.dto.QuoteDataDTO',
                    viewModelContext
                );

                setInitialSubmission(submissionVM);
            });
        }
        setIsLoading(false);
        // only execute this once per component lifecycle
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService]);

    const handleKnockout = useCallback(({ knockOutErrors, wizardData }) => {
        const quoteID = wizardData.quoteID.value;
        history.push('/knockoutpage', {
            underwritingIssues: knockOutErrors,
            quoteID
        });
    }, [history]);

    const handleCancel = useCallback((wizardProps) => {
        const {
            wizardData,
            param
        } = wizardProps;
        const quoteNumber = wizardData.quoteID.value;
        if (param === '/confirmation' || !quoteNumber) {
            return;
        }

        modalApi.showModal(
            <WMICModal
                title={translator(messages.areYouSure)}
                message={translator(messages.exitMessage)}
                description={translator(messages.yourQuoteNumberIs, { quoteNumber })}
                actionBtnLabel={messages.continueQuote}
                showCancelBtn
                cancelBtnLabel={messages.exitQuote}
                iconClass="error-text fa-3x fa fa-exclamation-circle"
                onCancel={() => { setCancelButtonClicked(true); }}
            />
        ).then(() => {
            return _.noop();
        }, _.noop);
    }, [history, translator]);

    useEffect(() => {
        if (cancelButtonClicked === true) {
            history.push('/');
        }
    }, [cancelButtonClicked, history]);

    const handleFinish = useCallback((wizardProps) => {
        const {
            wizardData
        } = wizardProps;
        history.push({
            pathname: '/confirmation',
            state: {
                submission: wizardData.value
            }
        });
    }, [history]);

    const handlePrevious = async (wizardProps) => {
        const {
            wizardSnapshot, cloneData, updateWizardData
        } = wizardProps;
        await updateWizardData(cloneData(wizardSnapshot));
        await new Promise((resolve) => setTimeout(resolve, 10));
        return wizardSnapshot;
    };

    const modalProps = {
        modalTitle: 'Loading...',
        isOpen: true
    };

    if (isLoading) {
        return <WMICProgressModal {...modalProps} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <QuoteInfoComponentContext.Provider value={HoQuoteInfo}>
            <WMICRetrieveInfoContext.Provider value={retrieveInfoProps}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    initialData={initialSubmission}
                    onCancel={handleCancel}
                    skipCompletedSteps={shouldSkipValidSteps}
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    onKnockOut={handleKnockout}
                    cancelLabel={translator(messages.returnLabel)}
                    onFinish={handleFinish}
                    onPrevious={handlePrevious}
                />
            </WMICRetrieveInfoContext.Provider>
        </QuoteInfoComponentContext.Provider>
    );
}

HOWizard.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
        state: PropTypes.shape({
            address: PropTypes.shape({})
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default HOWizard;