import { defineMessages } from 'react-intl';

export default defineMessages({
    retrieveQuote: {
        id: 'wmic.quoteandbind.directives.AvailableProductsAndFeatures.Retrieve a Quote',
        defaultMessage: 'Retrieve a Quote',
    }, 
    enterEMail: {
        id: 'wmic.quoteandbind.directives.ProductZipCodeContainer.Enter EMail',
        defaultMessage: 'Enter your EMail',
    },
    enterZipCode: {
        id: 'wmic.quoteandbind.directives.ProductZipCodeContainer.Enter ZIP code',
        defaultMessage: 'Enter your ZIP code',
    },
    enterQuoteID: {
        id: 'wmic.quoteandbind.directives.ProductZipCodeContainer.Enter Quote #',
        defaultMessage: 'Enter Quote #',
    },
    continue: {
        id: 'wmic.quoteandbind.directives.ProductZipCodeContainer.Get Your Quote',
        defaultMessage: 'Get Your Quote',
    },
    verifying: {
        id: 'wmic.quoteandbind.directives.ProductZipCodeContainer.Verifying...',
        defaultMessage: 'Verifying...',
    },
    errorTitle: {
        id: 'wmic.quoteandbind.directives.retrieve-quote.error.Oops ...',
        defaultMessage: 'Oops ...'
    },
    errorDescription: {
        id: 'wmic.quoteandbind.directives.retrieve-quote.error.Wawanesa currently only offers insurance for residents of California and Oregon.',
        defaultMessage: 'Wawanesa currently only offers insurance for residents of California and Oregon.'
    },
    confirmationButton: {
        id: 'wmic.quoteandbind.directives.retrieve-quote.error.OK',
        defaultMessage: 'OK'
    }
});
