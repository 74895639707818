import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FieldComponent } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { AddressLookupService } from 'gw-capability-address';
import { withAuthenticationContext } from 'wmic-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './AddressLookupComponent.metadata.json5';
import styles from './AddressLookupComponent.module.scss';
import './AddressLookupComponent.messages';

class AddressLookupComponent extends FieldComponent {
    static contextType = TranslatorContext;

    /**
     * @memberof gw-capability-address-react.AddressLookupComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.postalCode - the postal code entered in the landing page
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {function} propTypes.onValidate - returns if the values are valid
     */
    static propTypes = {
        value: PropTypes.shape({ postalCode: PropTypes.string }),
        path: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        onValidate: PropTypes.func,
        onValueChange: PropTypes.func,
        label: PropTypes.string,
        labelPosition: PropTypes.string,
        required: PropTypes.bool,
        authHeader: PropTypes.shape({}).isRequired,
        ...FieldComponent.propTypes,
        ...validationPropTypes
    };

    static defaultProps = {
        onValidate: undefined,
        value: undefined,
        onValueChange: undefined,
        layout: 'full-width',
        hideLabel: true,
        label: undefined,
        labelPosition: undefined,
        required: false
    };

    state = {
        errorText: '',
        matches: [],
        formData: {},
        emptiedSearchField: false,
        enableSearchButton: undefined,
        manualAddressOverride: false
    };

    componentDidMount() {
        const {
            id, onValidate, isComponentValid, registerComponentValidation
        } = this.props;

        registerComponentValidation(this.isFieldsValid);

        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        if (this.isFieldsValid()) {
            this.setState({ manualAddressOverride: true });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            id,
            onValidate,
            isComponentValid,
            hasValidationChanged,
            value: newAddressData
        } = this.props;
        const { value: oldAddressData } = prevProps;
        const isSameAddress = _.isEqual(newAddressData.value, oldAddressData.value);

        if ((!isSameAddress || hasValidationChanged) && onValidate) {
            onValidate(isComponentValid, id);
        }
    }

    isFieldsValid = () => {
        const { value: address } = this.props;

        return address.aspects.valid && address.aspects.subtreeValid;
    };

    handleChange = (value, changedPath) => {
        const { path, onValueChange } = this.props;
        const { matches, formData } = this.state;

        _.set(formData, changedPath, value);

        if (onValueChange && changedPath === 'selectedAddress') {
            onValueChange(_.get(matches, value).address, `${path}`);
        } else {
            onValueChange(value, `${path}.${changedPath}`);
        }

        this.setState({ formData });
    };

    writeValue = (value, path) => {
        const { formData, emptiedSearchField } = this.state;
        const clonedFormData = _.cloneDeep(formData);
        _.set(clonedFormData, path, value);

        this.setState({
            formData: clonedFormData,
            emptiedSearchField: path === 'searchText' && _.isEmpty(value),
            enableSearchButton: path === 'searchText' && emptiedSearchField
        });
    };

    clearTextField = () => {
        const { value: address, path, onValueChange } = this.props;
        const clonedValues = _.clone(address.value);
        clonedValues.addressLine1 = undefined;
        clonedValues.addressLine2 = undefined;
        clonedValues.addressLine3 = undefined;
        clonedValues.city = undefined;
        onValueChange(clonedValues, path);
        this.setState({
            formData: {
                searchText: '',
                selectedAddress: undefined
            },
            matches: [],
            errorText: ''
        });
    };

    renderErrorsMessage = () => {
        const { errorText } = this.state;
        return [errorText];
    };

    enterManualAddress = () => {
        this.setState({ manualAddressOverride: true });
    };

    enterAddressLookup = () => {
        this.setState({ manualAddressOverride: false });
    };

    onSearchAddress = () => {
        const { formData } = this.state;
        const { value: addressData, authHeader } = this.props;

        AddressLookupService.lookupAddressUsingStringAndFilterByPostalCode(
            formData.searchText,
            _.get(addressData, 'postalCode.value'),
            authHeader
        ).then((addresses) => {
            this.setState({
                enableSearchButton: !_.isEmpty(addresses.matches),
                matches: addresses.matches,
                errorText: addresses.errorDescription || ''
            });
        });
    };

    renderControl() {
        const {
            formData,
            matches,
            errorText,
            manualAddressOverride,
            enableSearchButton
        } = this.state;
        const {
            value: address, label, labelPosition, required, isComponentValid
        } = this.props;

        const dataForComponent = {
            ...formData,
            ...address
        };

        const showManualAddress = !!manualAddressOverride
            || (isComponentValid && _.isEmpty(matches) && manualAddressOverride === undefined);
        const showLookupAddress = !showManualAddress;

        const overrideProps = {
            '@field': {
                showOptional: true,
                labelPosition: labelPosition
            },
            addresslookupSearch: {
                onValueChange: this.writeValue,
                label: label,
                required: required
            },
            addressManualContainer: {
                visible: showManualAddress
            },
            addressLookupContainer: {
                visible: showLookupAddress
            },
            clearButtonIcon: {
                visible: !_.isEmpty(formData.searchText)
            },
            addressesFound: {
                showErrors: errorText !== '',
                validationMessages: this.renderErrorsMessage(),
                availableValues: matches.map((singleAddress, index) => {
                    return {
                        code: `${index}`,
                        name: `${singleAddress.address.addressLine1}, ${singleAddress.address.city}, ${singleAddress.address.state}, ${singleAddress.address.postalCode}`
                    };
                })
            },
            searchButton: {
                disabled:
                    enableSearchButton || _.isEmpty(formData) || _.isEmpty(formData.searchText)
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onSearchAddress: this.onSearchAddress,
                onCleartextField: this.clearTextField,
                enterManualAddress: this.enterManualAddress,
                enterAddressLookup: this.enterAddressLookup
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={dataForComponent}
                overrideProps={overrideProps}
                onValueChange={this.handleChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    }

    render() {
        return super.render();
    }
}

export default withAuthenticationContext(withValidation(AddressLookupComponent));
