import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import { useDataRefresh, ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICButton } from 'wmic-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import metadata from './StartQuoteComponent.metadata.json5';
import styles from './StartQuoteComponent.module.scss';
import PrivacyInfoComponent from '../PrivacyInfoComponent/PrivacyInfoComponent';

const StartQuoteComponent = (props) => {
    const {
        onEnterProductFlow,
        onGoToRetrieveQuote,
        wizardLink,
        productCode
    } = props;

    const { refreshData } = useDataRefresh();
    const { onValidate } = useValidation('StartQuoteComponent');
    const [showErrors, setShowErrors] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const [quoteRequestVM, updateQuoteRequestVM] = useState(undefined);

    useEffect(
        () => {
            if (viewModelService !== undefined && quoteRequestVM === undefined) {
                const quoteRequestViewModel = viewModelService.create(
                    {
                        effectiveDate: new Date(),
                        productCode: productCode
                    },
                    'pc',
                    'wmic.edge.us.capabilities.policycommon.availability.dto.PostalCodeAvailabilityRequestDTO'
                );
                updateQuoteRequestVM(quoteRequestViewModel);
            }
        },
        [productCode, quoteRequestVM, viewModelService]
    );

    const handleQuoteRequestVMChange = useCallback(
        (newModel) => {
            updateQuoteRequestVM(newModel);
            refreshData();
        },
        [refreshData]
    );


    const getQuote = () => {
        if (!quoteRequestVM.postalCode.aspects.valid) {
            setShowErrors(true);
        } else if (onEnterProductFlow) {
            onEnterProductFlow(quoteRequestVM.value, wizardLink);
        }
    };

    const zipCodeChange = useCallback(
        (event) => {
            if (quoteRequestVM !== undefined) {
                quoteRequestVM.postalCode.value = event.target.value.replace(/[^\d-]/, '');
                updateQuoteRequestVM(quoteRequestVM);
                refreshData();
            }
        },
        [quoteRequestVM, refreshData]
    );

    const renderZipCodeInput = () => {
        return (
            <>
                <input
                    type="tel"
                    value={quoteRequestVM ? quoteRequestVM.postalCode.value : ''}
                    id="PostalCodeNew"
                    className="zip-input"
                    onChange={zipCodeChange}
                />
                <div className="error-inline error-zip">
                    {(
                        (showErrors && !(_.get(quoteRequestVM, 'postalCode.aspects.valid', false)))
                        && <span>{quoteRequestVM.postalCode.aspects.validationMessage}</span>
                    )}
                </div>
            </>
        );
    };


    const handleSubmit = (evt) => {
        evt.preventDefault();
        getQuote();
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onGoToRetrieveQuote: onGoToRetrieveQuote,
            onGetQuote: getQuote,
        },
        resolveComponentMap: {
            privacyDisclaimerComponent: PrivacyInfoComponent,
            WMICButton
        }
    };

    const overrideProps = {
        startQuoteContentContainer: {
            onSubmit: handleSubmit,
        },
        postalCodeInput: {
            content: renderZipCodeInput()
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            model={quoteRequestVM}
            onModelChange={handleQuoteRequestVMChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

StartQuoteComponent.propTypes = {
    onEnterProductFlow: PropTypes.func.isRequired,
    onGoToRetrieveQuote: PropTypes.func.isRequired,
    wizardLink: PropTypes.string.isRequired,
    productCode: PropTypes.string.isRequired
};

export default StartQuoteComponent;
