import { defineMessages } from 'react-intl';

export default defineMessages({
    contactUsWarning: {
        id: 'quoteandbind.views.contact-us.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    contactUsSolution: {
        id: 'quoteandbind.views.contact-us.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.contact-us.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.contact-us.Reference Number',
        defaultMessage: 'Reference Number',
    },
    contactUs: {
        id: 'quoteandbind.views.contact-us.Contact Us',
        defaultMessage: 'Contact Us',
    },
    quoteDeclined: {
        id: 'quoteandbind.views.contact-us.Quote Declined',
        defaultMessage: 'Quote Declined',
    },
    unableToOffer: {
        id: 'quoteandbind.views.contact-us.We are unable to offer you a quote',
        defaultMessage: 'We are unable to offer you a quote.',
    },
    unableReason: {
        id: 'quoteandbind.views.contact-us.We aren’t able to offer a quote due to the following',
        defaultMessage: 'We aren’t able to offer a quote due to the following:',
    },
    sorry: {
        id: 'quoteandbind.views.contact-us.Sorry',
        defaultMessage: 'We\'re Sorry...',
    },
    referenceNum: {
        id: 'quoteandbind.views.contact-us.Please contact us with reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with reference number below and we would be pleased to assist you.',
    },
    techDifficulty: {
        id: 'quoteandbind.views.contact-us.We are experiencing some technical difficulty. Your information has been saved',
        defaultMessage: 'We are experiencing some technical difficulty. Your information has been saved and you may continue your quote once the issue is resolved.\n',
    },
    furtherAssist: {
        id: 'quoteandbind.views.contact-us.For further assistance please give us a call.',
        defaultMessage: 'For further assistance, please call us at {phoneNumber} or simply {link} at a later time.',
    },
    furtherAssistLink: {
        id: 'quoteandbind.views.contact-us.retrieve your online quote',
        defaultMessage: 'retrieve your online quote',
    },
    thankYou: {
        id: 'quoteandbind.views.contact-us.Thank you for your patience.',
        defaultMessage: 'Thank you for your patience.',
    },
    doNotOffer: {
        id: 'quoteandbind.views.contact-us.Sorry, we do not offer an insurance product that fits your needs at this time due to the following',
        defaultMessage: 'Sorry, we do not offer an insurance product that fits your needs at this time due to the following:',
    },
    callWithNumCA: {
        id: 'quoteandbind.views.contact-us.CA.Upon written request within 90 days',
        defaultMessage: 'Upon written request within 90 days, you may receive the reasons for this decision in writing and any personal information used to make this decision. Upon written request you may also access your personal information, or you may request to correct, amend or delete your personal information.',
    },
    callWithNumOR: {
        id: 'quoteandbind.views.contact-us.OR.Upon written request within 90 days',
        defaultMessage: 'Upon written request within 90 days, you may receive the reasons for this decision in writing and any personal information used to make this decision. Upon written request you may also access your personal information, or you may request to correct, amend or delete your personal information.',
    },
    callCenterHours: {
        id: 'quoteandbind.views.contact-us.Call Center Business Hours',
        defaultMessage: 'Call Center Business Hours',
    },
    weekdayHours: {
        id: 'quoteandbind.views.contact-us.Monday to Friday 8:00 am - 4:00 pm PT',
        defaultMessage: 'Monday to Friday: 8:00 am - 4:00 pm PT',
    },
    calFP: {
        id: 'quoteandbind.views.contact-us.You may be eligible for coverage through the California Fair Plan Association',
        defaultMessage: '&lt;span&gt;&lt;p&gt;You may be eligible for coverage through the California Fair Plan Association. Please contact the California Fair Plan Association at:&lt;/p&gt;&lt;p&gt;California Fair Plan Association&lt;br/&gt;P.O. Box 76924&lt;br/&gt;Los Angeles&lt;br/&gt;California&lt;br/&gt;90076-0924&lt;p&gt;or by calling &lt;a href="tel:1-800-339-4099"&gt;1-800-339-4099&lt;/a&gt; or &lt;a href="tel:1-213-487-0111"&gt;1-213-487-0111&lt;/a&gt;&lt;span&gt;',
    },
    orFP: {
        id: 'quoteandbind.views.contact-us.Oregon Fair Plan Association',
        defaultMessage: '&lt;span&gt;You may be eligible for coverage through the Oregon Fair Plan Association. Please contact the Oregon Fair Plan Association at:&lt;br&gt;&lt;br&gt;Oregon Fair Plan Association&lt;br/&gt;8705 S.W. Nimbus Ave, Suite 360&lt;br/&gt;Beaverton, OR 97008-7157&lt;br&gt;&lt;br&gt;or by calling &lt;a href="tel:1-503-643-5448"&gt;1-503-643-5448&lt;/a&gt;.&lt;/span&gt;',
    },
    emailUs: {
        id: 'quoteandbind.views.contact-us.Email Us',
        defaultMessage: '&lt;span&gt;Have a suggestion? We are always looking to improve! Please email your feedback to &lt;a href="mailto:usafdbk@wawanesa.com"&gt;usafdbk@wawanesa.com&lt;/a&gt;.&lt;/span&gt;',
    },
    contactWithRefNum: {
        id: 'quoteandbind.views.contact-us.underwriters.Please contact us with reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact one of our underwriters with reference number below and we would be pleased to assist you.',
    },
    refNum: {
        id: 'quoteandbind.views.contact-us.Reference Number',
        defaultMessage: 'Reference Number',
    },
    quoteNum: {
        id: 'quoteandbind.views.contact-us.Quote Number',
        defaultMessage: 'Quote Number',
    },
    timeOut: {
        id: 'quoteandbind.views.contact-us.Your session has timed out due to inactivity.',
        defaultMessage: 'Your session has timed out due to inactivity.',
    },
    quoteSaved: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Quote has been saved',
        defaultMessage: 'Your Quote Number is {quoteId}.',
    },
    returnToWawa: {
        id: 'quoteandbind.views.contact-us.Return to wawanesa.com',
        defaultMessage: 'Return to wawanesa.com',
    },
    quoteIs: {
        id: 'quoteandbind.views.contact-us.Your Quote Number is',
        defaultMessage: 'Your Quote Number is {quoteId}.',
    },
    hiThere: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Hi there! Based on the information',
        defaultMessage: 'Hi there! Based on the information you\'ve provided, we\'d like to personally discuss your insurance options.',
    },
    specialSolution: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Please call our Quote Specialists to find a solution together.',
        defaultMessage: 'Please call our Quote Specialists at {phoneNumber} to find a solution together.',
    },
    callTechError: {
        id: 'For further assistance, please call us or simply retrieve your online quote at a later time.',
        defaultMessage: 'For further assistance, please call us at {phoneNumber} or simply retrieve your online quote at a later time.'
    },
    callTechErrorCreate: {
        id: 'For further assistance, please call us or simply return later to get your online quote.',
        defaultMessage: 'For further assistance, please call us at {phoneNumber} or simply return later to get your online quote.'
    },
    temporarilyUnavailable: {
        id: 'quoteandbind.views.contact-us.Online Quoting is Temporarily Unavailable',
        defaultMessage: 'Online Quoting is Temporarily Unavailable',
    },
    callAgent: {
        id: 'quoteandbind.views.contact-us.To get a quote from a helpful agent',
        defaultMessage: '&lt;span&gt;To get a quote from a helpful agent, please call us at  &lt;a href="tel:1-800-640-2920"&gt;800-640-2920&lt;/a&gt;&lt;/span&gt;',
    },
    callAgentOpenHours1: {
        id: 'quoteandbind.views.contact-us.Monday - Friday 8:00 am - 4:00 pm PT',
        defaultMessage: 'Monday - Friday 8:00 am - 4:00 pm PT',
    },
    temporarilyUnavailableApology: {
        id: 'quoteandbind.views.contact-us.We apologize for any inconvenience and hope you’ll reach out to us',
        defaultMessage: 'We apologize for any inconvenience and hope you’ll reach out to us.',
    }
});
