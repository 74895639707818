import { defineMessages } from 'react-intl';

export default defineMessages({
    getQuote: {
        id: 'guidance.directives.recommendations.guidance-product.Get Quote',
        defaultMessage: 'Get Quote'
    },
    talkToAnAgent: {
        id: 'guidance.directives.recommendations.guidance-product.Talk to an Agent',
        defaultMessage: 'Talk to an Agent'
    },
});
