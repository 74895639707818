/* eslint-disable max-len */
import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { CONSTANTS, WMICArrayUtil, Position, REFERRAL_WHITELIST_CODES } from 'wmic-portals-utils-js';
import {
    WMICCustomRadioButton,
    WMICCustomTooltip,
    WMICCustomDropdownSelect,
    WMICCustomInput,
    WMICScrollToError,
    WMICProgressModal
} from 'gw-capability-quoteandbind-common-react';
import { WMICCheckbox, WMICButton } from 'wmic-components-platform-react';

import metadata from './WMICAdditionalDetails.metadata.json5';
import messages from './WMICAdditionalDetails.messages';
import styles from './WMICAdditionalDetails.modules.scss';

const INSURANCE_CARRIER_OTHER = 'Other';
const REFERRER_OTHER = 'other';

const insuranceCarrierListMapper = (carrier) => {
    return {
        code: carrier,
        name: carrier
    };
};

const getInsuranceCarrierList = (submissionVM) => {
    const insuranceList = submissionVM.lobData.homeowners.coverables.rating.aspects.context('InsuranceCarriers');
    // add other to the end of the list
    const otherIndex = _.findIndex(insuranceList, (carrier) => {
        return carrier === INSURANCE_CARRIER_OTHER;
    });
    WMICArrayUtil.move(otherIndex, insuranceList.length - 1, insuranceList);
    return insuranceList.map(insuranceCarrierListMapper);
};

// eslint-disable-next-line camelcase
function WMICAdditionalDetails(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const [isPageInitialized, setPageInitialized] = useState(false);
    const translator = useTranslator();
    const { defaultValuesEnabled = false } = appConfig;
    const [showErrors, setShowErrors] = useState(false);
    const [timestamp] = useState(Date.now());
    const [isLoading, setIsLoading] = useState(false);

    const {
        isComponentValid,
        initialValidation,
        onValidate
    } = useValidation('WMICAdditionalDetails');

    const [insuranceCarrierList] = useState(getInsuranceCarrierList(submissionVM));

    const isCA = () => {
        return (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.CA);
    };

    const isOR = useCallback(() => {
        return (_.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR);
    }, [submissionVM]);

    useEffect(() => {
        if (!isPageInitialized && _.isEmpty(submissionVM.lobData.homeowners.coverables.rating.value)) {
            // eslint-disable-next-line no-param-reassign
            submissionVM.lobData.homeowners.coverables.rating = {};
            submissionVM.baseData.multiProductPolicyNumberValid_WMIC.value = false;
            updateWizardData(submissionVM);
        }
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.get(submissionVM, '_context.Binding', false)) {
            const vm = viewModelService.changeContext(submissionVM, {
                Binding: true
            });
            updateWizardData(vm);
        }
    }, [submissionVM, updateWizardData, viewModelService]);


    const shouldShow = (path, valueToMatch = true) => {
        const value = _.get(submissionVM, path);
        return value === valueToMatch;
    };

    const getFireAlarmLabelTextForState = () => {
        if (isCA()) {
            return translator(messages.fireAlarmCA);
        }
        return translator(messages.fireAlarmOR);
    };

    const getBurglarAlarmLabelTextForState = () => {
        if (isCA()) {
            return translator(messages.burglarAlarmCA);
        }
        return translator(messages.burglarAlarmOR);
    };

    const setDefaultValues = () => {
        const ratingVM = submissionVM.lobData.homeowners.coverables.rating;
        if (submissionVM.region_WMIC.value.code === CONSTANTS.JURISDICTIONS.OR) {
            ratingVM.digitalDialer.value = true;
            ratingVM.sprinklerSystemType.value = 'none';
            ratingVM.burglarAlarmType.value = 'central';
            ratingVM.fireAlarmType.value = 'central';
            ratingVM.currentInsuranceCompany.value = "No Insurance";
        } else if (submissionVM.region_WMIC.value.code === CONSTANTS.JURISDICTIONS.CA) {
            ratingVM.gatedCommunity.value = true;
            ratingVM.securityGuard.value = true;
        }
        ratingVM.fireExtinguishers.value = true;
        ratingVM.burglarAlarm.value = true;
        ratingVM.fireAlarm.value = true;
        ratingVM.smokeAlarm.value = true;
        ratingVM.deadbolts.value = true;
        ratingVM.referrer.value = 'advertising';

        updateWizardData(submissionVM);
    };

    const getSortedReferrerList = useCallback(() => {
        const whitelistCodes = Object.values(REFERRAL_WHITELIST_CODES);
        const filteredList = submissionVM.lobData.homeowners.coverables.rating.referrer && submissionVM.lobData.homeowners.coverables.rating.referrer.aspects.availableValues
            .filter((item) => {
                if (isOR()) {
                    const index = whitelistCodes.indexOf(REFERRAL_WHITELIST_CODES.CCCU)
                    if (index > -1) {
                        whitelistCodes.splice(index, 1)
                    }
                }
                return whitelistCodes.includes(item.code)
            });
        const translatedList = filteredList && filteredList.map((item) => {
            return {
                code: item.code,
                name: translator({
                    id: item.name,
                    defaultMessage: item.name
                })
            }
        });
        return translatedList;
    }, [isOR, submissionVM, translator]);

    const writeValue = (value, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if(_.isUndefined(_.get(newSubmissionVM, path))) {
            newSubmissionVM.lobData.homeowners.coverables.rating = {};
        }
        _.set(newSubmissionVM, `${path}.value`, value);
        updateWizardData(newSubmissionVM);
    };
    

    const updateMultiProduct = useCallback((valid) => {
        _.set(submissionVM, 'baseData.multiProductPolicyNumberValid_WMIC.value', valid);
        updateWizardData(submissionVM);
    }, [updateWizardData, submissionVM]);

    const updateRatingForQuoting = () => {
        _.unset(submissionVM.value, 'bindData');
        const ratingVM = submissionVM.lobData.homeowners.coverables.rating;
        ratingVM.deadboltsNumber.value = ratingVM.deadbolts.value ? 1 : undefined;
        ratingVM.smokeAlarmOnAllFloors.value = true;
        ratingVM.visibleToNeighbors.value = false;
        if (isCA()) {
            ratingVM.burglarAlarmType.value = ratingVM.burglarAlarm.value ? CONSTANTS.CENTRAL : undefined;
            ratingVM.fireAlarmType.value = ratingVM.fireAlarm.value ? CONSTANTS.CENTRAL : undefined;
            ratingVM.sprinklerSystemType.value = CONSTANTS.NONE;
            // Since we are not asking the question about Prior Insurance History, we need to default this field for California
            ratingVM.currentInsuranceCompany.value = 'Other';
            ratingVM.currentInsuranceCompanyDescription.value = 'Web Quote Default';
            // We want to set these values to either true or false (never undefined) because
            // they are being controlled by checkboxes which gives a binary result instead of ternary
            ratingVM.fireExtinguishers.value = ratingVM.fireExtinguishers.value === true;
            ratingVM.burglarAlarm.value = ratingVM.burglarAlarm.value === true;
            ratingVM.fireAlarm.value = ratingVM.burglarAlarm.value === true;
            ratingVM.smokeAlarm.value = ratingVM.smokeAlarm.value === true;
            ratingVM.deadbolts.value = ratingVM.deadbolts.value === true;
            ratingVM.gatedCommunity.value = ratingVM.gatedCommunity.value === true;
            ratingVM.securityGuard.value = ratingVM.securityGuard.value === true;

            ratingVM.burglarAlarmType.value = ratingVM.burglarAlarm.value ? CONSTANTS.CENTRAL : undefined;
            ratingVM.fireAlarmType.value = ratingVM.fireAlarm.value ? CONSTANTS.CENTRAL : undefined;
        }
    };

    const onNext = useCallback(async () => {
        updateRatingForQuoting();
        const multiProduct = _.get(submissionVM, 'baseData.multiProduct_WMIC.value');
        let policyNumber = _.get(submissionVM, 'baseData.multiProductPolicyNumber_WMIC.value');
        if(multiProduct && !policyNumber) {
            setShowErrors(true);
            return false;
        }
        setIsLoading(true, "Policy Number", policyNumber);
        try {
            if (multiProduct) {
                const quoteId = _.get(submissionVM, 'quoteID.value');
                const validation = await LoadSaveService.validateMultiProductPolicyNumber(quoteId, policyNumber, authHeader);
                updateMultiProduct(validation);
            } else {
                policyNumber = undefined;
                _.set(submissionVM, 'baseData.multiProductPolicyNumber_WMIC.value', undefined);
            }
        } catch (e) {
            console.error(e);
        }

        if (!submissionVM.lobData.homeowners.coverables.rating.aspects.subtreeValid) {
            setIsLoading(false);
            setShowErrors(true);
            return false;
        }

        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
            submissionVM.value,
            authHeader
        );
        updateWizardData(submissionVM);
        return submissionVM;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoadSaveService, authHeader, submissionVM, isComponentValid, updateMultiProduct]);

    const isChecked = (path) => {
        return _.get(submissionVM, path);
    };

    const modalProps = {
        modalTitle: translator(messages.calculatingYourQuote),
        isOpen: true
    };

    const resolvers = {
        resolveComponentMap: {
            wmicCustomRadioButton: WMICCustomRadioButton,
            wmicCustomTooltip: WMICCustomTooltip,
            wmicCustomDropdownSelect: WMICCustomDropdownSelect,
            wmicInput: WMICCustomInput,
            WMICCheckbox,
            wmicScrollToError: WMICScrollToError,
            WMICButton
        },
        resolveCallbackMap: {
            onSetDefaultValues: setDefaultValues
        },
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top'
        },
        checkboxInputsContainer: {
            visible: isCA()
        },
        radioInputsContainer: {
            visible: isOR()
        },
        fireExtinguisherCheckbox: {
            label: translator(messages.haveFireExtinguisher),
            onValueChange: (value) => { writeValue(value, 'lobData.homeowners.coverables.rating.fireExtinguishers'); },
            value: isChecked('lobData.homeowners.coverables.rating.fireExtinguishers.value')
        },
        burglarAlarmCheckbox: {
            label: translator(messages.haveBurglarAlarm),
            onValueChange: (value) => { writeValue(value, 'lobData.homeowners.coverables.rating.burglarAlarm'); },
            value: isChecked('lobData.homeowners.coverables.rating.burglarAlarm.value')
        },
        smokeAlarmCheckbox: {
            label: translator(messages.haveSmokeAlarm),
            onValueChange: (value) => { writeValue(value, 'lobData.homeowners.coverables.rating.smokeAlarm'); },
            value: isChecked('lobData.homeowners.coverables.rating.smokeAlarm.value')
        },
        deadboltCheckbox: {
            label: translator(messages.haveDeadBolts),
            onValueChange: (value) => { writeValue(value, 'lobData.homeowners.coverables.rating.deadbolts'); },
            value: isChecked('lobData.homeowners.coverables.rating.deadbolts.value')
        },
        gatedCommunityChexkbox: {
            label: translator(messages.haveGatedCommunity),
            onValueChange: (value) => { writeValue(value, 'lobData.homeowners.coverables.rating.gatedCommunity'); },
            value: isChecked('lobData.homeowners.coverables.rating.gatedCommunity.value'),
        },
        securityGuardCheckbox: {
            label: translator(messages.haveSecurityGuard),
            onValueChange: (value) => { writeValue(value, 'lobData.homeowners.coverables.rating.securityGuard'); },
            value: isChecked('lobData.homeowners.coverables.rating.securityGuard.value')
        },
        defaultValuesButton: {
            visible: defaultValuesEnabled === 'true'
        },
        fireAlarm: {
            label: getFireAlarmLabelTextForState(),
            visible: isOR()
        },
        burglarAlarm: {
            label: getBurglarAlarmLabelTextForState(),
            visible: isOR()
        },
        fireExtiguisher: {
            visible: isOR()
        },
        smokeAlarm: {
            visible: isOR()
        },
        deadboltLocks: {
            visible: isOR()
        },
        multiProduct: {
            visible: isOR()
        },
        sprinklerSystemType: {
            labelPosition: Position.TOP,
            visible: isOR(),
            availableValues: submissionVM?.lobData.homeowners.coverables.rating.sprinklerSystemType?.aspects.availableValues,
            onValueChange: (value) => writeValue(value, 'lobData.homeowners.coverables.rating.sprinklerSystemType'),
            value: submissionVM?.lobData.homeowners.coverables.rating.sprinklerSystemType?.value?.code,
            alwaysShowPlaceholder: false
        },
        multiProductPolicyNumber: {
            visible: isOR() && shouldShow('baseData.multiProduct_WMIC.value')
        },
        digitalDialer: {
            visible: isOR() && shouldShow('lobData.homeowners.coverables.rating.smokeAlarm.value')
        },
        fireAlarmAndTooltipContainer: {
            visible: isOR() && shouldShow('lobData.homeowners.coverables.rating.fireAlarm.value')
        },
        burglarAlarmAndTooltipContainer: {
            visible: isOR() && shouldShow('lobData.homeowners.coverables.rating.burglarAlarm.value')
        },
        currentInsuranceCompany: {
            labelPosition: Position.TOP,
            availableValues: insuranceCarrierList,
            visible: isOR(),
            onValueChange: (value) => writeValue(value, 'lobData.homeowners.coverables.rating.currentInsuranceCompany'),
            value: submissionVM?.lobData.homeowners.coverables.rating.currentInsuranceCompany?.value,
            alwaysShowPlaceholder: false
        },
        currentInsuranceCompanyDescription: {
            labelPosition: Position.TOP,
            visible: isOR() && shouldShow('lobData.homeowners.coverables.rating.currentInsuranceCompany.value', INSURANCE_CARRIER_OTHER)
        },
        referrer: {
            labelPosition: Position.TOP,
            availableValues: getSortedReferrerList(),
            onValueChange: (value) => writeValue(value, 'lobData.homeowners.coverables.rating.referrer'),
            value: submissionVM?.lobData.homeowners.coverables.rating.referrer?.value?.code,
            alwaysShowPlaceholder: false
        },
        referrerDescription: {
            labelPosition: Position.TOP,
            visible: shouldShow('lobData.homeowners.coverables.rating.referrer.value.code', REFERRER_OTHER)
        },
        burglarAlarmTypeSelect: {
            labelPosition: Position.TOP,
            availableValues: submissionVM?.lobData.homeowners.coverables.rating.burglarAlarmType?.aspects.availableValues,
            onValueChange: (value) => writeValue(value, 'lobData.homeowners.coverables.rating.burglarAlarmType'),
            value: submissionVM?.lobData.homeowners.coverables.rating.burglarAlarmType?.value?.code,
            alwaysShowPlaceholder: false
        },
        fireAlarmTypeSelect: {
            labelPosition: Position.TOP,
            availableValues: submissionVM?.lobData.homeowners.coverables.rating.fireAlarmType?.aspects.availableValues,
            onValueChange: (value) => writeValue(value, 'lobData.homeowners.coverables.rating.fireAlarmType'),
            value: submissionVM?.lobData.homeowners.coverables.rating.fireAlarmType?.value?.code,
            alwaysShowPlaceholder: false
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showEmailQuoteButton={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                showErrors={showErrors}
            />
            <WMICScrollToError counter={timestamp} />
            {isLoading && <WMICProgressModal {...modalProps} />}
        </WizardPage>
    );
}

WMICAdditionalDetails.propTypes = wizardProps;
// eslint-disable-next-line camelcase
export default WMICAdditionalDetails;