/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@jutro/components';
import { Flex, FlexItem } from '@jutro/layout';
import cx from 'classnames';
import styles from './WMICPaymentStep.module.scss';

import { Link } from '@jutro/router';

const WMICPaymentStep = ({
    backgroundIcon,
    children,
    editEmail,
    editEmailNode,
    link: {
        hint,
        label,
        onClick,
    },
    hideArrow,
    isVertical
}) => (
    <Flex
        direction="column"
        alignItems="center"
        className={
            cx(styles.step, {
                [styles.withArrow]: !hideArrow,
                [styles.vertical]: isVertical
            })
        }
        gap="none"
    >
        <FlexItem className={styles.content}>
            <div className={styles.childrenWrapper}>
                {children}
            </div>
            <div className={styles.iconWrapper}>
                <div className={styles.iconCircle}>
                    <i className={cx(backgroundIcon, styles.icon)} />
                </div>
            </div>
        </FlexItem>
        <FlexItem className={styles.footer}>
            <div className={styles.linkHint}>{hint}</div>
            <Link onClick={onClick}>{label}</Link>
        </FlexItem>
        <FlexItem className={styles.emailForm}>
            {editEmail && (
                <div>
                    {editEmailNode}
                </div>
            )}
        </FlexItem>
    </Flex>
);

WMICPaymentStep.propTypes = {
    backgroundIcon: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    editEmail: PropTypes.bool,
    editEmailNode: PropTypes.node,
    link: PropTypes.shape({
        hint: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    }).isRequired,
    hideArrow: PropTypes.bool,
    isVertical: PropTypes.bool
};

WMICPaymentStep.defaultProps = {
    editEmail: false,
    editEmailNode: <></>,
    hideArrow: false,
    isVertical: false
};

export default WMICPaymentStep;