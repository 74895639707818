import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { Flex, FlexItem } from '@jutro/layout';
import { WMICEmailForm } from 'gw-capability-quoteandbind-ho-react';
import WMICPaymentStep from './WMICPaymentStep';
import messages from './WMICPaymentSteps.messages';
import styles from './WMICPaymentSteps.module.scss';


const WMICPaymentSteps = ({
    steps, submissionVM, updateWizardData, onResendEmail, setEffectiveEmail
}) => {
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    const [editEmail, setEditEmail] = useState(false);

    const showEmailFields = useCallback(() => {
        const nextSubmissionVM = viewModelService.clone(submissionVM);
        const effectiveEmail = _.get(submissionVM, 'bindData.payerEmail_WMIC.value');

        _.set(nextSubmissionVM, 'bindData.payerEmail_WMIC.value', effectiveEmail);
        _.set(nextSubmissionVM, 'bindData.payerEmailConfirmation_WMIC.value', '');
        updateWizardData(nextSubmissionVM);
        setEditEmail(true);
    }, [submissionVM, updateWizardData, viewModelService]);

    const checkEmailStep = {
        expand: true,
        backgroundIcon: 'fas fa-envelope',
        children: (
            <>
                <FormattedMessage
                    {...messages.checkEmail}
                />
                <br />
                <FormattedMessage
                    {...messages.email}
                    values={{ email: <em>{steps.checkEmail.email}</em> }}
                />
            </>
        ),
        link: {
            hint: editEmail ? '' : translator(messages.notPayorAddress),
            label: translator(messages.editEmail),
            onClick: () => {
                showEmailFields();
            }
        },
        editEmail: editEmail,
        editEmailNode: (
            <WMICEmailForm
                submissionVM={submissionVM}
                updateWizardData={updateWizardData}
                onCancel={() => {
                    setEditEmail(false);
                }}
                onResendEmail={onResendEmail}
                setEditEmail={setEditEmail}
                setEffectiveEmail={setEffectiveEmail}
            />
        )
    };

    const openEmailStep = {
        backgroundIcon: 'fas fa-link',
        children: <FormattedMessage
            {...messages.openEmail}
            values={{
                email: (
                    <>
                        <br />
                        <em>{steps.openEmail.email}</em>
                        <br />
                    </>)
            }}
        />,
        link: {
            hint: translator(messages.emailNotReceived),
            label: translator(messages.resendEmail),
            ...steps.openEmail.link
        }
    };

    const readAndSignStep = {
        backgroundIcon: 'fas fa-pencil-alt',
        children: translator(messages.readAndSign),
        link: {
            hint: translator(messages.payorAlreadySigned),
            label: translator(messages.proceedToNextStep),
            ...steps.readAndSign.link
        }
    };

    const stepsConfig = [checkEmailStep, openEmailStep, readAndSignStep];

    const isVertical = useMediaQuery({ query: '(max-width: 1023px)' });

    return (
        <Flex
            wrap={false}
            justifyContent="around"
            direction={isVertical ? 'column' : 'row'}
            gap="xxlarge"
            className={cx(styles.paymentSteps, {
                [styles.editEmail]: editEmail
            })}
        >
            {
                stepsConfig.map(
                    (step, index) => (
                        <FlexItem
                            key={step.backgroundIcon}
                            grow={1}
                            shrink={1}
                            className={cx(styles.stepContainer, {
                                [styles.expand]: step.expand,
                                [styles.shrink]: !step.expand
                            })}
                        >
                            <WMICPaymentStep
                                {...step}
                                hideArrow={index === stepsConfig.length - 1}
                                isVertical={isVertical}
                            />
                        </FlexItem>
                    )
                )
            }
        </Flex>
    );
};

WMICPaymentSteps.propTypes = {
    steps: PropTypes.shape({
        checkEmail: PropTypes.shape({
            email: PropTypes.string.isRequired
        }).isRequired,
        openEmail: PropTypes.shape({
            email: PropTypes.string.isRequired,
            link: PropTypes.shape({
                label: PropTypes.string,
                onClick: PropTypes.func.isRequired
            })
        }).isRequired,
        readAndSign: PropTypes.shape({
            link: PropTypes.shape({
                label: PropTypes.string,
                onClick: PropTypes.func.isRequired
            })
        }).isRequired
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    submissionVM: PropTypes.any.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    onResendEmail: PropTypes.func.isRequired,
    setEffectiveEmail: PropTypes.func.isRequired
};

export default WMICPaymentSteps;