import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useTranslator } from '@jutro/locale';

import { commonQuoteStyles } from 'gw-capability-quoteandbind-common-react';
import { MockUpUtil } from 'wmic-portals-utils-js';

import messages from './HoQuoteInfo.messages';

const PATH_TO_MOCK_DATA = 'quote.ho';

function HoQuoteInfo(props) {
    const { submissionVM } = props;
    const translator = useTranslator();
    const quoteId = _.get(submissionVM, 'quoteID.value');

    

    return (
        <div></div>
    );
}

HoQuoteInfo.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired
};

export default HoQuoteInfo;
