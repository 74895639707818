import { defineMessages } from 'react-intl';

export default defineMessages({
    jewelry: {
        id: 'quoteandbind.common.views.quote-details.coverage-name.Jewelry',
        defaultMessage: 'Extended Jewelry'
    },
    silverware: {
        id: 'quoteandbind.common.views.quote-details.coverage-name.Silverware3_wmic',
        defaultMessage: 'Silverware'
    },
    electronics: {
        id: 'quoteandbind.common.views.quote-details.coverage-name.Electronics_wmic',
        defaultMessage: 'Portable Electronics'
    },
    creditCards: {
        id: 'quoteandbind.common.views.quote-details.coverage-name.Credit Cards',
        defaultMessage: 'Credit Cards'
    },
    businessProperty: {
        id: 'quoteandbind.common.views.quote-details.coverage-name.Business Property',
        defaultMessage: 'Business Property'
    },
    firearms: {
        id: 'quoteandbind.common.views.quote-details.coverage-name.Firearms_wmic',
        defaultMessage: 'Firearms'
    },
    money: {
        id: 'quoteandbind.common.views.quote-details.coverage-name.Money_wmic',
        defaultMessage: 'Money'
    },
    securities: {
        id: 'quoteandbind.common.views.quote-details.coverage-name.Securities_wmic',
        defaultMessage: 'Securities'
    },
    earthquake: {
        id: 'quoteandbind.common.views.quote-details.coverage-name.Earthquake',
        defaultMessage: 'Earthquake'
    },
    coverageError: {
        id: 'quoteandbind.common.views.quote-details.Each Optional Coverage / Special Limit must have a unique type',
        defaultMessage: 'Each Optional Coverage / Special Limit must have a unique type'
    },
    earthquakeMessage: {
        id: 'quoteandbind.common.views.coverage-tooltip.Coverage is limited to $5,000 for Personal Property and $1,500 for Additional Living Expenses. The deductible is $750. The base policy does not provide coverage for Earthquakes.',
        defaultMessage: 'Coverage is limited to $5,000 for Personal Property and $1,500 for Additional Living Expenses. The deductible is $750. The base policy does not provide coverage for Earthquakes.'
    },
    businessMessage: {
        id: 'quoteandbind.common.views.coverage-tooltip.This increases property coverage used primarily for business purposes, subject to limitations.',
        defaultMessage: 'This increases property coverage used primarily for business purposes, subject to limitations.'
    }
});
