import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import PrivacyInfoComponent from './PrivacyInfoComponent/PrivacyInfoComponent';

setComponentMapOverrides(
    {
        PrivacyInfoComponent: { component: 'PrivacyInfoComponent' }
    },
    {
        PrivacyInfoComponent
    }
);

export { default as StartQuoteComponent } from './StartQuoteComponent/StartQuoteComponent';
export { default as PrivacyInfoComponent } from './PrivacyInfoComponent/PrivacyInfoComponent';
export { default as RetrieveQuoteComponent } from './RetrieveQuoteComponent/RetrieveQuoteComponent';
export { default as LoadingModal } from './LoadingModal/LoadingModal';
