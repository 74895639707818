import { defineMessages } from 'react-intl';

export default defineMessages({

    pageTitle: {
        id: 'quoteandbind.ho.views.contact-preference.Contact Preference',
        defaultMessage: 'Contact Preference'
    },
    yourConsent: {
        id: 'quoteandbind.ho.views.contact-preference.Getting your consent',
        defaultMessage: 'Let us know how you would like to be contacted.'
    },
    withConsent: {
        id: 'quoteandbind.ho.views.contact-preference.With your consent Wawanesa Insurance will electronically deliver your documents',
        defaultMessage: 'With your consent Wawanesa Insurance will electronically deliver your policy documents, forms, billing statements, notices, claim-related documents and any other documents and communications permitted or required by law. Communications may contain documents or may provide access to an online account where a document or communication is available. Your consent is voluntary.'
    },
    withConsentOregon: {
        id: 'quoteandbind.ho.views.contact-preference.oregon.With your consent Wawanesa Insurance will electronically deliver your documents',
        defaultMessage: 'With your consent Wawanesa Insurance will electronically deliver your policy documents, forms, billing statements, notices, claim-related documents and any other documents and communications permitted or required by law. Communications may contain documents or may provide access to an online account where a document or communication is available. Your consent is voluntary. Oregon policyholders receiving a discount related to electronic delivery of documents, will no longer receive the discount if authorization is cancelled.'
    },
    toReceive: {
        id: 'quoteandbind.ho.views.contact-preference.To receive electronically delivered documents',
        defaultMessage: 'To receive electronically delivered documents you must have a computer or other access device with an internet connection, an active email account, and software which permits you to receive, access and retain Portable Document Format or (“PDF”) Files.'
    },
    emailOrCall: {
        id: 'quoteandbind.ho.views.contact-preference.You may email or call us at to do any of the following',
        defaultMessage: '&lt;p&gt;You may email us at &lt;a href="mailto:service.us@wawanesa.com"&gt;service.us@wawanesa.com&lt;/a&gt; or call us at &lt;a href="tel:{phoneNumberRaw}"&gt;{phoneNumber}&lt;/a&gt; to do any of the following:&lt;/p&gt;'
    },
    optOut: {
        id: 'quoteandbind.ho.views.contact-preference.Opt-out of receiving electronic documents at any time',
        defaultMessage: 'Opt-out of receiving electronic documents at any time'
    },
    updateEmail: {
        id: 'quoteandbind.ho.views.contact-preference.Update your email address to ensure you receive all communications',
        defaultMessage: 'Update your email address to ensure you receive all communications'
    },
    requestCopies: {
        id: 'quoteandbind.ho.views.contact-preference.Request free paper copies of your policy documents and communications',
        defaultMessage: 'Request free paper copies of your policy or claim-related documents and communications'
    },
    radioQuestion: {
        id: 'quoteandbind.ho.views.contact-preference.Would you like to receive your documents and communication by email?',
        defaultMessage: 'Would you like to receive your documents and communication by email?'
    },
    radioYes: {
        id: 'quoteandbind.common.views.change-profile.radioYes',
        defaultMessage: '&lt;span class="ww-quote-choice positive"&gt;&lt;strong&gt;YES&lt;/strong&gt; - I want to receive my documents electronically. By clicking here I confirm that I have read the information above and consent to the electronic delivery of documents and communications from Wawanesa Insurance which will be sent to {email}.&lt;/span&gt;'
    },
    radioNo: {
        id: 'quoteandbind.common.views.change-profile.radioNo',
        defaultMessage: '&lt;span class="ww-quote-choice negative"&gt;&lt;strong&gt;NO&lt;/strong&gt; - I do not want to receive my documents electronically and want to receive communications via US mail.&lt;/span&gt;'
    },
    loadingYourQuote: {
        id: 'quoteandbind.common.views.change-profile.Loading your quote...',
        defaultMessage: 'Loading your quote...'
    }
});
