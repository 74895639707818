// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICContactPreferences__radioOption{margin-left:2.23em;margin-top:-1.85em}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-ho-react/pages/WMICContactPreferences/WMICContactPreferences.module.scss"],"names":[],"mappings":"AAAA,0CACI,kBAAA,CACA,kBAAA","sourcesContent":[".radioOption {\n    margin-left: 2.23em;\n    margin-top: -1.85em;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioOption": "app__WMICContactPreferences__radioOption"
};
export default ___CSS_LOADER_EXPORT___;
