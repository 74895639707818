import { defineMessages } from 'react-intl';

export default defineMessages({
    startOverLinkText: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.Start Over',
        defaultMessage: 'Start Over'
    },
    yourEstimateTitle: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.Here is your estimate',
        defaultMessage: 'Here is your estimate'
    },
    sendEmail: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.Send Email',
        defaultMessage: 'Send Email'
    },
    proceedToFullQuote: {
        id: 'quoteandbind.quickquote.pa.views.pa-quick-quote.Proceed to Full Quote',
        defaultMessage: 'Proceed to Full Quote'
    },
    quoteInformationText: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.Let us email you its number together with a link to continue from where you left off.',
        defaultMessage: 'Let us email you its number {quoteID} together with a link to continue from where you left off.'
    },
    quoteEmailed: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.Quote Emailed',
        defaultMessage: 'Quote Emailed'
    },
    emailedTO: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.The quote details have been emailed to',
        defaultMessage: 'The quote details have been emailed to {emailAddress1}.'
    },
    monthly: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.Monthly',
        defaultMessage: 'Monthly'
    },
    annualTotalCost: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.Annual total cost',
        defaultMessage: 'Annual total cost'
    },
    sendEstimateToEmail: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.Send this estimate to your email',
        defaultMessage: 'Send this estimate to your email'
    },
    estimateGeneralInfo: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.This estimate is for general information only.',
        defaultMessage: 'This estimate is for general information only. To provide you with a full quote, we need to ask you a few more questions.'
    },
    mailPlaceholder: {
        id: 'quoteandbind.quickquote.pa.directives.Estimate.Mail Placeholder',
        defaultMessage: 'mail@mail.com'
    }
});
