// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__GTPPreviousSteps__stepContainer{align-items:center;display:flex;justify-content:space-between;padding:var(--GW-LAYOUT-4) 0}.app__GTPPreviousSteps__stepValue{color:var(--GW-COLOR-WARNING-CONTRAST);font-weight:var(--GW-FONT-WEIGHT-REGULAR);margin-left:var(--GW-SPACING-5)}.app__GTPPreviousSteps__arrayIconContainer{display:inline-flex;flex-direction:row}.app__GTPPreviousSteps__icons{background-color:var(--GW-BACKGROUND-COLOR-BODY);border-radius:50%;margin:var(--GW-SPACING-2);padding:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-gtp-react/components/GTPPreviousSteps/GTPPreviousSteps.module.scss"],"names":[],"mappings":"AAAA,sCAEI,kBAAA,CADA,YAAA,CAEA,6BAAA,CACA,4BAAA,CAGJ,kCAGI,sCAAA,CADA,yCAAA,CADA,+BAEA,CAGJ,2CACI,mBAAA,CACA,kBAAA,CAGJ,8BACI,gDAAA,CAGA,iBAAA,CADA,0BAAA,CADA,2BAEA","sourcesContent":[".stepContainer {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: var(--GW-LAYOUT-4) 0;\n}\n\n.stepValue {\n    margin-left: var(--GW-SPACING-5);\n    font-weight: var(--GW-FONT-WEIGHT-REGULAR);\n    color: var(--GW-COLOR-WARNING-CONTRAST);\n}\n\n.arrayIconContainer {\n    display: inline-flex;\n    flex-direction: row;\n}\n\n.icons {\n    background-color: var(--GW-BACKGROUND-COLOR-BODY);\n    padding: var(--GW-SPACING-3);\n    margin: var(--GW-SPACING-2);\n    border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepContainer": "app__GTPPreviousSteps__stepContainer",
	"stepValue": "app__GTPPreviousSteps__stepValue",
	"arrayIconContainer": "app__GTPPreviousSteps__arrayIconContainer",
	"icons": "app__GTPPreviousSteps__icons"
};
export default ___CSS_LOADER_EXPORT___;
