// fmoreno TO DO => PaymenService.js from Wawanesa's code.

import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { WMICJsonRPCService } from 'wmic-portals-transport-js';


export default class WMICOneIncPaymentService {
    static getOneIncSessionDetails(oneIncSessionDetailsDTO, additionalHeaders = {}) {
        return WMICJsonRPCService.send(
            getProxiedServiceUrl('quote'), 'getOneIncSessionDetails', [oneIncSessionDetailsDTO], additionalHeaders
        );
    }
}
