import { defineMessages } from 'react-intl';

export default defineMessages({
    addressLabel: {
        id: 'wmic.quoteandbind.ho.views.contact-details.Enter your primary residence for this policy.',
        defaultMessage: 'Enter your primary residence for this policy.',
    },
    coverageStartDate: {
        id: 'wmic.quoteandbind.views.contact-details.I would like my coverage to start on',
        defaultMessage: "I'd like my coverage to start on \u002d mm/dd/yyyy"
    },
    description: {
        id: 'quoteandbind.views.contact-details.Description',
        defaultMessage: 'Description'
    },
    lastName: {
        id: 'quoteandbind.views.yourinfo.Last Name',
        defaultMessage: 'Last Name'
    },
    FirstName: {
        id: 'quoteandbind.views.yourinfo.First Name',
        defaultMessage: 'First Name'
    },
    mailingAddressSameAsResidence: {
        id: 'wmic.quoteandbind.views.contact-details.Mailing address same as residence',
        defaultMessage: 'Is your mailing address the same as your residence?'
    },
    addAdditionalNamedInsured: {
        id: 'wmic.quoteandbind.views.contact-details.Would you like to add coverage for another person to your policy?',
        defaultMessage: 'Would you like to add coverage for another person to your policy?'
    },
    additionalNamedOtherMessage: {
        id: 'wmic.quoteandbind.views.contact-details.This individual does not qualify for coverage, and must obtain their own policy.',
        defaultMessage: 'This individual does not qualify for coverage, and must obtain their own policy.'
    },
    additionalNamedInsured: {
        id: 'wmic.quoteandbind.ho.views.contact-details.An additional Named insured is any  person to which a policy\'s coverage has been extended.',
        defaultMessage: 'An additional Named insured is any  person to which a policy\'s coverage has been extended.'
    },
    relationshipToPNI: {
        id: 'quoteandbind.views.contact-details.Relationship to the Primary Named Insured',
        defaultMessage: 'Relationship to the Primary Named Insured'
    },
    relationshipToPNIValidationMessage: {
        id: 'quoteandbind.views.contact-details.Please select the Relationship to the Primary Named Insured',
        defaultMessage: 'Please select the Relationship to the Primary Named Insured'
    },
    ANIAddressSameAsPNI: {
        id: 'wmic.quoteandbind.views.contact-details.Is the Additional Named Insured address the same as the Primary Named Insured',
        defaultMessage: 'Is the Additional Named Insured address the same as the Primary Named Insured?'
    },
    occupation: {
        id: 'wmic.quoteandbind.views.contact-details.occupation',
        defaultMessage: 'Occupation'
    },
    tellUsMore: {
        id: 'wmic.quoteandbind.ho.views.contact-details.Tell us more about yourself',
        defaultMessage: "Tell us more about yourself and we'll get your quote started."
    },
    thePrimaryNamedInsured: {
        id: 'wmic.quoteandbind.ho.views.contact-details.The Primary Named Insured',
        defaultMessage: 'The Primary Named Insured refers to the individual occupying the residence and must be of legal age to enter into the contract. Additional responsibilities include the receipt of cancellation notices and premium payment obligations.'
    },
    wawanesaCollects: {
        id: 'quoteandbind.views.contact-details.tooltip.Wawanesa collects your email for account purposes only',
        defaultMessage: 'Wawanesa collects your email for account purposes only.'
    },
    theInsuranceScore: {
        id: 'wmic.quoteandbind.ho.views.contact-details.We collect information',
        defaultMessage: 'We collect information from consumer agencies about persons named as an insured, including an insurance score, and are required to notify insureds this information is being collected. You can request a written statement regarding how we use insurance scores. By indicating "I AGREE" below, you acknowledge and agree that you have the authority to receive notices on behalf of those named as an insured.'
    },
    agreeCheckbox: {
        id: 'wmic.quoteandbind.ho.views.insurance-score.I agree',
        defaultMessage: 'I AGREE'
    },
    covAvailable: {
        id: 'wmic.quoteandbind.ho.Coverage Available',
        defaultMessage: 'Checking if coverage is available for that date...'
    },
    nooffer: {
        id: 'wmic.quoteandbind.ho.views.contact-details.Wawanesa does not offer insurance in your state',
        defaultMessage: 'Wawanesa doesn’t offer insurance in your state.'
    },
    notCovered: {
        id: 'wmic.quoteandbind.ho.views.contact-details.Your ZIP Code is not covered.',
        defaultMessage: 'Thanks for thinking of Wawanesa Insurance'
    },
    notOfferCoverage: {
        id: 'wmic.quoteandbind.ho.views.contact-details.We do not currently offer coverage',
        defaultMessage: "Unfortunately, Wawanesa does not offer renters insurance in your area.",
    },
    wereSorryTitle: {
        id: "wmic.quoteandbind.ho.views.contact-details.We're sorry",
        defaultMessage: "We're sorry"
    },
    policyholdMessage: {
        id: 'wmic.quoteandbind.ho.views.contact-details.Policy Hold Message',
        defaultMessage: '&lt;strong&gt;Wawanesa is not currently offering new renters policies in your area.&lt;/strong&gt;&lt;br/&gt;&lt;br/&gt;Please try again in 30 days. We apologize for the inconvenience and appreciate your understanding.'
    },
    emailHint: {
        id: 'quoteandbind.views.contact-details.Collect Email',
        defaultMessage: 'Wawanesa collects your email for account purposes only.'
    },
    namedInsuredHint: {
        id: 'quoteandbind.views.contact-details.Named Insured Hint',
        defaultMessage: 'If you share ownership of your property, additional owners will only receive insurance benefits if they are included in your policy.'
    },
    calendarHint: {
        id: 'quoteandbind.views.contact-details.Calendar Hint',
        defaultMessage: 'Your insurance policy must start within the next 30 days.'
    },
    dateChangedModalTitle: {
        id: 'quoteandbind.views.contact-details.We have updated your policy effective date',
        defaultMessage: "We've updated your policy effective date"
    },
    dateChangedModalMessage: {
        id: 'quoteandbind.views.contact-details.The policy effective date you originally selected',
        defaultMessage: 'Your selected policy effective date is no longer available. It has been updated to {updatedEffectiveDate}.'
    },
    loadingQuote: {
        id: 'quoteandbind.controllers.ContactDetailsCtrl.Loading your quote...',
        defaultMessage: 'Loading your quote...'
    },
    startingQuote: {
        id: 'quoteandbind.controllers.ContactDetailsCtrl.Starting your quote...',
        defaultMessage: 'Starting your quote...'
    },
    invalidDate: {
        id: 'quoteandbind.views.yourinfo.Input value is not valid date',
        defaultMessage: 'Please enter a valid date'
    },
    requiredDate: {
        id: 'quoteandbind.views.yourinfo.Please enter your date of birth',
        defaultMessage: 'Please enter your date of birth'
    },
    invalidEmail: {
        id: 'quoteandbind.views.yourinfo.Value must be a valid email address',
        defaultMessage: 'Value must be a valid email address'
    },
    noEmail: {
        id: 'quoteandbind.views.yourinfo.Please enter your email address',
        defaultMessage: 'Please enter your email address'
    }
});
