import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'quoteandbind.views.recurring-payment-authorization.Monthly Automated Recurring Payment Authorization',
        defaultMessage: 'Monthly Automated Recurring Payment Authorization'
    },
    pageIntro: {
        id: 'quoteandbind.views.recurring-payment-authorization.Authorize the monthly automated recurring payments by reading and electronically signing an authorization form.',
        defaultMessage: 'Authorize the monthly automated recurring payments by reading and electronically signing an authorization form.'
    },
    emailSent: {
        id: 'quoteandbind.views.recurring-payment-authorization.We have sent an email containing instructions for electronically signing the monthly automated recurring payment authorization form.',
        defaultMessage: 'We have sent an email containing instructions for electronically signing the monthly automated recurring payment authorization form.'
    },
    ensureSteps: {
        id: 'quoteandbind.views.recurring-payment-authorization.Ensure the Payor follows these steps to provide authorization:',
        defaultMessage: 'Ensure the Payor follows these steps to provide authorization:'
    },
    needHelp: {
        id: 'quoteandbind.views.recurring-payment-authorization.If you need help',
        defaultMessage: "&lt;span&gt;If you need help, contact Wawanesa at {phoneNumber} and we will be happy to assist you. Please have your &lt;span class='bold'&gt;Quote Number {quoteNumber}&lt;/span&gt; ready.&lt;/span&gt;"
    },
    authorizationInstructionsEmailed: {
        id: 'quoteandbind.views.recurring-payment-authorization.Authorization instructions have been emailed',
        defaultMessage: 'Authorization instructions have been emailed'
    },
    authorizationInstructionsSent: {
        id: 'quoteandbind.views.recurring-payment-authorization.Authorization instructions have been sent',
        defaultMessage: 'Authorization instructions have been sent to {emailAddress}'
    },
    retrievingSigningStatus: {
        id: 'quoteandbind.controllers.recurring-payment-authorization.Retrieving signing status...',
        defaultMessage: 'Retrieving signing status...'
    },
    retrievingSubmission: {
        id: 'quoteandbind.controllers.recurring-payment-authorization.Retrieving submission...',
        defaultMessage: 'Retrieving submission...'
    },
    resendEmail: {
        id: 'quoteandbind.controllers.recurring-payment-authorization.Resend email',
        defaultMessage: 'Resend email'
    },
    proceedToNextStep: {
        id: 'quoteandbind.controllers.recurring-payment-authorization.Proceed to next step',
        defaultMessage: 'Proceed to next step'
    },
    continue: {
        id: 'quoteandbind.controllers.recurring-payment-authorization.Continue',
        defaultMessage: 'Continue'
    },
    serviceTemporarilyUnavailable: {
        id: 'quoteandbind.controllers.recurring-payment-authorization.Online Service Temporarily Unavailable',
        defaultMessage: 'Online Service Temporarily Unavailable'
    },
    onlineServiceUnavailable: {
        id: 'quoteandbind.controllers.recurring-payment-authorization.Online Service Unavailable',
        defaultMessage: 'The online service to complete this transaction is temporarily unavailable.'
    },
    onlinePaymentPlanUnavailable: {
        id: 'quoteandbind.controllers.recurring-payment-authorization.Online Payment Plan Unavailable',
        defaultMessage: '&lt;span&gt;To complete your purchase and enroll in our Monthly Automated Recurring Payment Plan, contact our Quote Specialists directly at {phoneNumber}. Please have your &lt;b&gt;Quote Number {quoteNumber}&lt;/b&gt; ready.\n\n To complete your purchase online, please select another payment plan.&lt;/span&gt;'
    }
});