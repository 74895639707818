import React, { useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { GuidanceService } from 'gw-capability-guidance';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuickQuoteWizardPageTemplate } from 'gw-capability-quoteandbind-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import GuidanceToProductPageTemplate from '../../templates/GuidanceToProductPageTemplate';
import metadata from './QuestionsPage.metadata.json5';
import './QuestionsPage.messages';
import messages from '../../GTPWizard.messages';

function QuestionsPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerComponentValidation
    } = useValidation('QuestionsPage');
    const { wizardData: submissionVM, updateWizardData } = props;

    const isValid = useCallback(() => {
        const questions = _.get(submissionVM, 'questions.value');
        const validQuestions = questions.filter((question) => question.answer);
        return !_.isEmpty(validQuestions);
    }, [submissionVM]);

    const onNext = useCallback(async () => {
        submissionVM.value = await GuidanceService.populateRecommendedProducts(submissionVM.value);
        return submissionVM;
    }, [submissionVM]);

    const updateQuestion = useCallback(
        (value, path) => {
            _.set(submissionVM, `${path}.value`, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            updateQuestion: updateQuestion
        }
    };

    useEffect(() => {
        registerComponentValidation(isValid);
    }, [isValid, registerComponentValidation]);

    return (
        <WizardPage
            showPrevious={false}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
            nextLabel={messages.continue}
            template={
                breakpoint === 'phoneWide' || breakpoint === 'phone'
                    ? QuickQuoteWizardPageTemplate
                    : GuidanceToProductPageTemplate
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

QuestionsPage.propTypes = wizardProps;
export default QuestionsPage;
