import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { AddressLookupService } from 'gw-capability-address';
import { GuidanceService } from 'gw-capability-guidance';
import { QuickQuoteWizardPageTemplate } from 'gw-capability-quoteandbind-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import GuidanceToProductPageTemplate from '../../templates/GuidanceToProductPageTemplate';
import metadata from './YourAddressPage.metadata.json5';

import './YourAddressPage.messages';
import messages from '../../GTPWizard.messages';
import styles from './YourAddressPage.module.scss';

function YourAddressPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        isComponentValid,
        onValidate,
        initialValidation,
        registerComponentValidation
    } = useValidation('YourAddressPage');
    const [isVMInitialized, updateVMInitialized] = useState(false);
    const [isManualEntryMode, setShowManualEntryMode] = useState(false);
    const { wizardData: submissionVM, updateWizardData } = props;
    const localeService = ServiceManager.getService('locale-service');

    useEffect(() => {
        const defaultAddress = {
            country: localeService.getDefaultCountryCode()
        };

        if (!isVMInitialized) {
            const filledAddress = _.get(submissionVM, 'userAddress');
            if (isManualEntryMode) {
                const initAddress = { ...defaultAddress, ...filledAddress.value };
                _.set(submissionVM, 'userAddress.value', initAddress);
            } else if (!filledAddress.aspects.valid || !filledAddress.aspects.subtreeValid) {
                _.set(submissionVM, 'userAddress.value', {});
            }
            updateWizardData(submissionVM);
            updateVMInitialized(true);
        }
    }, [localeService, isManualEntryMode, submissionVM, updateWizardData, isVMInitialized]);

    const searchItems = async (userInput) => {
        let results = [];
        if (userInput.length >= 2) {
            const response = await AddressLookupService.lookupAddressUsingString(userInput);
            results = _(response.matches || [])
                .sortBy('matchAccuracy')
                .map('address');
        }
        return results;
    };

    const handleUserSelection = (address, path) => {
        _.set(submissionVM, path, address);
        updateWizardData(submissionVM);
    };

    const renderItem = (address) => {
        return `${address.addressLine1}, ${address.city}, ${address.state}, ${address.postalCode}`;
    };

    const clearTypeaheadInput = useCallback(() => {
        _.set(submissionVM, 'userAddress.value', {});
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const onNext = useCallback(async () => {
        submissionVM.value = await GuidanceService.populateQuestions(submissionVM.value);
        return submissionVM;
    }, [submissionVM]);

    const toggleManualAddress = useCallback(() => {
        setShowManualEntryMode(!isManualEntryMode);
        updateVMInitialized(false);
    }, [isManualEntryMode]);

    const getFormValidity = useCallback(() => {
        const isFormValid = submissionVM.userAddress.aspects.valid
        && submissionVM.userAddress.aspects.subtreeValid;
        return isFormValid;
    }, [submissionVM]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            typeaheadSearchItems: searchItems,
            onHandleUserSelection: handleUserSelection,
            onRenderItem: renderItem,
            onClearTypeaheadInput: clearTypeaheadInput,
            toggleManualAddress
        }
    };

    const overrideProps = {
        addressLookup: {
            visible: !isManualEntryMode
        },
        manualAddressContainer: {
            visible: isManualEntryMode
        },
        addressNotInList: {
            visible: !isManualEntryMode
        },
        backToAddressSearch: {
            visible: isManualEntryMode
        },
        addressLine2: {
            showOptional: true
        },
        addressLine3: {
            showOptional: true
        }
    };

    return (
        <WizardPage
            showPrevious={false}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
            nextLabel={messages.continue}
            template={
                breakpoint === 'phoneWide' || breakpoint === 'phone'
                    ? QuickQuoteWizardPageTemplate
                    : GuidanceToProductPageTemplate
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

YourAddressPage.propTypes = wizardProps;
export default YourAddressPage;
