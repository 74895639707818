import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { QuickQuoteWizardPageTemplate } from 'gw-capability-quoteandbind-common-react';
import { EmailQuoteService, LoadSaveService } from 'gw-capability-quoteandbind';
import { MockUpUtil } from 'wmic-portals-utils-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './YourQuotePage.metadata.json5';
import styles from './YourQuotePage.module.scss';
import messages from './YourQuotePage.messages';
import SLQEstimationImgSrc from './images/product_quickquote.svg';

function YourQuotePage(props) {
    const modalApi = useModal();
    const translator = useTranslator();
    const {
        wizardData, updateWizardData, jumpTo, history
    } = props;
    const { quoteVM } = wizardData;

    const isEmailValid = () => {
        const emailAddress1VMNode = _.get(quoteVM, 'baseData.accountHolder.emailAddress1');
        return (
            !_.isEmpty(emailAddress1VMNode.value)
            && emailAddress1VMNode.aspects.valid
            && emailAddress1VMNode.aspects.subtreeValid
        );
    };

    const startOver = () => {
        const initialWizardStepID = 0;
        jumpTo(initialWizardStepID);
    };

    const sendEmail = () => {
        const quoteID = _.get(quoteVM, 'quoteID.value');
        const sessionID = _.get(quoteVM, 'sessionUUID.value');
        const emailAddress1 = _.get(quoteVM, 'baseData.accountHolder.emailAddress1.value');

        const emailInfo = {
            quoteID: quoteID,
            sessionID: sessionID,
            emailAddress1: emailAddress1
        };

        EmailQuoteService.emailQuote(emailInfo)
            .then(() => {
                modalApi.showAlert({
                    title: messages.quoteEmailed,
                    message: translator(messages.emailedTO, { emailAddress1 }),
                    status: 'info',
                    icon: 'mi-error-outline'
                }).catch(_.noop);
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.error,
                    message: messages.cantSendQuoteDetailsAtThisTime,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            });
    };

    const getTranslatedTextWithQuoteID = () => {
        const quoteID = _.get(quoteVM, 'quoteID.value');
        return translator(messages.quoteInformationText, { quoteID });
    };

    const onNext = async () => {
        const quoteID = _.get(quoteVM, 'quoteID.value');
        const sessionUUID = _.get(quoteVM, 'sessionUUID.value');

        quoteVM.value = await LoadSaveService.setQuoteToDraft(quoteID, sessionUUID);
        quoteVM.value = MockUpUtil.cleanUpMockedProperties(
            quoteVM.value,
            'quote.slq',
            'baseData.accountHolder.emailAddress1',
            'baseData.accountHolder.firstName',
            'baseData.accountHolder.lastName',
            'baseData.accountHolder.licenseNumber',
            'baseData.accountHolder.licenseState',
            'baseData.accountHolder.gender'
        );
        _.set(quoteVM, 'baseData.accountHolder.dateOfBirth.value', undefined);
        history.replace({
            pathname: '/quote-pa',
            state: {
                submission: quoteVM.value
            }
        });
    };

    const overrideProps = {
        slqSendEmailButton: {
            disabled: !isEmailValid()
        },
        slqSendToYourEmailDetail: {
            content: getTranslatedTextWithQuoteID()
        },
        slqEstimationImage: {
            src: SLQEstimationImgSrc,
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onStartOver: startOver,
            onSendEmail: sendEmail
        }
    };

    const handleModelChange = useCallback(
        (newModel) => {
            updateWizardData({
                ...wizardData,
                quoteVM: newModel
            });
        },
        [wizardData, updateWizardData]
    );

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, quoteVM, id, path, overrideProps);
        },
        [quoteVM, overrideProps]
    );

    return (
        <WizardPage
            template={QuickQuoteWizardPageTemplate}
            nextLabel={translator(messages.proceedToFullQuote)}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={quoteVM}
                onModelChange={handleModelChange}
                overrideProps={overrideProps}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

YourQuotePage.propTypes = wizardProps;
export default withRouter(YourQuotePage);
