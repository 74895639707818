import { defineMessages } from 'react-intl';

export default defineMessages({
    checkEmail: {
        id: 'quoteandbuy.PaymentSteps.checkEmail',
        defaultMessage: 'Check email account:'
    },
    email: {
        id: 'quoteandbuy.PaymentSteps.email',
        defaultMessage: '{email}'
    },
    notPayorAddress: {
        id: 'quoteandbuy.PaymentSteps.notPayorAddress',
        defaultMessage: 'Not the payor\'s email address?'
    },
    editEmail: {
        id: 'quoteandbuy.PaymentSteps.editEmail',
        defaultMessage: 'Edit Email Address'
    },
    openEmail: {
        id: 'quoteandbuy.PaymentSteps.openEmail',
        defaultMessage: 'Open the email from\n {email}\n and follow the instructions'
    },
    emailNotReceived: {
        id: 'quoteandbuy.PaymentSteps.emailNotReceived',
        defaultMessage: 'Payor hasn\'t received the email?'
    },
    resendEmail: {
        id: 'quoteandbuy.PaymentSteps.resendEmail',
        defaultMessage: 'Resend Email'
    },
    readAndSign: {
        id: 'quoteandbuy.PaymentSteps.readAndSign',
        defaultMessage: 'Read and sign the authorization form'
    },
    payorAlreadySigned: {
        id: 'quoteandbuy.PaymentSteps.payorAlreadySigned',
        defaultMessage: 'Payor has already signed?'
    },
    proceedToNextStep: {
        id: 'quoteandbuy.PaymentSteps.proceedToNextStep',
        defaultMessage: 'Proceed to Next Step'
    }
});