// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__QuotePage__startDate{align-items:baseline;padding:0;width:auto}.app__QuotePage__startDate div:first-child{flex:inherit}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-ho-react/pages/Quote/QuotePage.module.scss"],"names":[],"mappings":"AAAA,2BAGE,oBAAA,CADA,SAAA,CADA,UAEA,CAEA,2CACE,YAAA","sourcesContent":[".startDate {\n  width: auto;\n  padding: 0;\n  align-items: baseline;\n\n  div:first-child {\n    flex: inherit;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"startDate": "app__QuotePage__startDate"
};
export default ___CSS_LOADER_EXPORT___;
