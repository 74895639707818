import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { WMICJsonRPCService } from 'wmic-portals-transport-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WMICESignatureService {
    static getESignatureStatus(
        quoteID,
        sessionUUID,
        orderID,
        session,
        additionalHeaders = {}
    ) {
        const esignatureStatusRequest = {
            quoteID,
            sessionUUID,
            orderID
        };
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'getESignatureStatus',
            [esignatureStatusRequest, session],
            additionalHeaders
        );
    }

    static resendESignature(
        quoteID,
        sessionUUID,
        email,
        additionalHeaders = {}
    ) {
        const esignatureStatusRequest = {
            quoteID,
            sessionUUID,
            email
        };
        return WMICJsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'resendESignature',
            [esignatureStatusRequest],
            additionalHeaders
        );
    }
}
