import React from 'react';
import PropTypes from 'prop-types';
import {
    ModalNext,
    ModalHeader,
    ModalBody
} from '@jutro/components';
import styles from './LoadingModal.module.scss';

export function LoadingModal(props) {
    const {
        modalTitle,
        isOpen
    } = props;

    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader
                title={modalTitle}
            />
            <ModalBody>
                <div className={styles.span2} />
                <div className={styles.span8}>
                    <div className="progress progress-striped active">
                        <div className="bar" />
                    </div>
                </div>
                <div className={styles.span2} />
            </ModalBody>
        </ModalNext>
    );
}

LoadingModal.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default LoadingModal;
