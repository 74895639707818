/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl';

export default defineMessages({

    pageDescription: {
        id: 'quoteandbind.common.views.quote-details.page-description',
        defaultMessage: 'You can now make adjustments to your deductible and coverages to customize based on your specific needs.'
    },
    actualLossSustained: {
        id: 'quoteandbind.ho.directives.ho-main-coverages.Actual Loss Sustained up to 12 months - Included',
        defaultMessage: 'Actual Loss Sustained up to 12 months - Included'
    },
    quoteNumber: {
        id: 'quoteandbind.common.views.quote-details.print.Quote number',
        defaultMessage: 'Quote # {quoteID}'
    },
    quotePrice: {
        id: 'quoteandbind.common.views.quote-details.print.Quote price',
        defaultMessage: '${quotePrice}'
    },
    quoteTotalPrice: {
        id: 'quoteandbind.common.views.quote-details.print.Quote total price',
        defaultMessage: '${quoteTotalPrice} for 12 months'
    },
    effDateText: {
        id: 'quoteandbind.common.views.quote-details.print.Based on the information you provided',
        defaultMessage: 'Based on the information you provided, this is your premium for 12 months of coverage from Wawanesa Insurance, effective {effectiveDate}.'
    },
    retrieveQuoteOnlineLink: {
        id: 'quoteandbind.common.views.quote-details.print.retrieve quote link',
        defaultMessage: '&lt;a href="https://www.wawanesa.com/retrievequote-{stateCode}"&gt;www.wawanesa.com/retrievequote-{stateCode}&lt;/a&gt;'
    },
    deductibles: {
        id: 'wmic.quoteandbind.views.payment-details.Deductibles',
        defaultMessage: 'Deductibles'
    },
    description: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Description',
        defaultMessage: 'Description'
    },
    limit: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Limit',
        defaultMessage: 'Limit'
    },
    premium: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Premium',
        defaultMessage: 'Premium'
    },
    limitedEarthquake: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.coverage.Limited Coverage for Earthquake',
        defaultMessage: 'Limited Coverage for Earthquake'
    },
    included: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Included',
        defaultMessage: 'Included'
    },
    earthquakeCoverageLimit: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Earthquake Coverage Limit',
        defaultMessage: '$5,000 / $1,500'
    },
    actualLoss: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Actual Loss Sustained up to 12 Months',
        defaultMessage: 'Actual Loss Sustained up to 12 Months'
    },
    policyDeductible: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Policy Deductible',
        defaultMessage: 'Policy Deductible'
    },
    earthquakeDeductible: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Earthquake Deductible',
        defaultMessage: 'Earthquake Deductible'
    },
    homeProtectionDeductible: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Home Systems Protection Deductible',
        defaultMessage: 'Home Systems Protection Deductible'
    },
    identityFraudDeductible: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Identity Fraud Expense Deductible',
        defaultMessage: 'Identity Fraud Expense Deductible'
    },
    deductibleAmountHeader: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Amount',
        defaultMessage: 'Amount'
    },
    optionalSpecialCoverages: {
        id: 'quoteandbind.ho.directives.ho-additional-coverages.CA.Increased Special Limits of Liability',
        defaultMessage: 'Optional Coverages/Special Increased Limits'
    },
    addCoverages: {
        id: 'quoteandbind.ho.directives.ho-additional-coverages.Add Coverages',
        defaultMessage: 'Add Coverages'
    },
    articleProperty: {
        id: 'quoteandbind.ho.directives.ho-additional-coverages.Article or Property Type',
        defaultMessage: 'Article or Property Type'
    },
    addlLimit: {
        id: 'quoteandbind.ho.directives.ho-additional-coverages.Additional Limit',
        defaultMessage: 'Additional Limit'
    },
    delete: {
        id: 'quoteandbind.ho.directives.ho-additional-coverages.Delete',
        defaultMessage: 'Delete'
    },
    baseCoverages: {
        id: 'quoteandbind.ho.directives.ho-additional-coverages.Base Coverages',
        defaultMessage: 'Base Coverages'
    },
    personalProperty: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Personal Property',
        defaultMessage: 'This amount provides coverage to replace your personal property in the event of a fire, theft or other covered loss.'
    },
    personalPropertyCA: {
        id: 'quoteandbind.ho.directives.ho-coverages.help-text.CA.Personal Property.Limit',
        defaultMessage: 'This amount provides coverage to replace your personal property in the event of a fire, theft or other covered loss. You must enter a value between $15,000 and $210,000.'
    },
    addlLivingExpenses: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Additional Living Expenses',
        defaultMessage: 'This covers the cost of living expenses that would be incurred if you were to be temporarily displaced from your residence.'
    },
    personalLiability: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Personal Liability',
        defaultMessage: 'This protects you from claims or lawsuits brought against you for damages that cause bodily injury or property damage where you are liable. Select an amount that you believe will cover your risk.'
    },
    personalPropertyModalTitle: {
        id: 'quoteandbind.common.components.premium-box.personalPropertyModalTitle',
        defaultMessage: 'Oops ...'
    },
    personalPropertyModal1: {
        id: 'quoteandbind.common.components.premium-box.personalPropertyModal1',
        defaultMessage: 'Personal Property Coverage Included Limit Value must be greater than or equal to the minimum amount of $15,000.'
    },
    personalPropertyModal2: {
        id: 'quoteandbind.common.components.premium-box.personalPropertyModal2',
        defaultMessage: 'Personal Property Coverage Included Limit Value must be less than or equal to the maximum amount of $210,000.'
    },
    coveragesInputErrorModalTitle: {
        id: 'quoteandbind.common.components.premium-box.coveragesInputErrorModalTitle',
        defaultMessage: 'Invalid input values'
    },
    coveragesInputErrorModalDescription: {
        id: 'quoteandbind.common.components.premium-box.coveragesInputErrorModalDescription',
        defaultMessage: 'Please correct input field errors'
    },
    medicalPayments: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Medical Payments',
        defaultMessage: 'This provides coverages up to the limit of liability for necessary medical expenses as a result of an accidental injury to another.'
    },
    creditCard: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Credit Card',
        defaultMessage: 'This provides coverage for losses created by your legal obligation to pay expenses due to theft or unauthorized use of your credit cards.'
    },
    ltdEarthquake: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Limited Earthquake',
        defaultMessage: 'Coverage is limited to $5,000 for Personal Property and $1,500 for Additional Living Expenses. The base policy does not provide coverage for Earthquake.'
    },
    ltdCoverageForEarthquake: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Limited Coverage for Earthquake',
        defaultMessage: 'Coverage is limited to $5,000 for Personal Property and $1,500 for Additional Living Expenses. The base policy does not provide coverage for Earthquake.'
    },
    businessProperty: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Increased Limits on Business Property',
        defaultMessage: 'This increases property coverage used primarily for business purposes.'
    },
    specialLiabilityOnProperty: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Increased Special Limits of Liability on Property',
        defaultMessage: 'This provides increased coverage limits for selected property items'
    },
    email: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Email',
        defaultMessage: 'This email can be updated in Your Info.'
    },
    deductible: {
        id: 'quoteandbind.ho.directives.policy-info.help-text.Deductible',
        defaultMessage: 'The deductible is the dollar amount that you will be responsible for in the event of a covered loss.'
    },
    personalPropertyOR: {
        id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Personal Property.Limit',
        defaultMessage: 'This amount provides coverage to replace your personal property in the event of a fire, theft or other covered loss. You must enter a value greater than the selected deductible.'
    },
    personalLiabilityCA: {
        id: 'quoteandbind.ho.directives.ho-coverages.help-text.CA.Personal Liability.Limit',
        defaultMessage: 'This protects you from claims or lawsuits brought against you for damages that cause bodily injury or property damage where you are liable. Select an amount that you believe will cover your risk.'
    },
    personalLiabilityOR: {
        id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Personal Liability.Limit',
        defaultMessage: 'This protects you from claims or lawsuits brought against you for damages that cause bodily injury or property damage where you are liable. Select an amount that you believe will cover your risk.'
    },
    earthquakeHelpTooltip: {
        id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Earthquake.Limit',
        defaultMessage: 'This endorsement provides coverage for losses resulting from earthquake, which is excluded under the base policy. Coverage must be purchased to equal the amount of contents coverage purchased and is subject to a separate deductible.'
    },
    homeSystemsProtectionLimit: {
        id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Home Systems Protection.Limit',
        defaultMessage: 'Provides coverage for sudden and accidental breakdown that causes direct physical damage to personal property, including but not limited to: alarm systems, appliances, computers, entertainment systems, electronics, exercise equipment, power equipment. The limit for Home Systems Protection coverage is $50,000 with a $500 deductible.'
    },
    identityFraudHelp: {
        id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Identity Fraud Expense.Limit',
        defaultMessage: 'Covers up to $25,000 for expenses incurred as the direct result of any one identity fraud during the policy period.  A $500 deductible applies.'
    },
    increasedLimitsOnBusiness: {
        id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Increased Limits on Business Property.Limit',
        defaultMessage: 'This increases property coverage used primarily for business purposes.'
    },
    personalPropertyReplacement: {
        id: 'quoteandbind.ho.directives.ho-coverages.help-text.OR.Personal Property Replacement Cost Loss Settlement.Limit',
        defaultMessage: 'Under the base policy,  in the event of a claim, the loss settlement applicable to Contents is Actual Cash Value. This means that depreciation will be applied, and considers factors such as age, condition, wear and tear etc.  When the Personal Property Replacement Cost Loss Settlement is added, the loss settlement is changed to Replacement Cost, meaning the cost to repair or replace without deduction for depreciation (except for property listed as not eligible).'
    },
    buyModalMessage: {
        id: 'wmic.quoteandbind.ho.directives.ho-coverages.Thank you for your patience while your quote is being finalized',
        defaultMessage: 'Thank you for your patience while your quote is being finalized'
    },
    toSpeakWithQuoteSpecialist: {
        id: 'quoteandbind.common.views.quote-details.print.To speak with a Quote Specialist',
        defaultMessage: 'To speak with a Quote Specialist, call {phoneNumber}.'
    }
});