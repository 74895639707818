import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICButton } from 'wmic-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useModal } from '@jutro/components';
import { useLocation } from 'react-router-dom';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { LoadingModal, WMICModal, WMICRetrieveQuote } from 'gw-capability-quoteandbind-common-react';
import { WMICRegionUrlUtil } from 'wmic-portals-url-js';
import { useTranslator } from '@jutro/locale';

import metadata from './RetrieveQuoteComponent.metadata.json5';
import styles from './RetrieveQuoteComponent.module.scss';
import PrivacyInfoComponent from '../PrivacyInfoComponent/PrivacyInfoComponent';
import messages from './RetrieveQuoteComponent.messages'

function RetrieveQuote() {
    const modalApi = useModal();
    const { state } = useLocation();
    const { onValidate, isComponentValid } = useValidation('RetrieveQuote');
    // const { paymentCallback } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const [isRetrieving, setIsRetrieving] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalProps, setModalProps] = useState({});
    const [viewModelContext] = useState({
        EmailRequired: true
    });

    const initRetrieveQuoteVM = useCallback(() => {

        if (state && state.postalCode && state.quoteID && state.email) {
            const retrieveData = {
                postalCode: state.postalCode,
                quoteID: state.quoteID,
                email: state.email,
            }
            return viewModelService.create(
                retrieveData,
                'pc',
                'wmic.edge.us.capabilities.quote.submission.dto.QuoteRetrievalDTO',
                viewModelContext
            );
        }
        return {};

    }, [state, viewModelContext, viewModelService]);

    const [retrieveQuoteVM, updateRetrieveQuoteVM] = useState(initRetrieveQuoteVM);

    const retrieveQuote = useCallback(() => {
        const postalCodeInt = parseInt(retrieveQuoteVM.value.postalCode.substring(0, 2));
        if (postalCodeInt < 90 || postalCodeInt > 97) {
            modalApi.showModal(<WMICModal
                title={messages.errorTitle}
                message={translator(messages.errorDescription)}
                iconClass="error-text fa-3x fa fa-exclamation-circle"
            />).catch(_.noop);
        } else {
            if (postalCodeInt >= 90 && postalCodeInt < 97) {
                WMICRegionUrlUtil.setRegionFromValue('CA');
            } else {
                WMICRegionUrlUtil.setRegionFromValue('OR');
            }
            setIsRetrieving(true);
        }
    }, [retrieveQuoteVM, translator]);

    useEffect(
        () => {
            if (state && state.paymentCallback) {
                retrieveQuote()
            } else if (viewModelService) {
                const retrieveQuoteViewModel = viewModelService.create(
                    {},
                    'pc',
                    'wmic.edge.us.capabilities.quote.submission.dto.QuoteRetrievalDTO',
                    viewModelContext
                );
                updateRetrieveQuoteVM(retrieveQuoteViewModel);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [viewModelService]);

    const writeValue = useCallback(
        (value, path) => {
            const newRetrieveQuoteVM = viewModelService.clone(retrieveQuoteVM);
            _.set(newRetrieveQuoteVM, path, value);
            updateRetrieveQuoteVM(newRetrieveQuoteVM);
        },
        [retrieveQuoteVM, updateRetrieveQuoteVM, viewModelService]
    );

    const writeNumberValue = useCallback(
        (value, path) => {
            const newRetrieveQuoteVM = viewModelService.clone(retrieveQuoteVM);
            _.set(newRetrieveQuoteVM, path, String(value).replace(/\D/, ''));
            updateRetrieveQuoteVM(newRetrieveQuoteVM);
        },
        [retrieveQuoteVM, updateRetrieveQuoteVM, viewModelService]
    );

    const writeNumberOrDashValue = useCallback(
        (value, path) => {
            const newRetrieveQuoteVM = viewModelService.clone(retrieveQuoteVM);
            _.set(newRetrieveQuoteVM, path, String(value).replace(/[^\d-]/, ''));
            updateRetrieveQuoteVM(newRetrieveQuoteVM);
        },
        [retrieveQuoteVM, updateRetrieveQuoteVM, viewModelService]
    );

    const onRetrieveQuote = () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return;
        }

        retrieveQuote();

    };

    const retrieveQuoteSubmission = useCallback(() => {
        return LoadSaveService.retrieveSubmission(retrieveQuoteVM.value)
    }, [retrieveQuoteVM]);

    if (isLoading) {
        return <LoadingModal {...modalProps} />;
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRetrieveQuote: onRetrieveQuote
        },
        resolveComponentMap: {
            privacyDisclaimerComponent: PrivacyInfoComponent,
            WMICButton
        },
    };

    const overrideProps = {
        retrieveQuoteZipCodeInput: {
            path: "postalCode",
            showErrors: showErrors && !(_.get(retrieveQuoteVM, "postalCode.aspects.valid", false)),
            onValueChange: writeNumberOrDashValue
        },
        retrieveQuoteSubmissionIDInput: {
            path: "quoteID",
            showErrors: showErrors && !(_.get(retrieveQuoteVM, "quoteID.aspects.valid", false)),
            onValueChange: writeNumberValue
        },
    };

    return (
        <>
            {isRetrieving && (
                <WMICRetrieveQuote
                    setIsRetrieving={setIsRetrieving}
                    retrieveSubmissionCallback={retrieveQuoteSubmission}
                    retrieveSubmissionRequest={retrieveQuoteVM.value}
                    paymentCallback={_.get(state, "paymentCallback")}
                />
            )}
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={retrieveQuoteVM}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
                overrideProps={overrideProps}
            />
        </>
    );
}

export default RetrieveQuote;