import { defineMessages } from 'react-intl';

export default defineMessages({
    yourHome: {
        id: 'quoteandbind.ho.wizard.step.Your Home',
        defaultMessage: 'Your Home'
    },
    construction: {
        id: 'quoteandbind.ho.wizard.step.Construction',
        defaultMessage: 'Construction'
    },
    discount: {
        id: 'quoteandbind.ho.wizard.step.Discount',
        defaultMessage: 'Discount'
    },
    additionalOptional: {
        id: 'quoteandbind.ho.views.rating.Additional (optional)',
        defaultMessage: 'Additional (optional)'
    },
    anErrorOccurred: {
        id: 'quoteandbind.ho.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.ho.wizard.error.title',
        defaultMessage: 'Error'
    },
    safetyNet: {
        id: 'quoteandbind.ho.views.rating.Trampoline has a safety net',
        defaultMessage: 'Does the trampoline have a safety net?'
    },
    policyInfo: {
        id: 'quoteandbind.wizard.step.Policy Info',
        defaultMessage: 'Policy Info'
    },
    contactPreference: {
        id: 'quoteandbind.wizard.step.Contact Preference',
        defaultMessage: 'Contact Preference'
    },
    yourDiscounts: {
        id: 'quoteandbind.wizard.step.Your Discounts',
        defaultMessage: 'Your Discounts'

    },
    areYouSure: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Are you sure',
        defaultMessage: "Are you sure you're done?"
    },
    exitMessage: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Information provided prior to this page will be saved and available upon your return. You may return and complete this quote at any time.',
        defaultMessage: 'Information provided prior to this page will be saved and available upon your return. You may return and complete this quote at any time.'
    },
    yourQuoteNumberIs: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Your Quote Number is',
        defaultMessage: 'Your Quote Number is {quoteNumber}.'
    },
    exitQuote: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Exit Quote',
        defaultMessage: 'Exit Quote'
    },
    continueQuote: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Continue Quote',
        defaultMessage: 'Continue Quote'
    },
    returnLabel: {
        id: 'quoteandbind.controllers.WizardFlowCtrl.Return Later',
        defaultMessage: 'Return Later'
    },
    recurringPaymentAuthorization: {
        id: 'quoteandbind.wizard.step.Authorization',
        defaultMessage: 'Authorization'
    }
});
