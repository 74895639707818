// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__YourAddressPage__addressHeader{margin-bottom:0}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-gtp-react/pages/YourAddress/YourAddressPage.module.scss"],"names":[],"mappings":"AAAA,qCACE,eAAA","sourcesContent":[".addressHeader {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addressHeader": "app__YourAddressPage__addressHeader"
};
export default ___CSS_LOADER_EXPORT___;
