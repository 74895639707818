// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__YourVehicle__contentContainer{padding-top:var(--GW-LAYOUT-6)}.app__YourVehicle__vehicleToggle .jut__Button__button{padding:initial}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-slq-react/pages/YourVehicle/YourVehicle.module.scss"],"names":[],"mappings":"AAAA,oCACE,8BAAA,CAGF,sDACE,eAAA","sourcesContent":[".contentContainer {\n  padding-top: var(--GW-LAYOUT-6);\n}\n\n.vehicleToggle :global(.jut__Button__button) {\n  padding: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": "app__YourVehicle__contentContainer",
	"vehicleToggle": "app__YourVehicle__vehicleToggle"
};
export default ___CSS_LOADER_EXPORT___;
