import { defineMessages } from 'react-intl';

export default defineMessages({
    youngestDriver: {
        id: 'quoteandbind.quickquote.pa.directives.Driver.What age is the youngest driver?',
        defaultMessage: 'What age is the youngest driver?',
    },
    termsAndConditions: {
        id: 'quoteandbind.quickquote.pa.directives.Driver.I agree to: Terms and Conditions',
        defaultMessage: 'I agree to: Terms and Conditions',
    },
    informationText: {
        id: 'quoteandbind.quickquote.pa.directives.Driver.Teenage drivers have the highest crash risk per mile traveled, compared to drivers in other age groups. Young drivers tend to overestimate their driving abilities and underestimate the dangers on the road',
        defaultMessage: 'Teenage drivers have the highest crash risk per mile traveled, compared to drivers in other age groups. Young drivers tend to overestimate their driving abilities and underestimate the dangers on the road',
    },
});
