// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PolicyInformationPage__vehicleContainer{margin-top:var(--GW-LAYOUT-6)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-quoteandbind-pa-react/pages/PolicyInformation/PolicyInformationPage.module.scss"],"names":[],"mappings":"AAAA,8CACI,6BAAA","sourcesContent":[".vehicleContainer {\n    margin-top: var(--GW-LAYOUT-6);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vehicleContainer": "app__PolicyInformationPage__vehicleContainer"
};
export default ___CSS_LOADER_EXPORT___;
