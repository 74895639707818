import React, { Component } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { RetrieveQuoteComponent } from 'wmic-components-qnb-common-react';
import { WMICProgressModal } from 'gw-capability-quoteandbind-common-react';
import { TranslatorContext } from '@jutro/locale';
import styles from './WMICRetrievePage.module.scss';
import metadata from './WMICRetrievePage.metadata.json5';

class WMICRetrievePage extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string
        }).isRequired
    };

    state = {
        formData: {
            lobCategory: 'personal'
        },
        isLoading: false,
        modalProps: {}
    };

    handleValueChange = (value, path) => {
        const { formData } = this.state;
        _.set(formData, path, value);
        this.setState({ formData });
    };

    readValue = (id, path) => {
        const { formData } = this.state;
        return _.get(formData, path);
    };

    render() {
        const { submitted, isLoading, modalProps } = this.state;
        const overrideProps = {
            '@field': {
                // apply to all fields
                onValueChange: this.handleValueChange,
                showErrors: submitted
            }
        };

        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles,
            // added custom start/retrieve quote components
            resolveComponentMap: {
                retrieveQuoteComponent: RetrieveQuoteComponent
            },
        };
        if (isLoading) {
            return <WMICProgressModal {...modalProps} />;
        }

        return (
            <div className={styles.main}>
                <MetadataContent
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    {...resolvers} />
            </div>
        );
    }
}
export const RetrievePageComponent = WMICRetrievePage;
export default withRouter(WMICRetrievePage);
