import { defineMessages } from 'react-intl';

export default defineMessages({
    unableToOffer: {
        id: 'wmic.quoteandbind.ho.knockout.We are unable to offer you a quote',
        defaultMessage: 'We are unable to offer you a quote.',
    },
    weRecommend1: {
        id: 'wmic.quoteandbind.ho.knockout.We recommend calling',
        defaultMessage: 'We recommend calling'
    },
    weRecommendPhoneNumber: {
        id: 'wmic.quoteandbind.ho.knockout.1-888-995-3111',
        defaultMessage: '1-888-995-3111'
    },
    weRecommend2: {
        id: 'wmic.quoteandbind.ho.knockout.to speak with our partner',
        defaultMessage: 'to speak with our partner, Answer Financial, who can provide you with quotes from multiple insurance companies. It’s fast and you can compare quotes and choose what’s right for you.'
    },
    requestDetails: {
        id: 'wmic.quoteandbind.ho.knockout.To request details in writing',
        defaultMessage: 'To request details in writing, please call our Quote Specialists at'
    },
    requestPhoneNumber: {
        id: 'wmic.quoteandbind.ho.knockout.1-877-WAWANESA (929-2637)',
        defaultMessage: '1-877-WAWANESA (929-2637)'
    },
    yourQuoteNumber: {
        id: 'wmic.quoteandbind.ho.knockout.Your Quote Number is',
        defaultMessage: 'Your Quote Number is '
    },
    orByCalling: {
        id: 'wmic.quoteandbind.ho.knockout.or by calling ',
        defaultMessage: 'or by calling '
    },
    contactPhone: {
        id: 'wmic.quoteandbind.ho.knockout.contactPhone ',
        defaultMessage: '1-503-643-5448'
    },
    haveSuggestion: {
        id: 'wmic.quoteandbind.ho.knockout.Have a suggestion',
        defaultMessage: 'Have a suggestion? We are always looking to improve! Please email your feedback to'
    },
    feedbackMail: {
        id: 'wmic.quoteandbind.ho.knockout.usafdbk@wawanesa.com',
        defaultMessage: 'usafdbk@wawanesa.com'
    },
    returnToWawanesa: {
        id: 'wmic.quoteandbind.ho.knockout. Return to wawanesa.com',
        defaultMessage: ' Return to wawanesa.com'
    }
});
