import React, { useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/legacy/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { MockUpUtil } from 'wmic-portals-utils-js';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import metadata from './PolicyInformationPage.metadata.json5';
import styles from './PolicyInformationPage.module.scss';

import './PolicyInformationPage.messages';

function removeInitialData(submissionVM) {
    // eslint-disable-next-line no-param-reassign
    submissionVM.value = MockUpUtil.cleanUpMockedProperties(
        _.get(submissionVM, 'value'),
        'quote.pa',
        'bindData.contactPhone',
        'bindData.contactEmail'
    );
    return submissionVM;
}

function initialiseVM(submissionVM) {
    const vm = removeInitialData(submissionVM);
    vm.bindData.value = vm.bindData.value || {};
    return vm;
}

function PolicyInformationPage(props) {
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const localeService = ServiceManager.getService('locale-service');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate } = useValidation('PolicyInformationPage');

    useEffect(() => {
        let vm = viewModelService.clone(submissionVM);
        vm = initialiseVM(vm);
        if (!_.isEqual(vm.value, submissionVM.value)) {
            updateWizardData(vm);
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        submissionVM.value = await LoadSaveService.updateQuotedSubmission(
            submissionVM.value,
            authHeader
        );
        return submissionVM;
    }, [LoadSaveService, authHeader, submissionVM]);

    const renderCellContent = useCallback((data, index, tableProps) => {
        const { path } = tableProps;
        if (path === 'licenseState') {
            return translator({
                id: `typekey.State.${data.licenseState}`,
                defaultMessage: data.licenseState
            });
        }
        return _.get(data, path);
    },
    [translator]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        phoneNumber: {
            countryCode: localeService.getDefaultPhoneCountry()
        },
        vehicleList: {
            data: _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value')
        },
        driverList: {
            data: _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRenderCell: renderCellContent
        }
    };

    return (
        <WizardPage onNext={onNext} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

PolicyInformationPage.propTypes = wizardProps;
export default PolicyInformationPage;
