import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleDetails: {
        id: 'quoteandbind.quickquote.step.Vehicle Details',
        defaultMessage: 'Vehicle Details'
    },
    locationDetails: {
        id: 'quoteandbind.quickquote.step.Location Details',
        defaultMessage: 'Location Details'
    },
    personalDetails: {
        id: 'quoteandbind.quickquote.step.Personal Details',
        defaultMessage: 'Personal Details'
    },
    quoteDetails: {
        id: 'quoteandbind.quickquote.step.Quote Details',
        defaultMessage: 'Quote Details'
    }
});
