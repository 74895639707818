/* eslint-disable class-methods-use-this */
import { service, ServiceManager } from '@jutro/legacy/services';
import { GOOGLE_EXPERIMENT_SERVICE_ID } from './provider/GoogleExperiment';
import { DEMO_GW_EXPERIMENT_SERVICE_ID } from './provider/DemoGuidewireExperiment';

export const AB_EXPERIMENT_SEGMENT_SERVICE_ID = 'ABExperimentSegmentation';

export default class ABExperimentSegmentationImpl {
    retrieveExperimentVariant({ experimentID, providerID }) {
        const experimentProvider = {
            googleExperiment: GOOGLE_EXPERIMENT_SERVICE_ID,
            demoGuidewireExperiment: DEMO_GW_EXPERIMENT_SERVICE_ID
        };
        const Provider = ServiceManager.getService(experimentProvider[providerID]);

        return Provider.getExperimentValue(experimentID);
    }
}

export const ABExperimentSegmentation = (
    service(AB_EXPERIMENT_SEGMENT_SERVICE_ID)(ABExperimentSegmentationImpl)
);
